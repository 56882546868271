import React, {useState} from 'react';
import {Member} from "../Members";
import PlanSales from "./PlanSales";
import TabNavigator from "../../components/TabNavigator";
import ProductSales from "./Sales/ProductSales";
import AllSales from "./Sales/AllSales";
import SalesStats from "./Sales/SalesStats";

export enum SaleLocations {POS = "CS", IN_APP = "IA"}

interface SalePlanItem {
    id: string;
    quantity: number;
    price: string;
    plan: string;
    cancelled: boolean;
}

interface SaleProductItem {
    id: string;
    quantity: number;
    price: string;
    product: string;
}

export interface Sale {
    id: string;
    plan_items: SalePlanItem[];
    products: SaleProductItem[];
    total: string;
    member: Member;
    payment_method: string;
    branch: string;
    card: string;
    completed: boolean;
    transaction_by: string;
    created_on: string
    expiration?: string
    location: SaleLocations
}


const Reports2 = () => {
    const [rangeStart, setRangeStart] = useState(new Date());
    const [rangeEnd, setRangeEnd] = useState(new Date());

    return (
        <div className="h-100 on-reports on-finish mt-5">

            <div>
                <div className="d-flex align-items-center">
                    <h1 className="text-primary font-size-h1 ">Mis Ventas</h1>

                </div>
                <div className="d-md-flex ">
                    <p className="font-size-lg text-dark-50 d-block">Historial y reporte de ventas</p>
                </div>

            </div>

            {/* TITULO 2 */}
            {/* <div className="">
                <div className="d-flex align-items-center">
                    <i className="fi-rr-stats mr-3 text-dark"/>
                    <h1 className="text-dark">Reportes y Estadisticas</h1>
                </div>
                <p className="font-size-lg text-muted">Consulta el estado de tu negocio y descarga reportes.</p>

            </div> */}


            {/* SALES STATS */}
            <SalesStats rangeStart={rangeStart} rangeEnd={rangeEnd} setRangeStart={setRangeStart}
                        setRangeEnd={setRangeEnd}/>


            {/* <h4 className="text-dark mt-15">Historial</h4> */}

            <AllSales rangeStart={rangeStart} rangeEnd={rangeEnd}/>,


            {/* <div className="px-2 pt-2" style={{background: "white"}}>
                <TabNavigator tabLabels={["", "", ""]} panels={
                    [
                        <AllSales rangeStart={rangeStart} rangeEnd={rangeEnd}/>,
                        // <PlanSales rangeStart={rangeStart} rangeEnd={rangeEnd}/>,
                        <ProductSales rangeStart={rangeStart} rangeEnd={rangeEnd}/>,
                    ]
                }/>
            </div> */}
            <div className="pb-15"/>
        </div>
    )

};

export default Reports2;


