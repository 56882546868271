import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen, faTag, faTrashAlt, faEdit, faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";
import {Badge, Button} from 'react-bootstrap';

import TableHeader from "../../../components/table/TableHeader";
import {Card, Table} from "react-bootstrap";


import {StoreState} from "../../../reducers";
import ToolboxHeader from "../ToolboxHeader";
import {removePlan, StudioPlan, StudioPlansState} from "../../../actions/studioPlans";
import CreateEditPlanModal from './CreateEditPlanModal';
import DeleteModal from "../../../components/modals/DeleteModal";
import CreatePromotionModal from "./CreatePromotionModal";

const Plans = () => {
    const dispatch = useDispatch()
    const plansState = useSelector<StoreState, StudioPlansState>(state => state.studioPlans)
    const [showModal, setShowModal] = useState(false)
    const [showCreatePromotionModal, setShowCreatePromotionModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [noLimitCredits, setNoLimitCredits] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState<StudioPlan>()

    if (plansState.loading) return <div>Cargando Planes...</div>
    return (
        <div style={{overflowY: "auto"}} className="py-4">
            <DeleteModal url={`/plans/${selectedPlan?.id}/`} onSuccess={() => {
                dispatch(removePlan(selectedPlan?.id || ""))
            }} show={showDeleteModal} onHide={() => {
                setShowDeleteModal(false)
                setSelectedPlan(undefined)
            }}>
                <p className='font-size-h6'>¿Seguro que quieres eliminar este plan?</p>
                <strong className='font-size-h4'> {selectedPlan?.name} </strong>
            </DeleteModal>
            <CreateEditPlanModal show={showModal} plan={selectedPlan} onHide={() => {
                setSelectedPlan(undefined)
                setShowModal(false)
            }} noLimitCredits={noLimitCredits}/>
            <CreatePromotionModal studioPlan={selectedPlan} show={showCreatePromotionModal} onHide={() => {
                setShowCreatePromotionModal(false)
                setSelectedPlan(undefined)
            }}/>

            {/* TITULO */}
            <div className="mt-5">
                {/* <button className='btn btn-link-primary mb-3'><FontAwesomeIcon icon={faLongArrowAltLeft} size="lg" className='mr-3'/>Configuración</button> */}
                {/* <div className=" align-items-center mb-5">
                    <h2 className="pt-2 font-size-h2 font-weight-bolder">Mis Planes</h2>
                    <div className="d-md-flex ">
                            <p className="font-size-lg text-dark-50 d-block">Crea los planes en venta de tu gimnasio, podrás asignarlos a tus clientes.</p>
                        </div>

                </div> */}

                <div className="col-12">



                {/* CARD PAQUETE DE CLASES */}
                <Card className="card-custom p-5 justify-content-center">
                <div className=" align-items-center mb-5">
                    {/* TITULO */}
                        {/* <h2 className="pt-2 font-size-h2 font-weight-bolder text-primary">Paquetes de Clases</h2>
                        <div className="d-md-flex ">
                                <p className="font-size-lg text-dark-50 d-block">El cliente solo podrá reservar el numero de clases que compre o hasta que su paquete venza.</p>
                                <button className="btn btn-link text-secondary align-self-center mt-n4 d-block ml-n4 ml-md-2">Más info sobre paquetes de clases</button>
                            </div>
                            <p className="font-size-lg text-dark-50 d-block mt-n3"> Cada reservación resta -1 clase en automático del paquete del cliente.</p> */}
                    {/* FIN TITULO */}
                    <button onClick={() => {
                        setNoLimitCredits(false)
                        setShowModal(true)
                    }
                    } className="btn btn-outline-success font-weight-bold font-size-h6 font-weight-bolder">
                        + Crear Paquete de Clases
                    </button>
                    <button onClick={() => {
                        setNoLimitCredits(true)
                        setShowModal(true)

                    }
                    } className="btn btn-outline-primary font-weight-bold font-size-h6 font-weight-bolder ml-3">+ Crear
                        Membresía Ilimitada
                    </button>
                </div>

                <Table hover bordered>
                    <TableHeader
                        headers={[ "Nombre", "# Clases incluidas", "Vigencia", "Precio", "Editar"]}/>
                    <tbody className='font-size-lg'>
                        {plansState.plans.map(p => {
                            return <tr key={p.id}>
                                <td className='text-primary'>{p.name}</td>
                                <td>{p.credits}</td>
                                <td>{p.days_duration} días</td>
                                <td>${p.price}</td>
                                <td className='text-right'>
                                <Button
                                        onClick={() => {
                                            setSelectedPlan(p)
                                            setNoLimitCredits(p.no_limit_credits)
                                            setShowModal(true)
                                        }}
                                        className="btn btn-icon btn-light-primary bg-light-primary btn-xs mr-3">
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setSelectedPlan(p)
                                            setShowCreatePromotionModal(true)
                                        }}
                                        className="btn btn-icon btn-light-success bg-light-success btn-hover-success  btn-xs bg-white mr-3">
                                        <FontAwesomeIcon icon={faTag} rotation={90} />
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setSelectedPlan(p)
                                            setShowDeleteModal(true)
                                        }}
                                        className="btn btn-icon btn-light-danger bg-light-danger btn-hover-danger bg-white btn-xs ">
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                </td>
                            </tr>
                        })}
                    </tbody>

                </Table>
            </Card>

            {/* CARD MEMBRESIAS ILIMITADAS */}
                    {/* <Card className="card-custom p-5 justify-content-center mt-10">
                        <div className=" align-items-center mb-5">
                            <h2 className="pt-2 font-size-h2 font-weight-bolder text-primary">Membresías Ilimitadas</h2>
                            <div className="d-md-flex ">
                                    <p className="font-size-lg text-dark-50 d-block">Las membresías permiten a tus clientes reservar sin límite durante la vigencia de esta.</p>
                                    <button className="btn btn-link text-secondary align-self-center mt-n4 d-block ml-n4 ml-md-2">Más info sobre membresías</button>
                                </div>
                                <p className="font-size-lg text-dark-50 d-block mt-n3">Puedes limitar reservas diarias y semanales. </p>

                                <button onClick={() => {
                                setNoLimitCredits(true)
                                setShowModal(true)

                            }
                            } className="btn btn-success font-weight-bold font-size-h6 font-weight-bolder">+ Crear
                                Membresía Ilimitada
                            </button>
                        </div>

                        <Table hover bordered>
                            <TableHeader
                                headers={[ "Nombre", "Vigencia", "Precio", "Editar"]}/>
                            <tbody className='font-size-lg'>
                                {plansState.plans.map(p => {
                                    return <tr key={p.id}>
                                        <td className='text-primary'>{p.name}</td>
                                        <td>{p.days_duration} días</td>
                                        <td>${p.price}</td>
                                        <td className='text-right'>
                                            <Button
                                                onClick={() => {
                                                    setSelectedPlan(p)
                                                    setNoLimitCredits(false)
                                                    setShowModal(true)
                                                }}
                                                className="btn btn-icon btn-light-primary bg-light-primary btn-xs mr-3">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSelectedPlan(p)
                                                    setShowCreatePromotionModal(true)
                                                }}
                                                className="btn btn-icon btn-light-success bg-light-success btn-hover-success  btn-xs bg-white mr-3">
                                                <FontAwesomeIcon icon={faTag} rotation={90} />
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSelectedPlan(p)
                                                    setShowDeleteModal(true)
                                                }}
                                                className="btn btn-icon btn-light-danger bg-light-danger btn-hover-danger bg-white btn-xs">
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </Button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>

                        </Table>
                    </Card> */}
                {/* CARD MEMBRESÍAS */}





                


                </div>

                {/* VIEJJJOOOOO */}
                {/* VIEJJJOOOOO */}
                {/* VIEJJJOOOOO */}
                {/* VIEJJJOOOOO */}
                                {/* <div className="row border-bottom pb-5">
                                    <div hidden={plansState.plans.find(p => !p.no_limit_credits) !== undefined}
                                        className="text-center text-muted font-size-h2 w-100 mt-5 text-">
                                        Paquetes de clases (créditos)
                                        <p className="font-size-lg mt-5" >El cliente solo podra reservar el numero de clases que compre o hasta que su paquete expire.</p>
                                        <p className="font-size-lg">Cada vez que el cliente reserve se le restara en automatico -1 clase de su paquete</p>
                                        <p className="font-size-h4"> 1 Reservacion = -1 Clase</p>

                                        <button onClick={() => {
                                        setNoLimitCredits(false)
                                        setShowModal(true)
                                        }
                                        } className="btn btn-primary btn-pill ml-3 font-weight-bolder font-size-h5">
                                            Crear mi primer paquete de clases
                                        </button>
                                    </div>
                                    {plansState.plans.filter((p) => !p.no_limit_credits).map((p) => {
                                        return (
                                            <div key={p.id} className="col-md-4 col-xl-3 col-xxl-2">
                                                <div className="card card-custom shadow text-center pb-5 pt-2 mt-2 px-2">

                                                    <div>
                                                        <div className="d-flex justify-content-between mb-2">
                                                            <Button
                                                                onClick={() => {
                                                                    setSelectedPlan(p)
                                                                    setShowDeleteModal(true)
                                                                }}
                                                                className="btn btn-icon btn-light-secondary btn-hover-danger bg-white btn-xs">
                                                                <FontAwesomeIcon icon={faTrashAlt}/>
                                                            </Button>
                                                            <div>
                                                                <Button
                                                                    onClick={() => {
                                                                        setSelectedPlan(p)
                                                                        setShowCreatePromotionModal(true)
                                                                    }}
                                                                    className="btn btn-icon btn-light-secondary btn-hover-success  btn-xs bg-white mr-3">
                                                                    <FontAwesomeIcon icon={faTag} rotation={90}/>
                                                                </Button>
                                                                <Button
                                                                    onClick={() => {
                                                                        setSelectedPlan(p)
                                                                        setNoLimitCredits(false)
                                                                        setShowModal(true)
                                                                    }}
                                                                    className="btn btn-icon btn-light-secondary btn-xs bg-white">
                                                                    <FontAwesomeIcon icon={faPen}/>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <p className="text-muted font-weight-light mt-n5">{p.name}</p>
                                                        <p className="h1">{p.credits}</p>
                                                        <p className="font-weight-bolder mt-n2">{p.credits === 1 ? "CRÉDITO" : "CRÉDITOS"}</p>
                                                        <p className="text-muted font-weight-light">{p.days_duration} dias</p>
                                                        {p.is_subscription ?<Badge className="mb-1" pill variant="success">Suscripción</Badge> : <React.Fragment />}
                                                        <p className="text-primary h3 font-weight-boldest" style={{letterSpacing: ".1rem"}}>${p.price}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="d-flex align-items-center mt-5">
                                    <h5 className="pt-2 font-size-h3">Mis Membresías</h5>
                                    <button onClick={() => {
                                        setNoLimitCredits(true)
                                        setShowModal(true)

                                    }
                                    } className="btn btn-light-primary btn-pill ml-3">+ Nueva
                                        Membresía
                                    </button>
                                </div>
                                <div className="row  pt-5">
                                    <div hidden={plansState.plans.find(p => p.no_limit_credits) !== undefined}
                                        className="text-center text-muted font-size-h2 w-100 mt-5">
                                        Agrega tu primer membresía
                                        <button onClick={() => {
                                            setNoLimitCredits(true)
                                            setShowModal(true)

                                            }
                                            } className="btn btn-primary btn-pill ml-3 d-block mx-auto mt-4">
                                                + Nueva Membresía
                                        </button>
                                    </div>
                                    {plansState.plans.filter((p) => p.no_limit_credits).map((p) => {
                                        return (
                                            <div key={p.id} className="col-3">
                                                <div className="card card-custom shadow text-center pb-5 mt-2 pt-2 px-2">

                                                    <div>
                                                        <div className="d-flex justify-content-between mb-2">
                                                            <Button
                                                                onClick={() => {
                                                                    setSelectedPlan(p)
                                                                    setShowDeleteModal(true)
                                                                }}
                                                                className="btn btn-icon btn-light-secondary btn-hover-danger bg-white btn-xs">
                                                                <FontAwesomeIcon icon={faTrashAlt}/>
                                                            </Button>
                                                            <div>
                                                                <Button
                                                                    onClick={() => {
                                                                        setSelectedPlan(p)
                                                                        setShowCreatePromotionModal(true)
                                                                    }}
                                                                    className="btn btn-icon btn-light-secondary btn-hover-success  btn-xs bg-white mr-3">
                                                                    <FontAwesomeIcon icon={faTag} rotation={90}/>
                                                                </Button>
                                                                <Button
                                                                    onClick={() => {
                                                                        setSelectedPlan(p)
                                                                        setNoLimitCredits(true)
                                                                        setShowModal(true)
                                                                    }}
                                                                    className="btn btn-icon btn-light-secondary btn-xs bg-white">
                                                                    <FontAwesomeIcon icon={faPen}/>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <p className="text-muted font-weight-light mt-n5">{p.name}</p>
                                                        <p className="h1">{p.days_duration}</p>
                                                        <p className="font-weight-bolder mt-n2">{p.days_duration === 1 ? "DIA" : "DIAS"}</p>
                                                        <p className="text-muted font-weight-light">{p.days_duration} dias</p>
                                                        <p className="text-primary h6" style={{letterSpacing: ".1rem"}}>${p.price}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div> */}
                {/* FIN VIEJJJOOOOO */}
                {/* VIEJJJOOOOO */}
                {/* VIEJJJOOOOO */}
                {/* VIEJJJOOOOO */}

            </div>
        </div>
    );
};

export default Plans;
