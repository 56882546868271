import React, {useEffect, useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {CSVLink} from "react-csv"
import baseApi from "../../../apis/baseApi";
import {endOfDay, format, formatISO, startOfDay} from "date-fns";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {Branch} from "../../../actions";
import {es} from "date-fns/locale";
import LoadingRoller from "../../../components/misc/LoadingRoller";

interface Props {
    show: boolean
    onHide: Function
    start?: Date,
    end?: Date
    branch: Branch
}

const CsvDownloadModal = ({show, onHide, start, end, branch}: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")
    const [data, setData] = useState([]);

    useEffect(() => {
        if (start && end) {
            let startQ = formatISO(startOfDay(start))
            let endQ = formatISO(endOfDay(end))
            baseApi.get(`/sales/download/?branch=${branch.id}&start=${startQ}&end=${endQ}`,)
                .then((resp) => {
                        setData(resp.data)
                        setSuccess(true)
                    }
                ).catch((resp) => {
                    setSuccess(false)
                    setAlertMessage(getApiErrorMessage(resp))
                }
            )

        }
    }, [start, end]);


    const renderAlert = () => {
        if (success || success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const renderButton = () => {
        if (!start || !end || !success) return <React.Fragment/>
        return <CSVLink filename={`${branch.name} - ${format(start, "MMMM yyyy", {locale: es})}`} data={data} target="_blank">
            <Button variant="success">
                DESCARGAR
            </Button>
        </CSVLink>
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>Descarga</Modal.Header>
            <Modal.Body className="text-center">
                {renderAlert()}
                <div hidden={success != null}>
                    Preparando documento...
                    <p>No cierres esta ventana.</p>
                    <LoadingRoller />
                </div>
                <h4 hidden={!success}>Documento listo</h4>
                {renderButton()}
            </Modal.Body>
        </Modal>
    );
};

export default CsvDownloadModal;
