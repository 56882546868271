import React from 'react';
import {Field} from 'formik';

type FormFieldProps = {
    name: string
    type?: string
    disabled?: boolean
}

const MiniFormField2 = ({name, type = "text", disabled=false}: FormFieldProps) => {
    return (
        <div className=" ml-n4 ml-lg-n2 ">
            <Field disabled={disabled} name={name} className="form-control form-control- text-left  font-size-lg" type={type} />
        </div>
    );
};

export default MiniFormField2;
