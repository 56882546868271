import React, {useEffect, useRef, useState} from 'react';
import {Table} from "react-bootstrap";
import baseApi from "../../../apis/baseApi";
import {Paginated} from "../../../types";
import TableHeader from "../../../components/table/TableHeader";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import {TimeSlot} from "../../Schedules";
import {format, parse, parseISO} from "date-fns";
import {formatDateTime} from "../../../utils/dateFormatters";
import TableFooter from "../../../components/table/TableFooter";


interface Reservation {
    id: string
    assistance: boolean
    cancelled: boolean
    created_on: string
    cancelled_on?: string
    timeslot: TimeSlot
    seat: string
}

type UserRsvpProps = {
    memberId: string
}

const MemberRsvp = ({memberId}: UserRsvpProps) => {
    const [reservations, setReservations] = useState<Reservation[]>([])
    const count = useRef(0)

    useEffect(() => {
        baseApi.get<Paginated>(`/rsvp/user/?member=${memberId}`).then((resp) => {
            count.current = resp.data.count
            setReservations(resp.data.results.map(r => {
                let start = parseISO(r.timeslot.start)
                if (r.timeslot.start_time !== null) {
                    start = parse(r.timeslot.start_time, "HH:mm:ss", start)
                }
                return ({
                    ...r,
                    timeslot: {...r.timeslot, start: start}
                });
            }))
        })
    }, [memberId])

    const handlePageChange = (page: number) => {
        baseApi.get<Paginated>(`/rsvp/user/?member=${memberId}&page=${page}`).then((resp) => {
            setReservations(resp.data.results.map(r => ({
                ...r,
                timeslot: {...r.timeslot, start: parseISO(r.timeslot.start)}
            })))
        }).catch((err) => alert("ERROR"))
    }

    const renderRow = (rsvp: Reservation) => {
        return <tr
            className={`font-size-md align-middle left ${rsvp.cancelled ? "bg-light-danger" : ""}`}
            key={rsvp.id}>
            {/* RSVP CODIGO */}
            {/* <td className=" text-uppercase"> {rsvp.id.slice(-6)}</td> */}

            <td>{format(rsvp.timeslot.start, "dd-MMM-yyyy H:mm")}</td>
            <td>{rsvp.timeslot.studio_class.name}</td>
            {/* SPOT LUGAR */}
            {/* <td>{rsvp.seat}</td> */}
            <td className="">
                {rsvp.cancelled ? "Cancelada " : rsvp.assistance ? "Asistió" : "Falta"}
                {rsvp.cancelled && rsvp.cancelled_on ? <span>{format(parseISO(rsvp.cancelled_on!), "dd-MMM-yy H:mm")}</span> : <React.Fragment />}
            </td>

        </tr>
    }

    return (
        <div>

            <Table bordered className=''>
                <TableHeader headers={["Reserva", "Clase", "Asistencia"]}/>
                <tbody>
                {reservations.length === 0 ? <EmptyTr/> : reservations.map((rsvp) => renderRow(rsvp))}
                </tbody>
            </Table>
            <TableFooter count={count.current} pageCount={reservations.length} pageSize={10}
                         onPageChange={handlePageChange}/>
        </div>
    );
};

export default MemberRsvp;
