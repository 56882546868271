import React, {useState} from 'react';
import {Col, Row} from "react-bootstrap";

import PosCart from "./Cart";
import {StudioPlan} from "../../actions/studioPlans";
import TabNavigator from "../../components/TabNavigator";
import Memberships from "./Memberships";
import Products, {Product} from "./Products";
import CreateCashExpenseModal from "./CreateCashExpenseModal";
import PendingPaymentSales from "./PendingPaymentSales";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCashRegister} from "@fortawesome/free-solid-svg-icons";


export interface ProductSaleItem {
    product: Product
    quantity: number
    price: number
}

export interface PlanSaleItem {
    plan: StudioPlan
    quantity: number
    price: number
    activate_on: Date
}

const Pos = () => {
    const [cartPlans, setCartPlans] = useState<PlanSaleItem[]>([])
    const [cartProducts, setCartProducts] = useState<ProductSaleItem[]>([]);
    const [showCashMovementModal, setShowCashMovementModal] = useState(false);
    const [positiveMove, setPositiveMove] = useState(true);

    return (
        <div style={{overflowY: "auto", overflowX: "hidden"}} className="h-100 on-pos">
            <CreateCashExpenseModal show={showCashMovementModal} onHide={() => setShowCashMovementModal(false)}
                                    positive={positiveMove}/>

                    <div className="mt-5 mb-1">
                        <div className="d-flex justify-content-between">
                            <div>
                                <div className="d-flex align-items-center">
                                    {/* <i className="fi-rr-dollar mr-3 text-dark"/> */}
                                    <h1 className="text-primary">Registrar Venta / Renovación</h1>
                                    <FontAwesomeIcon className="ml-3 text-primary font-size-h5" icon={faCashRegister}/>

                                </div>
                                <p className="font-size-h5 text-dark-50">Agrega paquetes o membresías a clientes y registra ventas en efectivo, transferencia, etc.</p>
                            </div>
                            {/* BOTONES INGRESO EGRESO */}
                            {/* <div className="d-flex h-50">
                                <button className="btn btn-outline-success d-flex btn-sm">
                                    <i className="fi-rr-plus ml-n2 mr-1  align-self-center muted icon-sm pl-2"/>

                                    <div className="align-self-center">Marcar Ingreso</div>
                                </button>
                                <button className="btn btn-outline-danger d-flex ml-2 btn-sm">
                                    <i className="fi-rr-minus ml-n2 mr-1  align-self-center muted icon-sm pl-2"/>

                                    <div className="align-self-center">Marcar Egreso</div>
                                </button>
                            </div> */}
                        </div>
                    </div>
            <Row>
                <Col md={6} className="bg-light-primary px-8">
                    <Memberships cart={cartPlans} setCart={setCartPlans}/>,
                    
                    {/* TABS */}
                        {/* <TabNavigator tabLabels={[""]} panels={[
                            <Memberships cart={cartPlans} setCart={setCartPlans}/>,
                            <Products cartProducts={cartProducts} setCartProducts={setCartProducts}/>,
                            <PendingPaymentSales/>
                        ]}/> */}
                    {/* FIN TABS */}

                </Col>
                <Col md={6}>
                    <PosCart cartPlans={cartPlans} setCartPlans={setCartPlans} cartProducts={cartProducts}
                             setCartProducts={setCartProducts}/>
                </Col>
            </Row>

        </div>
    );
};

export default Pos;