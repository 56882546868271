import React from 'react';
import {StudioPlan} from "../../actions/studioPlans";
import {StudioClass} from "../../actions/studioClasses";


type MembershipCardProps = {
    plan: StudioPlan
    onClick: () => void
}

const MembershipCard = ({plan, onClick}: MembershipCardProps) => {

    const getDaysDuration = () => {
        if (plan.no_limit_credits) return <React.Fragment/>
        return <span>{plan.days_duration} días</span>
    }
    const getStudioClassNames = (studioClasses: StudioClass[]) => {
        //TODO: add react-fast-marquee for long texts
        return studioClasses.map(s => s.name).join(", ")
        // console.log(ids)
        // // @ts-ignore
        // const names = ids.map(id => studioClasses.find(sc => sc.id === id).name)
        // return names.join(", ")
    }

    ////// CARD de membresias y planes /////////////////////////////////////////////////
    ////// CARD de membresias y planes /////////////////////////////////////////////////
    ////// CARD de membresias y planes /////////////////////////////////////////////////
    ////// CARD de membresias y planes /////////////////////////////////////////////////
    ////// CARD de membresias y planes /////////////////////////////////////////////////
    ////// CARD de membresias y planes /////////////////////////////////////////////////
    ////// CARD de membresias y planes /////////////////////////////////////////////////
    ////// CARD de membresias y planes /////////////////////////////////////////////////
    ////// CARD de membresias y planes /////////////////////////////////////////////////
    ////// CARD de membresias y planes /////////////////////////////////////////////////
 
    return (
        <div onClick={onClick} style={{cursor: "pointer"}} className="col-sm-6 px-2 mb-5 pl-4 ">

            {/* DESKTOP */}
            <div className="d-none d-md-block card-hover card card-custom text-left pt-7 pb-5 rounded-0 karpseudo border bg-hover-light-primary px-5 ">
                <div>
                    <p className="font-weight-bold text-primary">{plan.name}</p>

                    <div className="d-flex align-items-center mt-n5">
                        <p className="font-size-h2 ">{plan.no_limit_credits ? plan.days_duration : plan.credits}</p>
                        
                        <p className="ml-2">{plan.no_limit_credits ? "Días membresía" : plan.credits === 1 ? "Créditos" : "Créditos"}</p>
                    </div>
                    {/* <p className=" font-size-lg text-primary">10 Creditos</p> */}
                    <p className="font-size-lg mt-n5">{getDaysDuration()} </p>
                    {/*TODO: map through allowed classes*/}
                    {/* <p className="text-muted font-size-lg">Clases: {plan.allow_all_classes ? "Todas" : getStudioClassNames(plan.allowed_classes)}</p> */}
                    <div className="d-flex justify-content-between">
                        <p className=" font-weight-boldest h3 text-primary">$ {plan.price.slice(0, -3)}</p>
                        <button className='btn btn-link btn-sm font-weight-bold font-size-xs px-0'>Agregar a carrito</button>
                    </div>

                </div>
            </div>
            {/* FIN DESKTOP */}

            {/* MOBILE */}
            <div className="d-md-none card-hover card card-custom text-left  rounded-0 karpseudo border bg-hover-light-primary px-5 pt-2 pb-2 mb-n4">
                <div className='d-flex justify-content-between'>
                    <div>
                        <p className="font-weight-bold text-primary">{plan.name}</p>
                        <div className="d-flex align-items-center mt-n5">
                            <p className="font-size-h6 ">{plan.no_limit_credits ? plan.days_duration : plan.credits}</p>
                        
                            <p className="ml-2">{plan.no_limit_credits ? "Días membresía" : plan.credits === 1 ? "Créditos" : "Créditos"}</p>
                        </div>
                        {/* <p className=" font-size-lg text-primary">10 Creditos</p> */}
                        <div className="font-size-sm mt-n5">{getDaysDuration()} </div>
                        {/*TODO: map through allowed classes*/}
                        {/* <p className="text-muted font-size-lg">Clases: {plan.allow_all_classes ? "Todas" : getStudioClassNames(plan.allowed_classes)}</p> */}
                    </div>
                    <div className=" text-center">
                        <div className=" font-weight-boldest font-size-h3 text-primary">$ {plan.price.slice(0, -3)}</div>
                        <button className='btn btn-link btn-sm font-weight-bold font-size-xs px-0'>Agregar a carrito</button>
                    </div>

                </div>
            </div>
            {/* MOBILE */}
        </div>
    );

        ////// END CARD de membresias y planes /////////////////////////////////////////////////
        ////// END CARD de membresias y planes /////////////////////////////////////////////////
        ////// END CARD de membresias y planes /////////////////////////////////////////////////
};

export default MembershipCard;
