import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight, faEye} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {format} from "date-fns"
import {es} from "date-fns/locale"

type SchedulesHeaderProps = {
    currentDate: Date
    onArrowBack: () => void
    onArrowForward: () => void
    setShowCreateModal: (show: boolean) => void
}

const SchedulesHeader = ({onArrowBack, onArrowForward, currentDate, setShowCreateModal} : SchedulesHeaderProps) => {

    return (
        <div className="mt-5 mb-8">
            <div className="align-items-center">


                {/*  */}
                <div className="align-items-center justify-content-between">
                    {/* <div className="d-flex align-items-center">
                        <h2 className="text-dark display-4 font-weight-bold ">Calendario</h2>
                        <button onClick={() => setShowCreateModal(true)} className="d-none d-md-inline ml-5 btn btn-pill btn-lg  border-0 font-weight-bolder btn btn-primary btn-hover-success shadow">+ Nuevo Horario</button>
                    </div> */}

                    <div className='d-flex justify-content-between'>
                        <div>
                            <div className="d-flex align-items-center">
                                {/* <i className="fi-rr-calendar mr-3 text-dark"/> */}
                                {/* <FontAwesomeIcon className="mr-3 text-dark font-size-h5" icon={faChevronRight} /> */}
                                <h1 className="text-primary font-size-h1">Calendario</h1>
                            </div>
                            <p className="font-size-lg text-dark-50">Administra los horarios de tu gimnasio.</p>
                            
                            <button onClick={() => setShowCreateModal(true)} className="d-none d-md-inline btn-lg  border-0 font-weight-bolder btn btn-success btn-hover-success shadow">+ Crear Horario</button>
                        </div>

                        {/* <div className='bg-light-primary col-12 col-md-2 col-xxl- rounded-lg'>
                            <button className='btn btn-link btn-block text-left py-0 mt-2'>Mis clases
                            <FontAwesomeIcon className="ml-1 text-primary font-size-h5 " icon={faChevronRight} size="xs" />
                            </button>
                            <button className='btn btn-link btn-block text-left py-0'>Mis instructores
                            <FontAwesomeIcon className="ml-1 text-primary font-size-h5 " icon={faChevronRight} size="xs" />
                            </button>
                            <button className='btn btn-link btn-block text-left py-0'>Mis salones
                            <FontAwesomeIcon className="ml-1 text-primary font-size-h5 " icon={faChevronRight} size="xs" />
                            </button>
                            
                        </div> */}
                    </div>



                    {/* MOBILE HEADER*/}
                    <div>
                        {/* <Button className="btn-light-primary mr-3 d-none d-md-block">
                            <FontAwesomeIcon icon={faEye} />
                            {" "}Listado
                        </Button> */}
                        {/* <Button className="btn-light btn-icon btn-circle d-md-none text-danger">
                            <FontAwesomeIcon icon={faEye} />
                            {" "}
                        </Button> */}
                        <div className="d-block">
                            <button onClick={() => setShowCreateModal(true)} className="d-md-none btn btn-success font-weight-bold font-size-h6 btn-block">
                                <i className="fi-rr-plus text-white icon-md"></i>
                                Crear horario
                            </button>
                        </div>
                    </div>
                </div>
                {/* END MOBILE HEADER */}





                {/*-------------------- AREA MES-----------------*/}
                <div className="d-flex align-items-center flex-wrap justify-content-between mt-10 mb-n5">

                    <Button onClick={onArrowBack} className="btn btn-sm btn-light bg-transparent border-0 px-1"
                            data-toggle="tooltip" data-original-title="Semana anterior">
                        <FontAwesomeIcon icon={faChevronLeft} /> Semana Anterior
                    </Button>
                    <h5 className="text-primary font-weight-bold mt-2 mr-5 align-middle ml-5 text-center">{format(currentDate, "MMMM", {locale: es})}</h5>

                    <Button onClick={onArrowForward} className="btn btn-sm btn-light bg-transparent border-0 px-1"
                            data-toggle="tooltip" data-original-title="Siguiente semana">
                        Siguiente Semana<FontAwesomeIcon className='ml-2' icon={faChevronRight} />
                    </Button>
                </div>
            </div>


        </div>

    );
};

export default SchedulesHeader;
