import React, {useState} from 'react';
import CheckInPill from "../../../pages/Reservations/CheckInPill";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {Reservation} from "../../../pages/Reservations";
import DeleteModal from "../../modals/DeleteModal";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {TimeSlot} from "../../../pages/Schedules";
import {format, parseISO} from "date-fns";
import {updateReservation} from "../../../actions/timeslotReservations";
import {faBirthdayCake, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {es} from "date-fns/locale";

type ReservationTrProps = {
    rsvp: Reservation
    editDisabled: boolean
}

const ReservationTr = ({rsvp, editDisabled = false}: ReservationTrProps) => {
    const dispatch = useDispatch()

    const timeslot = useSelector<StoreState, TimeSlot>(state => state.timeSlotReservations.timeslot)
    const [showDelete, setShowDelete] = useState(false);
    const [showDisabledTooltip, setShowDisabledTooltip] = useState(false);
    const [showDisabledDeleteTooltip, setShowDisabledDeleteTooltip] = useState(false);
    const isBirthdayToday = rsvp.member?.birthday ? parseISO(rsvp.member?.birthday).getDate() === timeslot.start.getDate() && parseISO(rsvp.member.birthday).getMonth() === timeslot.start.getMonth() : false
    const birthdayTooltip = (props: any) => (
        <Tooltip id="birthdayTT" {...props}>
            Cumple años en este día
        </Tooltip>
    );

    const editDisabledTooltip = (props: any) => {
        return <Tooltip id="editDisabledTT" {...props}>
            Acción restringida por tu administrador.
        </Tooltip>
    };

    return (
        <tr
            key={rsvp.id}
            className={`font-size-sm align-middle text-center ${rsvp.cancelled ? "text-muted" : ""}`}>
            <DeleteModal
                url={`/rsvp/update/${rsvp.id}/`}
                onSuccess={() => {
                    dispatch(updateReservation({...rsvp, cancelled: true}))
                }}
                show={showDelete}
                onHide={() => setShowDelete(false)}
                text={"Cancelar Reservación"}
                successText={"Reservación cancelada."}
            >
                <div className='font-size-h6'>
                    {rsvp.member ?
                        <p className="text-center">
                            {rsvp.member.full_name}
                            {/* ({rsvp.member.external_id}) */}
                        </p>
                        :
                        <p className="text-center">{rsvp.dashboard_reservation?.full_name} ({rsvp.dashboard_reservation?.phone})</p>
                    }
                    <p className="p-0 m-0 text-primary"><strong>{format(timeslot.start, "EEEE dd 'de' MMM h:mm a", {locale: es})}</strong></p>
                    <p className="text-center font-size-sm">{timeslot.studio_class.name}</p>
                </div>
            </DeleteModal>

            

            {/* EMPIEZA TABLA */}
            <td className="align-middle text-left">
                <div className="font-weight-bold font-size-h6">
                    {rsvp.member ? rsvp.member.full_name : rsvp.dashboard_reservation?.full_name}
                    {/* CUMPLEANOS */}
                    <OverlayTrigger placement="bottom" overlay={birthdayTooltip} delay={{show: 150, hide: 250}}>
                        <i hidden={!isBirthdayToday} className="text-success ml-2">
                            <FontAwesomeIcon icon={faBirthdayCake}/>
                        </i>
                    </OverlayTrigger>
                    {/* FIN CUMPLEANOS */}
                </div>
                <div className="text-dark-50 ">{rsvp.email || rsvp.dashboard_reservation?.email}</div>

            </td>


            {/* #LUGAR */}
                {/* <div className="text-muted font-size-xs">Lugar: {rsvp.seat}</div> */}
            {/* FIN LUGAR */}
            {/* TALLA */}
                {/* <td>{rsvp.member ? rsvp.member.shoe_size : ""}</td> */}
             {/*FIN TALLA  */}
             {/* ID */}
                {/* <td className=" text-uppercase"> {rsvp.member ? rsvp.member.external_id : ""}</td> */}
            {/* FIN ID */}


            
            {/* ROW ASISTENCIA */}
            <td className=" w-140px px-0">

                <OverlayTrigger onToggle={(val) => {
                    if (editDisabled) {
                        setShowDisabledTooltip(val)
                    }
                }} placement="bottom" overlay={editDisabledTooltip} delay={{show: 200, hide: 250}}
                                show={showDisabledTooltip}>
                    <div>
                        <CheckInPill rsvp={rsvp} editDisabled={editDisabled}/>
                    </div>
                </OverlayTrigger>
            </td>
            
            {/* ROW BOTON CANCELAR RESERVACION */}
            <td className=''>
                <OverlayTrigger onToggle={(val) => {
                    if (editDisabled) {
                        setShowDisabledDeleteTooltip(val)
                    }
                }} placement="bottom" overlay={editDisabledTooltip} delay={{show: 200, hide: 250}}
                                show={showDisabledDeleteTooltip}>
                    <div>

                        <Button
                            disabled={useSelector<StoreState, boolean>(state => !state.staffUser.permissions.find(p => p.feature === "reservations")?.delete ?? true)}
                            className="btn  border-0 bg-transparent text-danger btn-xs btn-hover-danger mr-1"
                            onClick={() => {
                                setShowDelete(true)
                            }}>
                            <FontAwesomeIcon icon={faTrash}/>
                            <div className='font-size-xs'>Eliminar</div>
                        </Button>
                    </div>
                </OverlayTrigger>
                {/*<Button*/}
                {/*    className="btn btn-icon btn-light btn-xs btn-hover-primary">*/}
                {/*    <FontAwesomeIcon icon={faUser}/>*/}
                {/*</Button>*/}
            </td>
        </tr>
    );
};

export default ReservationTr;
