import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useParams, useLocation, useHistory} from 'react-router-dom';

import {Member} from "../index";
import MemberPlans from "./MemberPlans";
import MemberRsvp from './MemberRsvp';
import Profile from "./Profile";
import TabNavigator from "../../../components/TabNavigator";
import {faChevronLeft, faChevronRight, faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";
import MemberCredits from "./MemberCredits";

import Profileprueba1 from "./Profileprueba1";

// AGREGADOK
import {faBell, faCoins, faStar, faEdit, faCashRegister} from "@fortawesome/free-solid-svg-icons";

import AddMemberCreditsModal from "./AddMemberCreditsModal";
import RemoveCreditsModal from "./RemoveMemberCreditsModal";
// AGREGADOK


// agregado k botones creditos
export interface CreditsTransaction {
    id: string
    amount: number
    created_on: string
    credits: string
    description: string
    transaction_by: string
    expire_on: string
}
//  agregadok



const MemberDetails = () => {
    const history = useHistory()
    const location = useLocation() as { state: { member: Member } }

    // agregado k
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [transactions, setTransactions] = useState<CreditsTransaction[]>([]);
// agregado k


    let {memberId} = useParams<{ memberId: string }>()

    const [member, setMember] = useState<Member | null>(null)

    useEffect(() => {
        if (location.state) {
            setMember(location.state.member)
        }
    }, [location])

    useEffect(() => {
        if (member) {
            history.replace({...history.location, state: {member: member}})
        }
    }, [history, member]);


    if (!member) return <React.Fragment/>
    return (
        <div className="h-100">

            {/* BOTON "ATRAS" */}
            <div className='mb-4 mt-10'>
                <Button onClick={history.goBack} size="sm" variant="link-primary">
                    <i><FontAwesomeIcon icon={faLongArrowAltLeft} size="lg"/></i>Clientes
                </Button>
            </div>
            {/* FIN BOTON ATRAS */}

            {/* PERFIL */}
            <div>
                <div className="">
                    <div className='px-5'>{member ? <Profile member={member} setMember={setMember}/> : <React.Fragment/>}</div>
                </div>
            </div>
            {/* FIN PERFIL */}





            {/* BOTONES agregar restar credios k */}
            <AddMemberCreditsModal show={showCreateModal} onHide={() => setShowCreateModal(false)} member={member}
                                   setMember={setMember}
                                   transactions={transactions} setTransactions={setTransactions}/>
            <RemoveCreditsModal show={showRemoveModal} onHide={() => setShowRemoveModal(false)} setMember={setMember}
                                member={member}
                                transactions={transactions} setTransactions={setTransactions}/>
            <div className="text-left">
                
                {/* BOTONES AGREGAR CREDITOS */}
                
                <Button onClick={() => setShowCreateModal(true)} className="my-2 btn-outline-success btn-sm  font-weight-bolder btn-block d-sm-none" size="sm">
                    + Agregar créditos
                </Button>
                {/* BOTONES QUITAR */}
                
                <Button variant="" onClick={() => setShowRemoveModal(true)} className="my-2  btn-sm btn-outline-danger font-weight-bolder btn-block d-sm-none"
                        size="sm">
                    - Quitar créditos
                </Button>
                {/* BOTON VENDER PLAN */}


                <div className='text-center col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6  p-5 mb-5 text-center'>
                    <div>
                        <Button onClick={() => setShowCreateModal(true)} className="mt-2 ml-2 btn-outline-success font-size-h6 font-weight-bold d-none d-sm-inline" size="sm">
                            + Agregar créditos
                        </Button>
                        <Button variant="" onClick={() => setShowRemoveModal(true)} className="mt-2 ml-2 btn-outline-danger font-size-h6 font-weight-bold d-none d-sm-inline"
                                size="sm">
                            - Quitar créditos
                        </Button>
                    </div>
                        <Button variant="" onClick={()  => history.push ("/pos")} className="my-2 ml-2 btn-link font-size-h6 font-weight-bolder d-none d-sm-inline px-12 btn-hover-primary"
                                size="sm" >
                                <u>
                                    <FontAwesomeIcon icon={faCashRegister} size="sm" className='mr-1'/>
                                    Registrar venta / renovación
                                    <FontAwesomeIcon icon={faChevronRight} size="sm" className='ml-1'/>
                                </u>

                        </Button>
                </div>


                <Button variant="" onClick={() => history.push ("/pos")} className="my-2 btn-success btn-sm  font-weight-bolder btn-block d-sm-none"
                        size="sm">
                         <FontAwesomeIcon icon={faCashRegister} size="sm" className='mr-1'/>
                        Vender un plan / renovar
                </Button>
                {/* BOTON AGREGADO  EDITAR FECHA MEMBRESIA */}
                    {/* <Button variant="" onClick={() => setShowRemoveModal(true)} className="my-2 ml-2 btn-primary font-size-h6 font-weight-bolder d-none d-sm-inline"
                            size="sm">
                            <FontAwesomeIcon icon={faEdit} size="sm" className='mr-1'/>
                            Editar fecha membresía
                    </Button>
                    <Button variant="" onClick={() => setShowRemoveModal(true)} className="my-2  btn-sm btn-outline-primary font-weight-bolder btn-block d-sm-none"
                            size="sm">
                        <FontAwesomeIcon icon={faEdit} size="sm" className='mr-1'/>
                            Editar fecha membresía
                    </Button> */}
                {/* FIN EDITAR EXPIRACIÓN MEMBRESÍA */}
                
            </div>
            {/* botones agregar restar creditos k  */}













            {/* TABS */}
            <Row className="h-100 mt-4">
            {/* TABS */}
                <Col lg={8}>
                    <div className=" px-2 pt-2" style={{background: "white", height: "85%"}}>
                        <TabNavigator tabLabels={["Historial Compras", "Historial Reservaciones", "Transacciones Créditos"]} panels={
                            [
                                <MemberPlans member={member!} setMember={setMember}/>,
                                <MemberRsvp memberId={memberId}/>,
                                <MemberCredits member={member!} setMember={setMember}/>,
                            ]
                        }/>
                    </div>
                </Col>
            </Row>
            {/* TABS END */}



            


            {/* CARD HISTORIAL MEMBRESIAS */}
            {/* <div>
                <div className="card shadow">
                    <div className="card-header bg-light-primary text-primary h6">Historial compras membresías y paquetes</div>
                    <div><MemberPlans member={member!} setMember={setMember}/>,</div>
                </div>
            </div> */}
            {/* FIN HISTORIAL MEMBRESIAS */}


            {/* CARD RESERVACIONES */}
            {/* <div>
                <div className="card shadow">
                    <div className="card-header bg-light-primary text-primary h6">Reservaciones</div>
                    <div><MemberRsvp memberId={memberId}/>,</div>
                </div>
            </div> */}
            {/* FIN CARD RESERVACIONES */}

            {/* MOVIMIENTO CREDITOS */}
            {/* <div>
                <div className="card shadow">
                    <div className="card-header bg-light-primary text-primary h6">Historial movimiento creditos</div>
                    <div className='px-5'><MemberCredits member={member!} setMember={setMember}/></div>
                </div>
            </div> */}
            {/* FIN MOVIMIENTO CREDITOS */}



        </div>
    );
};

export default MemberDetails;
