import React, {useEffect, useState} from 'react';
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {startOfDay, endOfDay, formatISO, format, parseISO, parse} from "date-fns"
import {es} from "date-fns/locale"

import {TimeSlot} from "../Schedules";
import baseApi from "../../apis/baseApi";
import TimeSlotListCard from "./TimeSlotListCard";
import ReactDatePicker from "react-datepicker";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarDay, faFileExcel, faEdit} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";
import EmptyAlert from "../../components/misc/Empty";
import WaitListTable from "./WaitListTable";
import TabNavigator from "../../components/TabNavigator";
import ReservationsTable from "./ReservationsTable";
import {fetchReservations, TimeslotReservationsState} from "../../actions/timeslotReservations";
import CreateReservationModal from "./CreateReservationModal";
import {CSVLink} from "react-csv";
import {faUser} from "@fortawesome/free-regular-svg-icons";


export interface DashboardReservationMember {
    full_name: string
    email?: string
    phone?: string
}

interface ReservationMember {
    external_id: string,
    full_name: string,
    phone: string,
    shoe_size: string
    birthday?: string
}

export interface Reservation {
    id: string,
    member?: ReservationMember
    dashboard_reservation?: DashboardReservationMember
    assistance: boolean | null
    cancelled: boolean
    email: string
    seat: string | null
    cancelled_on?: string
}

const Reservations = () => {
    const dispatch = useDispatch()
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const rsvpState = useSelector<StoreState, TimeslotReservationsState>(state => state.timeSlotReservations)

    const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([])
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot | null>(null)
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date())

    useEffect(() => {
        const start = formatISO(startOfDay(selectedDate))
        const end = formatISO(endOfDay(selectedDate))
        baseApi.get(`/schedules/slots/?start=${start}&end=${end}&branch=${currentBranch.id}`).then((resp) => {
            // @ts-ignore
            const data: TimeSlot[] = resp.data.map((t) => {
                let start = parseISO(t.start)
                if (t.start_time !== null) {
                    start = parse(t.start_time, "HH:mm:ss", start)
                }
                return ({
                    ...t,
                    start: start,
                } as TimeSlot);
            })
            const sortedData = data.sort((a, b) => (a.start.getTime() - b.start.getTime()))
            setTimeSlots(sortedData)
        }).catch(() => alert("Error obteniendo las reservaciones"))
    }, [selectedDate, currentBranch])

    useEffect(() => {
        if (timeSlots.length > 0) {
            setSelectedTimeSlot(timeSlots[0])
        } else {
            setSelectedTimeSlot(null)
        }
    }, [timeSlots])

    useEffect(() => {
        if (selectedTimeSlot !== null) {
            dispatch(fetchReservations(selectedTimeSlot))
        }
    }, [selectedTimeSlot, dispatch]);

    const addRsvpTooltip = (props: any) => (
        <Tooltip id="add-rsvp-tooltip" {...props}>
            Agregar reservación
        </Tooltip>
    );


    const renderTimeSlots = () => {
        if (timeSlots.length === 0) {
            return <div>
                {/* <div className="mt-5 pt-5 text-center justify-content-center align-items-center text-muted">
                    No tienes horarios el día de hoy
                    <div className="d-flex justify-content-center align-items-center mt-4 text-dark ">
                        Selecciona otra fecha
                        <div className="ml-2 text-center font-size-h3" style={{ cursor: "pointer", color: "#007bff" }}>
                            <ReactDatePicker customInput={<FontAwesomeIcon icon={faCalendarDay} />}
                                dateFormat="dd 'de' MMMM 'del' yyyy"
                                locale={es} selected={selectedDate}
                                onChange={(d) => setSelectedDate(d as Date)} />
                        </div>
                    </div>
                
                
                </div> */}
            </div>
            
        }
        return <div style={{overflowY: "auto", overflowX: "hidden"}}>
            {timeSlots.map((ts) => {
                return <TimeSlotListCard key={ts.id} setSelectedTimeSlot={setSelectedTimeSlot} timeSlot={ts}/>
            })}
        </div>

    }

    const renderSelectedTimeSlot = () => {
        if (selectedTimeSlot === null) {
            return <EmptyAlert/>
        }
        return <div>

            {/* RESERVATION TABLE HEADER */}
            <div className="mb-10 mt-1 ">

                <div className=''>
                    
                    <div className='font-weight-bold font-size-h1 border-bottom pb-2'>Lista de Reservaciones</div>

                    <div className=" font-size-h4 font-weight-bold d-flex mt-2 ">
                        
                        <div className="mb-1">
                        {format(selectedTimeSlot.start, "EEEE d 'de' MMMM", {locale: es})}
                        </div>
                        <div className='text-primary pr-4 ml-2'>{format(selectedTimeSlot.start, "h:mm aa")}</div>

                    </div>

                    <div className="d-flex align-items-center mt-2">
                        {/* <div className='text-primary pr-4'>{format(selectedTimeSlot.start, "h:mm aa")}</div> */}
                        <div className=''>
                            <div style={{color: selectedTimeSlot.studio_class.color}}
                                                        className=" font-size-lg text-dark-50">Clase: {selectedTimeSlot.studio_class.name}
                                                    </div>
                            <div className="d-flex text-dark-50">
                                <div className="pr-2 font-size-lg">Instructor:</div>
                                <div className="font-size-lg">{selectedTimeSlot.coaches.map(c => c.full_name).join(", ")}</div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="d-flex justify-content-between">

                    {/*<button className="btn btn-white btn-icon btn-circle btn-lg shadow ml-3">*/}
                    {/*    <i className="fi-rr-apps text-dark-50"></i>*/}
                    {/*</button>*/}
                    
                

                    <div className="text-center">
                            <button onClick={() => setShowCreateModal(true)}
                                    className="btn btn-outline-primary font-weight-bold font-size-lg mt-4">
                                {/* <i className="fi-rr-user-add text-dark-50"/>  */}
                                + Crear reservación para cliente
                            </button>
                        </div>
                    {/* EXCEL */}
                    {/* <CSVLink
                        filename={`${selectedTimeSlot?.studio_class.name} ${format(selectedTimeSlot?.start, "dd-MMM-yyyy H_mm")}`}
                        data={rsvpState.reservations.map(r => {
                            let cliente = r.member ? `${r.member.external_id} - ${r.member.full_name}` : r.dashboard_reservation ? `${r.dashboard_reservation.full_name}` : ""
                            return {
                                "CLIENTE": cliente,
                                "LUGAR": r.seat,
                                "ASISTENCIA": r.assistance ? "SI" : "NO",
                                "CANCELADA": r.cancelled ? "SI" : ""
                            }
                        })}>
                        <button className="btn btn-outline-success ml-4 d-none d-md-block">
                            <FontAwesomeIcon icon={faFileExcel} /> Descargar
                        </button>
                    </CSVLink> */}
                    {/* FIN EXCEL */}
                    {/*<button className="btn btn-white btn-icon btn-circle btn-lg btn-hover-danger shadow ml-3">*/}
                    {/*    <i className="fi-rr-trash text-dark-50"></i>*/}
                    {/*</button>*/}
                </div>

            </div>


            <TabNavigator tabLabels={["Clientes reservados", "Clientes en lista de espera"]}
                          panels={[
                              <ReservationsTable/>,
                              <WaitListTable timeslot={selectedTimeSlot}/>
                          ]}/>

        </div>
    }

    return (
        <div className="h-100 on-rsvp on-check-in on-waitlist">
            <CreateReservationModal show={showCreateModal} onHide={() => setShowCreateModal(false)}
                                    timeslot={selectedTimeSlot!}/>
            <div className="mt-5 mb-8">
                <div className="d-flex align-items-center">
                    {/* <i className="fi-rr-checkbox mr-3 text-dark"></i> */}
                    <h1 className="text-primary">Listas de Reservaciones</h1>
                </div>
            </div>

            <Row className="h-100 mt-5">

                {/* Starts column 1 -izquierda */}
                <Col sm={12} md={5} className="col-xl-3 col-xxl-3">

                    <div style={{height: "90%",}} className="border p-5">


                        {/* <div>
                            <div style={{opacity: "0.3"}} className="font-weight-bolder text-uppercase">
                                RESERVACIONES
                            </div>
                        </div> */}

                        <div className='font-weight-bold font-size-h4 text-center'>Horarios del día</div>
                        <div className='text-muted font-size-md text-center'>
                                    Escoger Fecha
                                </div>
                        <div className="d-flex flex-row align-items-center mb-10 justify-content-center text-center">

                            <div className="font-weight-bold text-primary font-size-h4">
                                {format(selectedDate, "EEEE, dd 'de' MMMM", {locale: es})}
                                
                            </div>

                            <div className="ml-2" style={{cursor: "pointer", color: "#007bff"}}>
                                <ReactDatePicker customInput={<FontAwesomeIcon icon={faEdit} size="lg"/>}
                                                 dateFormat="dd 'de' MMMM 'del' yyyy"
                                                 locale={es} selected={selectedDate}
                                                 onChange={(d) => setSelectedDate(d as Date)}/>
                            </div>
                        </div>

                            <div className='text-muted d-md-none'>Selecciona un horario, la lista se encuentra en la parte inferior de la pantalla</div>

{/* 
                        <div className="d-flex flex-row justify-content-around text-center mt-15 mb-15 d-none">
                            <div className="d-none d-sm-block">
                                <div>
                                    <p style={{opacity: "0.3", fontSize: "12px"}}
                                       className="m-0 text-uppercase  font-weight-bolder">
                                        HORARIOS
                                    </p>
                                </div>
                                <div>
                                    <p className="m-0 font-weight-bold font-size-h6">{timeSlots.length}</p>
                                </div>
                            </div>
                            <div className="d-none d-sm-block">
                                <div>
                                    <p style={{opacity: "0.3", fontSize: "12px"}}
                                       className="m-0 text-uppercase  font-weight-bolder">
                                        TOTAL
                                    </p>
                                </div>
                                <div>
                                    <p className="m-0 font-weight-bold font-size-h6">{timeSlots.reduce((acc, ts) => acc + ts.people_limit, 0)}</p>
                                </div>
                            </div>
                            <div className="d-none d-sm-block">
                                <div>
                                    <p style={{opacity: "0.3", fontSize: "12px"}}
                                       className="m-0 text-uppercase  font-weight-bolder">
                                        Ocupación
                                    </p>
                                </div>
                                <div>
                                    <p className="m-0 font-weight-bold font-size-h6">{timeSlots.reduce((acc, ts) => acc + (ts.people_limit - ts.available_spaces), 0)}</p>
                                </div>
                            </div>
                        </div> */}


                        <div className="h-100">
                            {renderTimeSlots()}
                        </div>
                    </div>
                </Col>

                {/* Starts column 2 derecha*/}
                <Col sm={12} md={7} className="col-xl-8 col-xxl-9 ">
                    <div style={{backgroundColor: "", height: "90%"}} className="border p-5">
                        {renderSelectedTimeSlot()}
                    </div>
                </Col>


            </Row>
        </div>
    );
};

export default Reservations;
