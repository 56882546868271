import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Button } from 'react-bootstrap';
import {useDispatch} from "react-redux";



const ClientesActivos = () => {
    const dispatch = useDispatch()



    return (
        <div>
            
            
        </div>
    );
};

export default ClientesActivos;
