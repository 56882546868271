import React, {useEffect} from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm} from "formik";
import FormField from "../../components/form/FormField";
import {TimeSlot} from "../Schedules";
import {format} from "date-fns";
import yup from "../../utils/yup";
import baseApi from "../../apis/baseApi";
import {getApiErrorMessage} from "../../utils/apiErrors";
import FieldError from "../../components/form/FieldError";
import {useDispatch, useSelector} from "react-redux";
import {addReservation} from "../../actions/timeslotReservations";
import {Reservation} from "./index";
import {Seat} from "../Toolbox/Rooms";
import {CartMember} from "../Pos/Cart";
import Select from "react-select";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";

type CreateScheduleModalProps = {
    show: boolean,
    onHide: Function,
    timeslot: TimeSlot
}

const CreateReservationModal = ({show, onHide, timeslot}: CreateScheduleModalProps) => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const dispatch = useDispatch()
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")
    const [selectedSeat, setSelectedSeat] = useState<Seat>()
    const [searchTerm, setSearchTerm] = useState("")
    const [fetchingMembers, setFetchingMembers] = useState(false)
    const [members, setMembers] = useState<CartMember[]>([])

    useEffect(() => {
        baseApi.get(
            `/members/search/?branch=${branch.id}`,
        ).then((response) => {
            setMembers(response.data.results);
            setFetchingMembers(false);
        }).catch();
    }, [])

    useEffect(() => {
        const searchFn = setTimeout(() => {
            if (searchTerm.length < 2) return
            setFetchingMembers(true)
            baseApi.get(
                `/members/search/?search=${searchTerm}&branch=${branch.id}`,
            ).then((response) => {
                setMembers(response.data.results);
                setFetchingMembers(false);
            }).catch();
        }, 800);
        return () => clearTimeout(searchFn);
    }, [searchTerm]);

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        member: yup.string().nullable().defined(),
        full_name: yup.string().when("member", {is: null, then: yup.string().required(), otherwise: yup.string()}),
    })

    const renderRoom = () => {
        if (timeslot.room === null || !timeslot.room.uses_map) return <React.Fragment/>
        let slots = Array.from(Array(timeslot.room.cols * timeslot.room.rows).keys())
        let spotWidth = Math.floor(683 / timeslot.room!.cols)
        return <div className=" border p-3 text-center">
            {slots.map(index => {
                const row = Math.floor(index / timeslot.room!.cols)
                const col = index % timeslot.room!.cols
                const seat = timeslot.room!.seats.find(seat => seat.col === col && seat.row === row)
                if (!seat) {
                    return <div className={`d-inline-flex`} key={index} style={{width: spotWidth}}/>
                }
                const occupied = timeslot.occupied_seats.includes(seat.id)
                return <div className={`d-inline-flex justify-content-center `} key={index}
                            style={{width: spotWidth}}>
                    <div onClick={occupied ? () => {
                    } : () => setSelectedSeat(seat)}
                         style={{
                             width: spotWidth / 2,
                             height: spotWidth / 2,
                             backgroundColor: occupied ? "#dee2e6" : selectedSeat?.id === seat.id ? "#ade8f4" : "white"
                         }}
                         className="border rounded-circle seat-select d-flex align-items-center justify-content-center ">
                        <div className="">
                            {seat.label}
                        </div>

                    </div>
                </div>
            })}
        </div>
    }

    return (
        <React.Fragment>
            <Modal size="sm" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{full_name: "", email: "", phone: "", member: undefined}}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        if (timeslot.room !== null && timeslot.room.uses_map && !selectedSeat) {
                            setSuccess(false)
                            setAlertMessage("Debes seleccionar un lugar")
                            setSubmitting(false)
                            return
                        }
                        const url = values.member === null ? "/rsvp/dashboard/" : "/rsvp/"
                        let data: any = {
                            member: values.member,
                            timeslot: timeslot.id,
                            seat: selectedSeat?.id
                        }
                        if (values.member === null) {
                            data.dashboard_reservation = {full_name: values.full_name}
                        }
                        baseApi.post<Reservation>(url, data).then(resp => {
                            const memberData = members.find(m => m.id === values.member)
                            let member = memberData ? {
                                external_id: memberData.external_id,
                                full_name: memberData.full_name,
                                phone: "",
                                shoe_size: "",
                            } : {
                                external_id: "",
                                full_name: values.full_name,
                                phone: values.phone,
                                shoe_size: ""
                            }
                            dispatch(addReservation({
                                ...resp.data,
                                seat: selectedSeat?.label || null,
                                member: member,
                                email: memberData?.email ?? ""
                            }))
                            setAlertMessage("Reservación exitosa")
                            setSuccess(true)
                            setSubmitting(false)
                        }).catch(err => {
                            setAlertMessage(getApiErrorMessage(err))
                            setSuccess(false)
                            setSubmitting(false)
                        })
                    }}>
                    {({isSubmitting, setFieldValue, values}) => (
                        <FormikForm>
                            <Modal.Header className='px-0 font-weight-bolder text-primary border-0 mb-n4'>Agregar Reservación
                            </Modal.Header>

                            <Modal.Body className='px-0 '>
                            <div className='border-bottom mt-n8 text-dark-50 pb-5 font-size-xs'>Agrega una reservacion para un cliente, se ocupará el lugar y mostrará en la lista de reservaciones.</div>

                                {renderAlert()}
                                <div className="mb-5 mt-4">
                                    <div className=" font-weight-bold mb-5 font-size-h4 text-center">{format(timeslot.start, "dd 'de' MMM")}</div>
                                    <div className="font-weight-bold mb-5 font-size-h4 mt-n3 text-center">{timeslot.studio_class.name} <span className='text-primary'>{format(timeslot.start, "HH:mm")}</span></div>
                                </div>
                                <FieldError name="member"/>
                                <div className="w-100 mb-2">
                                    <div className='font-weight-boldest font-size-lg'>Buscar cliente</div>
                                    <Select className='font-size-lg' placeholder="Buscar cliente"
                                            options={
                                                [{
                                                    value: null,
                                                    label: "/// Cliente no registrado"
                                                }, ...members.map((m) => ({
                                                    value: m.id,
                                                    label: `${m.external_id} ${m.full_name} ${m.last_name ?? ''}`
                                                }))]
                                            }
                                            onInputChange={(text) => setSearchTerm(text)}
                                            noOptionsMessage={() => "No se encontraron resultados"}
                                            isLoading={fetchingMembers} loadingMessage={() => "Cargando..."}
                                            onChange={(option) => setFieldValue("member", option?.value)}/>
                                </div>
                                <p className='text-dark-50 font-size-xs'>Se resta -1 clase de su paquete en automático  (si aplica).</p>

                                <div hidden={values.member !== null}>
                                    <div className='font-weight-boldest'>Nombre (cliente sin cuenta):</div>
                                    <div className='w-100 mt-n5'>
                                        <FormField label="" name="full_name"/>
                                    </div>
                                    {/* <p className='text-muted mt-n6'>No se restará la clase ya que el cliente no tiene cuenta.</p> */}

                                </div>
                                <FieldError name="full_name"/>
                                {renderRoom()}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="light-dark font-weight-bolder font-size-h6 text-white" onClick={handleClose}>
                                    Atrás
                                </Button>
                                <Button className='font-weight-bolder font-size-h6 ' type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Guardar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateReservationModal;
