import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field} from "formik";
import FormField from "../../../components/form/FormField";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {StudioClassesState} from "../../../actions/studioClasses";
import baseApi from "../../../apis/baseApi";
import {BranchesState} from "../../../actions";
import FormInputGroup from "../../../components/form/FormInputGroup";
import {addPlan, StudioPlan} from "../../../actions/studioPlans";
import FormSelectAlt, {SelectOption} from "../../../components/form/FormSelectAlt";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import Checkbox from "../../../components/form/Checkbox";
import yup from '../../../utils/yup';
import FieldError from "../../../components/form/FieldError";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStripe} from "@fortawesome/free-brands-svg-icons";

type CreatePlanModalProps = {
    show: boolean,
    onHide: Function,
    noLimitCredits?: boolean
    plan?: StudioPlan
}

const CreateEditPlanModal = ({show, onHide, plan, noLimitCredits = false}: CreatePlanModalProps) => {
    const dispatch = useDispatch()
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)
    const classesState = useSelector<StoreState, StudioClassesState>(state => state.studioClasses)

    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("");

    // @ts-ignore
    const CustomCheckbox = ({field, form: {touched, errors}, ...props}) => (
        <label className="col-form-label px-lg-0 checkbox">
            <input type="checkbox" {...field} {...props} />
            <span/>
            <div className="ml-2">{props.label}</div>
            {touched[field.name] &&
            errors[field.name] && <div className="error">{errors[field.name]}</div>}
        </label>
    );

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMsg}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const validationSchema = yup.object().shape({
        name: yup.string().required().min(3),
        days_duration: yup.number().required().min(1),
        price: yup.number().required().min(0),
    })

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        name: plan?.name,
                        price: plan?.price,
                        credits: plan?.credits,
                        no_limit_credits: noLimitCredits,
                        days_duration: plan?.days_duration,
                        day_reservations_limit: plan?.day_reservations_limit,
                        week_reservations_limit: plan?.week_reservations_limit,
                        allow_all_classes: !plan ? true : plan.allow_all_classes,
                        allow_all_branches: !plan ? true : plan.allow_all_branches,
                        allow_friend_rsvp: !plan ? false : plan.allow_friend_rsvp,
                        allowed_classes: !plan ? [] : plan.allowed_classes.map(c => c.id),
                        public_visible: !plan ? true : plan.public_visible,
                        max_purchases_per_member: plan?.max_purchases_per_member,
                        is_subscription: plan?.is_subscription ?? false,
                        branches: !plan || plan?.allow_all_branches ? [{
                            value: "ALL",
                            label: "Todas"
                        }] : branchesState.selectOptions.filter(option => plan.branches.includes(option.value)),
                        penalizations: null
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        if (plan) {
                            baseApi.put(`/plans/${plan.id}/`, {
                                ...values,
                                week_reservations_limit: values.week_reservations_limit || null,
                                day_reservations_limit: values.day_reservations_limit || null,
                                max_purchases_per_member: values.max_purchases_per_member || null,
                                branches: values.branches.flatMap((option: SelectOption) => option.value !== "ALL" ? option.value : []),
                            }).then((resp) => {
                                // TODO: dispatch update plan
                                setAlertMsg("Plan actualizado correctamente")
                                setSuccess(true)
                                setSubmitting(false)
                            }).catch((err) => {
                                setAlertMsg(getApiErrorMessage(err))
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        } else {
                            baseApi.post("/plans/", {
                                ...values,
                                week_reservations_limit: values.week_reservations_limit || null,
                                day_reservations_limit: values.day_reservations_limit || null,
                                max_purchases_per_member: values.max_purchases_per_member || null,
                                branches: values.branches.flatMap((option: SelectOption) => option.value !== "ALL" ? option.value : []),
                            }).then((resp) => {
                                dispatch(addPlan(resp.data))
                                setAlertMsg("Plan creado correctamente")
                                setSuccess(true)
                                setSubmitting(false)
                            }).catch((err) => {
                                setAlertMsg(getApiErrorMessage(err))
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        }
                    }}>
                    {({isSubmitting, values, setFieldValue}) => (
                        <FormikForm>

                            <Modal.Header className='text-primary font-weight-bolder font-size-h2'>
                                {plan ? "Editar " : "Crear "} {noLimitCredits ? "Membresía" : "paquete de clases"}
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                

                                <div className="align-items-center">
                                    <div className='col-md-3 font-size-lg font-weight-bolder mb-n5'>Nombre Paquete</div>
                                    <div className="col-12 col-md-6">
                                        <FieldError name="name"/>
                                        <FormField label="" name="name"/>
                                    </div>
                                </div>

                                <div className="align-items-center">
                                    {/* Borrar titulo para plan tipo membresia Solicitud22*/}
                                    <div hidden={noLimitCredits} className='col-md-3 font-size-lg font-weight-bolder mb-n5'>Clases incluidas</div>
                                    <div className="col-12 col-md-6">
                                    <FieldError name="credits" />
                                {noLimitCredits ?
                                    <React.Fragment/>
                                    : <FormField label="" name="credits" />
                                }
                                    </div>
                                </div>

                                <div className="align-items-center">
                                    <div className='col-md-3 font-size-lg font-weight-bolder mb-2'>Vigencia Paquete</div>
                                    <div className="col-12 col-md-6 w-100 ml-4">
                                        <FieldError name="days_duration"/>
                                        <FormInputGroup name="days_duration" label="" groupText="días"
                                                    placeholder="15 días"/>
                                    </div>
                                </div>

                                <div className="align-items-center">
                                    <div className='col-md-3 font-size-lg font-weight-bolder mb-2'>Precio</div>
                                    <div className="col-12 col-md-6 w-100 ml-4">
                                        <FieldError name="price"/>
                                        <FormInputGroup name="price" label="" groupText="$" prepend={true} placeholder="800"/>
                                    </div>
                                </div>

                                <hr />

                                <h4 className='text-primary ml-4 my-4'>Configuración Adicional</h4>

                                <div className="align-items-center">
                                    <div className=' font-size-lg mb-1 ml-4'>¿Hay un límite de reservas por <span className='font-weight-bolder'>día</span>?</div>
                                    <div className="col-md-5 w-100 ml-md-">
                                        <div className="input-group ">
                                            <Field name="day_reservations_limit" className="form-control"
                                                   placeholder=""/>
                                            <div className="input-group-append font-size-xs"><span
                                                className="input-group-text text-muted font-size-sm">reservas max. en el mismo día</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='font-size-xs  text-muted ml-4'>Dejar en blanco en caso de que no</div>
                                </div>

                                <div className="my-5"></div>

                                <div className="align-items-center">
                                    <div className=' font-size-lg mb-1 ml-4'>¿Hay un límite de reservas por <span className='font-weight-bolder'>semana</span>?</div>
                                    <div className="col-md-5 w-100 ml-md-">
                                        <div className="input-group ">
                                            <Field name="week_reservations_limit" className="form-control"
                                                   placeholder=""/>
                                            <div className="input-group-append font-size-xs"><span
                                                className="input-group-text text-muted font-size-sm">reservas max. en la semana</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='font-size-xs  text-muted ml-4'>Dejar en blanco en caso de que no</div>
                                </div>

                                <div className="my-5"></div>

                                <div className="align-items-center">
                                    <div className=' font-size-lg mb-1 ml-4'>¿Hay un máximo de compras para este plan (por cliente)? </div>
                                    <div className="col-md-5 w-100 ml-md-">
                                        <div className="input-group ">
                                            <FieldError name="max_purchases_per_member"/>
                                            <Field name="max_purchases_per_member" className="form-control"
                                                   placeholder=""/>
                                            <div className="input-group-append font-size-xs"><span
                                                className="input-group-text text-muted font-size-sm">compras por cliente max.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='font-size-xs  text-muted ml-4'>Para <span className='font-weight-bolder text-primary'>clase de prueba</span> ingresa 1. Dejar en blanco en caso de que no</div>
                                </div>

                
                                <div className='d-flex align-items-center ml-4 mt-5'>
                                    <div className="">
                                        <span className='font-size-lg mr-3'>Vender este plan <span className='font-weight-bolder'>en línea</span></span>
                                        <div className='text-muted'>Será visible para clientes en app</div>
                                    </div>

                                    <div className="d-inline-flex pb-4 align-items-center switch switch-success m-4 border-0">
                                        <Field component={Checkbox} name="public_visible"
                                               label=""/>
                                    </div>
                                </div>

                                {/*<div className='d-flex align-items-center ml-4'>*/}
                                {/*    <div>*/}
                                {/*        <span className='font-size-lg  mr-3'>Clientes pueden <span className='font-weight-bolder'>compartir</span> este plan</span>*/}
                                {/*        <div className='text-muted'>Podrán reservar más de 1 lugar por horario</div>*/}
                                {/*    </div>*/}
                                {/*    <div className="d-inline-flex pb-4 align-items-center switch switch-success m-4 border-0">*/}
                                {/*        <Field component={Checkbox} name="allow_friend_rsvp"*/}
                                {/*           label=""/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <hr />

                                <div className='ml-4 my-8'>
                                    <div className='font-size-lg'><span className='font-weight-bolder text-primary font-size-h5'>Tipo de clases</span> permitidas para reservar con este plan</div>
                                    <div className='text-muted'>El cliente solo podrá reservar los horarios que sean el tipo de clase seleccionado abajo</div>
                                    <Field component={CustomCheckbox} type="checkbox" name="allow_all_classes"
                                               label="Cualquier tipo de clase"/>
                                        {values.allow_all_classes ?
                                            <React.Fragment/> :
                                            classesState.classes.map((c) => (
                                                <Field key={c.id} component={CustomCheckbox} type="checkbox" value={c.id}
                                                       name="allowed_classes" label={c.name}/>
                                            ))
                                        }
                                </div>






                                {/* FIN NUEVO */}
                                {/* FIN NUEVO */}
                                {/* FIN NUEVO */}
                                {/* FIN NUEVO */}
                                {/* FIN NUEVO */}


                                {/* <FieldError name="name"/>
                                <FormField label="Nombre" name="name"/>

                                <FieldError name="credits" />
                                {noLimitCredits ?
                                    <React.Fragment/>
                                    : <FormField label="Clases incluidas" name="credits" />
                                }
                                <FieldError name="days_duration"/>
                                <FormInputGroup name="days_duration" label="Vigencia" groupText="días"
                                                placeholder="30"/>
                                <FieldError name="price"/>
                                <FormInputGroup name="price" label="Precio" groupText="$" prepend={true}/>
                                <div className="offset-lg-1 d-inline-flex">
                                    <Field disabled={plan} component={CustomCheckbox} type="checkbox" name="is_subscription"
                                           label="Suscripción (cobro automático)"/>
                                    <FontAwesomeIcon color="purple" size={"2x"} icon={faStripe} />
                                </div>
                                <hr/>
                                <h5 className="offset-lg-1 pb-4">Condiciones</h5>
                                <div>
                                    <div className="offset-lg-1 d-inline-flex pb-4 align-items-center">
                                        <Field component={Checkbox} name="public_visible"
                                               label="Vender en línea"/>
                                    </div>
                                </div>
                                <div className="offset-lg-1 pb-4 d-inline-flex align-items-center">
                                    <Field component={Checkbox} name="allow_friend_rsvp"
                                           label="Permitir reservar para un amigo"/> <span
                                    className="text-muted font-size-xs ml-1">(Usuarios podrán reservar mas de un lugar)</span>
                                </div>

                                <div className="form-group row">
                                    <label className="col-lg-4 offset-lg-1 col-form-label ">
                                        - Limite de Reservaciones
                                    </label>
                                    <div className="col-lg-2 ml-n20">
                                        <div className="input-group ">
                                            <Field name="day_reservations_limit" className="form-control"
                                                   placeholder=""/>
                                            <div className="input-group-append font-size-xs"><span
                                                className="input-group-text text-muted font-size-sm">/dia</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="input-group ">
                                            <Field name="week_reservations_limit" className="form-control"
                                                   placeholder=""/>
                                            <div className="input-group-append font-size-xs"><span
                                                className="input-group-text text-muted font-size-sm">/semana</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-4 offset-lg-1 col-form-label ">
                                        - Limite de compras
                                    </label>
                                    <div className="col-lg-3 ml-n20">
                                        <FieldError name="max_purchases_per_member"/>
                                        <div className="input-group ">
                                            <Field name="max_purchases_per_member" className="form-control"
                                                   placeholder=""/>
                                            <div className="input-group-append font-size-xs"><span
                                                className="input-group-text text-muted font-size-sm">/cliente</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5"/>
                                </div>

                                <FormSelectAlt
                                    label="Sucursales" placeholder="seleccionar"
                                    value={values.branches}
                                    options={[{
                                        value: "ALL",
                                        label: "Todas"
                                    }, ...branchesState.selectOptions]}
                                    onChange={(options: SelectOption[]) => {
                                        const todas = options.find(op => op.value === "ALL")
                                        if (todas) {
                                            setFieldValue("allow_all_branches", true)
                                        } else {
                                            setFieldValue("allow_all_branches", false)
                                        }
                                        setFieldValue("branches", options)
                                    }
                                    }

                                    isMulti={true}/>

                                    <hr />
                                <div className="offset-lg-1">
                                    <label className="col-form-label font-size-h6">
                                        Selecciona el tipo de <strong>clases</strong> que podran reservar al adquirir este paquete
                                    </label>
                                    <Field component={CustomCheckbox} type="checkbox" name="allow_all_classes"
                                           label="Todas"/>
                                    {values.allow_all_classes ?
                                        <React.Fragment/> :
                                        classesState.classes.map((c) => (
                                            <Field key={c.id} component={CustomCheckbox} type="checkbox" value={c.id}
                                                   name="allowed_classes" label={c.name}/>
                                        ))
                                    }

                                </div> */}


                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="light-primary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Guardar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateEditPlanModal;
