export enum ActionTypes {
    LOGGING_IN = "LOGGING_IN",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_FAILED = "LOGIN_FAILED",
    LOGOUT = "LOGOUT",
    LOGOUT_WITH_ERROR = "LOGOUT_WITH_ERROR",
    STORAGE_TOKEN_SUCCESS = "STORAGE_TOKEN_SUCCESS",
    STORAGE_TOKEN_FAILED = "STORAGE_TOKEN_FAILED",

}

export enum BranchActionTypes {
    BRANCHES_FETCHED_SUCCESS = "BRANCHES_FETCHED_SUCCESS",
    BRANCHES_FETCHED_FAILED = "BRANCHES_FETCHED_FAILED",
    UPDATE_BRANCHES = "UPDATE_BRANCHES",
    SELECT_BRANCH = "SELECT_BRANCH",
    REMOVE_BRANCH = "REMOVE_BRANCH",
}

export enum StudioActionTypes {
    RETRIEVED_STUDIO = "RETRIEVED_STUDIO"
}

export enum StudioClassesTypes {
    FETCHING = "FETCHING_CLASSES",
    FETCHED = "FETCHED_CLASSES",
    FETCHING_FAILED = "FETCHING_CLASSES_FAILED",
    ADD_CLASS = "ADD_CLASS",
    REMOVE_CLASS = "REMOVE_CLASS",
    UPDATE_CLASS = "UPDATE_CLASS",
}

export enum StaffActionTypes {
    FETCHING = "FETCHING_STAFF_USER",
    FETCHED = "FETCHED_STAFF_USER",
    FETCHING_FAILED = "FETCHING_STAFF_USER_FAILED",
}

export enum CheckoutActionTypes {
    FETCHED_PRICES = "FETCHED_PRICES",
    FETCHED_PM = "FETCHED_PM",
    NEXT_STEP = "NEXT_STEP",
    ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD",
    SELECT_PLAN = "SELECT_PLAN"
}

export enum TimeSlotReservationsTypes {
    FETCHING = "FETCHING_RESERVATIONS",
    FETCHED = "FETCHED_RESERVATIONS",
    SELECT_RESERVATION = "SELECT_RESERVATION",
    UPDATE_RESERVATION = "UPDATE_RESERVATION",
    ADD_RESERVATION = "ADD_RESERVATION",
}

export enum StudioPlansActionTypes {
    FETCHING = "FETCHING_PLANS",
    FETCHED = "FETCHED_PLANS",
    ADD_PLAN = "ADD_PLAN",
    REMOVE_PLAN = "REMOVE_PLAN",
    UPDATE_PLAN = "UPDATE_PLAN",
}
