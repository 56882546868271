import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";
import React, {useEffect, useState} from "react";
import baseApi from "../../apis/baseApi";
import DeleteModal from "../../components/modals/DeleteModal";
import CreateEditCoachModal from "./CreateEditCoachModal";
import ToolboxHeader from "../Toolbox/ToolboxHeader";
import {Button, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashAlt, faUserCircle, faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";
import {User} from "../../actions/user";



export interface Coach {
    id: string,
    user: User | null
    full_name: string
    img: string | null
}

const Coaches = () => {
    const [coaches, setCoaches] = useState<Coach[]>([]);
    const [showCreate, setShowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [selectedCoach, setSelectedCoach] = useState<Coach>()

    useEffect(() => {
        baseApi.get(`/coaches/`).then((resp) => {
            setCoaches(resp.data)
        })
    }, []);

    return (
        <div className="p-4">
            <DeleteModal url={`/coaches/update/${selectedCoach?.id}/`} onSuccess={() => {
                setCoaches(coaches.filter(c => c.id !== selectedCoach?.id))
            }} show={showDelete} onHide={() => {
                setShowDelete(false)
                setSelectedCoach(undefined)
            }}>
                <p className="font-size-h6 text-danger">¿Eliminar <span>{selectedCoach?.full_name}</span>?</p>
                <div>Esta acción es irreversible y se eliminará el instructor de todos los horarios en los que este asignado.</div>
            </DeleteModal>
            <CreateEditCoachModal
                coach={selectedCoach}
                show={showCreate}
                coaches={coaches}
                setCoaches={setCoaches}
                onHide={() => {
                    setShowCreate(false)
                    setSelectedCoach(undefined)
                }}/>

            {/* <div className="d-flex align-items-center mb-15">
                <i className="fi-rr-calendar mr-3"></i>
                <h2 className="text-dark">Coaches</h2>
                <button onClick={() => setShowCreate(true)}
                        className="d-none d-md-inline ml-5 btn btn-light-primary  btn-pill btn-lg bg-primary-o-50 text-primary border-0 btn-hover-primary font-weight-bold">+
                    Nuevo Coach
                </button>
            </div> */}

            {/* <button className='btn btn-link-primary mb-3'><FontAwesomeIcon icon={faLongArrowAltLeft} size="lg" className='mr-3'/>Configuración</button> */}
                <div className=" align-items-center ">
                    <h2 className="pt-2 font-size-h2 font-weight-bolder">Instructores</h2>
                    <div className="d-md-flex ">
                            <p className="font-size-lg text-dark-50 d-block">Crea y maneja los instructores de tus clases para asignarlos a tus horarios.</p>
                        </div>
                
                </div>
                <button onClick={() => setShowCreate(true)}
                        className="btn btn-success font-weight-bold font-size-h6 font-weight-bolder mb-5">+
                    Nuevo Instructor
                </button>

                <hr />

            {/* <Row>
                <div hidden={coaches.length > 0} className="text-center text-muted font-size-h2 w-100 mt-15">
                    Aún no hay instructores, crea tu primer instructor 
                    <button onClick={() => setShowCreate(true)}
                        className="btn btn-success font-weight-bold font-size-h6 font-weight-bolder mb-5">+
                    Crear Instructor
                </button>
                </div>
                {coaches.map((c) => {
                    return <div key={c.id} className="col-6 col-md-3 col-xxl-2 mb-2">
                        <div className="card card-custom overlay text-center py-5 shadow pt-8"> */}

                            {/* IMAGEN COACH */}
                            {/* IMAGEN COACH */}

                            {/* <div className="symbol symbol-60 symbol-lg-85  mx-auto pb-5">
                                {c.img ?
                                    <img alt="Pic"
                                         src={c.img}/>
                                    : <div className="text-muted">
                                        <FontAwesomeIcon icon={faUserCircle} style={{fontSize: "85px"}}/>
                                    </div>


                                }
                            </div> */}


                            {/* <div className=" align-items-start justify-content-between pt-1 pr-1">
                            <div className="font-weight-bold font-size-h6 mb-3">{c.full_name}</div>

                            <Button onClick={() => {
                                    setSelectedCoach(c)
                                    setShowCreate(true)
                                }} bsPrefix="btn btn-light-primary bg-light-primary btn-sm mr-3" size="sm">
                                    <span className="mr-2">Editar</span><FontAwesomeIcon icon={faEdit} size="sm"/>
                                </Button>
                                <Button
                                    onClick={() => {
                                        setSelectedCoach(c)
                                        setShowDelete(true)
                                    }}
                                    bsPrefix="btn btn-light-danger bg-light-danger btn-hover-danger bg-white btn-sm " size="sm">
                                    <FontAwesomeIcon icon={faTrashAlt} size="sm"/>
                                </Button>
                                
                            </div>
                        </div>
                    </div>
                })}
            </Row> */}

            <div className=" p-5 col-12 col-md-6 col-lg-5">

                <div className="mb-3 font-size-h4">Nombre Instructor</div>
                <div hidden={coaches.length > 0} className="text-center text-muted font-size-h2 w-100 mt-15">
                    Aún no hay instructores, crea tu primer instructor 
                    <button onClick={() => setShowCreate(true)}
                        className="btn btn-success font-weight-bold font-size-h6 font-weight-bolder mb-5">+
                    Crear Instructor
                </button>
                </div>
                <Table bordered>
                    <tbody>
                    {coaches.map((c) => {
                        return <tr key={c.id} className="col-6 col-md-3 col-xxl-2 mb-2">
                            <td>
                                <div className="font-weight-bold font-size-h6 mb-3">{c.full_name}</div>
                            </td>
                            <td className="text-right">
                            <Button onClick={() => {
                                        setSelectedCoach(c)
                                        setShowCreate(true)
                                    }} bsPrefix="btn btn-light-primary bg-light-primary btn-sm mr-3" size="sm">
                                        <span className="mr-2">Editar</span><FontAwesomeIcon icon={faEdit} size="sm"/>
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setSelectedCoach(c)
                                            setShowDelete(true)
                                        }}
                                        bsPrefix="btn btn-light-danger bg-light-danger btn-hover-danger bg-white btn-sm " size="sm">
                                        <FontAwesomeIcon icon={faTrashAlt} size="sm"/>
                                    </Button>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </Table>
                <div className="font-size-lg text-muted  pt-4">Los instructores mostrados aquí te aparecerán en el listado de "Instructores" al crear un horario.</div>
            </div>




        </div>
    );
};

export default Coaches;

