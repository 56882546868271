import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field, ErrorMessage} from "formik";
import FormField from "../../components/form/FormField";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {BranchesState} from "../../actions";
import baseApi from "../../apis/baseApi";
import {Member} from "./index";
import {getApiErrorMessage} from "../../utils/apiErrors";
import ModalSelect from "../../components/form/ModalSelect";
import {FormikSelect} from "../../components/form/FormikSelect";
import {Studio} from "../../actions/studio";
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {format} from "date-fns";
import Select from "react-select";
import {MX_SIZES} from "../../data/shoeSizes";

type AddUserModalProps = {
    show: boolean,
    onHide: Function,
    membersList: Member[],
    setMembersList: (members: Member[]) => void
}

const CreateMemberModal = ({show, onHide, membersList, setMembersList}: AddUserModalProps) => {
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };
    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik initialValues={{
                    full_name: "", last_name: "", username: "", phone: "", current_member: {
                        preferred_branch: branchesState.branches[0].id,
                        studio: studio.id,
                        birthday: null as Date | null,
                        shoe_size: null as string | null,
                    }
                }}
                        onSubmit={(values, {setSubmitting}) => {
                            setSuccess(null)
                            let birthday = values.current_member.birthday ? format(values.current_member.birthday as Date, "yyyy-MM-dd") : null
                            let current_member = {...values.current_member, birthday: birthday}
                            // current_member
                            baseApi.post("/members/", {
                                ...values,
                                current_member: current_member,
                                requires_create_password: true,
                                password: studio.name.replaceAll(" ", "").toLowerCase(),
                            }).then((resp) => {
                                setSuccess(true)
                                setAlertMessage("Se ha creado el usuario correctamente.")
                                console.log(resp.data)
                                // setMembersList([...membersList, resp.data])
                                setSubmitting(false)
                            }).catch((err) => {
                                setAlertMessage(getApiErrorMessage(err))
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        }}>
                    {({isSubmitting, values, setFieldValue}) => (
                        <FormikForm>

                            <Modal.Header className='text-primary font-weight-boldest'>
                                Agregar cliente
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div className='font-size-h6 font-weight-bolder ml-4 mb-n5'>Nombre completo</div>
                                <div className="col-12 col-lg-6">
                                    <FormField label="" name="full_name"/>
                                </div>
                                <div hidden={!studio.preferences.uses_last_name}>
                                    <FormField label="- Apellido" name="last_name"/>
                                </div>
                                <div className='font-size-h6 font-weight-bolder ml-4'>Correo electrónico</div>
                                <div className='text-muted font-size-sm ml-4  mb-n5'>Este es el usuario que utilizará el cliente para ingresar a la app</div>
                                <div  className="col-12 col-lg-6">
                                    <FormField label="" name="username"/>
                                </div>
                                <div className='font-size-h6 font-weight-bolder ml-4 mb-n5'>Teléfono</div>
                                <div  className="col-12 col-lg-6">
                                    <FormField label="" name="phone"/>
                                </div>
                                <div className="font-weight-bolder font-size-h6 text-primary ml-4">Contraseña: <span
                                    className="font-weight-bold text-dark">{studio.name.replaceAll(" ", "").toLowerCase()}</span>
                                </div>
                                {/* SUCURSAL */}
                                            {/* <div hidden={branchesState.branches.length === 1}>
                                                <ModalSelect label="Sucursal">
                                                    <Field name="current_member.preferred_branch"
                                                        options={branchesState.selectOptions}
                                                        component={FormikSelect}/>
                                                </ModalSelect>
                                            </div> */}
                                {/* SUCURSAL */}

                                {/* CUMPLEANOS */}
                                                {/* <div style={{width: "550px"}} className="d-flex flex-row pb-3 offset-lg-1">
                                                    <div style={{width: "120px"}}>
                                                        <span className="">- Cumpleaños</span>
                                                    </div>
                                                    <div className="customDatePickerWidth" style={{width: "220px"}}>
                                                        <ReactDatePicker
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            dateFormat="dd 'de' MMMM 'de' yyyy"
                                                            maxDate={new Date()}
                                                            locale={es}
                                                            selected={values.current_member.birthday}
                                                            onChange={date => {
                                                                if (date) {
                                                                    setFieldValue("current_member.birthday", date)
                                                                }
                                                            }}
                                                            customInput={<input className="form-control"/>}
                                                        />
                                                    </div>
                                                </div> */}
                                {/* FIN CUMPLEANOS */}

                                {/* ZAPATOS */}
                                            {/* <div hidden={!studio.preferences.uses_shoe_field}>
                                                <ModalSelect label={"Talla de zapatos"} >

                                                <Select placeholder={""}
                                                        isClearable={true}
                                                        onChange={(option :{ value: string, label: string } | null) => {
                                                            setFieldValue("current_member.shoe_size", option?.value)
                                                        }}
                                                        options={MX_SIZES.map(s => ({value: s.toString(), label: s.toString()}))}/>
                                                </ModalSelect>
                                            </div> */}
                                {/* FIN ZAPATOS */}

                                {/* CONTRASENA2 */}
                                                {/* <div className="offset-lg-1 font-weight-bolder">- Contraseña: <span
                                                    className="font-weight-bolder">{studio.name.replaceAll(" ", "").toLowerCase()}</span>
                                                </div> */}
                                {/* FIN CONTRASENA2 */}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Atrás
                                </Button>
                                <Button className='font-weight-bolder btn-light-primary' type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Crear"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateMemberModal;
