import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollarSign} from "@fortawesome/free-solid-svg-icons";
import {IconDefinition} from "@fortawesome/free-regular-svg-icons";

type ToolboxSidebarItemProps = {
    text: string
    active: boolean
    onClick: () => void
    icon: IconDefinition
}

const ToolboxSidebarItem = ({text, onClick, active, icon} : ToolboxSidebarItemProps) => {
    return (
        
<div className="navi-item  border-0  mr-2   flex-column border w-150px mb-2  px-0 border border-primary" onClick={onClick}>
    
            <div className={`navi-link py-2 border rounded ${active ? "active" : ""}`}>

                <div className="d-block mr-3  p-1 d-block text-center mx-auto">
                    <div className="text-primary">
                        {/* <FontAwesomeIcon icon={icon} /> */}
                    </div>
                    <div className="d-block font-weight-bold font-size-md">{text}</div>

                </div>

                

            </div>
        </div>
    );
};

export default ToolboxSidebarItem;
