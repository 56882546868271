import React from 'react';
import {Field} from 'formik';

type FormInputGroupProps = {
    name: string
    label: string
    groupText: string
    placeholder?: string
    prepend?: boolean
    extraElement? : JSX.Element
    row? : boolean
}

const FormInputGroup = ({name, label, placeholder, groupText, row=true, extraElement = <React.Fragment />, prepend = false}: FormInputGroupProps) => {
    const getElement = () => {
        if (prepend) {
            return <div className="input-group">
                <div className={"input-group-prepend"}><span
                    className="input-group-text">{groupText}</span></div>
                <Field type="text" name={name} className="form-control" placeholder={placeholder}/>
            </div>
        }
        return <div className="input-group ">
            <Field type="text" name={name} className="form-control" placeholder={placeholder}/>
            <div className={"input-group-append"}><span
                className="input-group-text">{groupText}</span></div>
        </div>
    }

    return (
        <div className={`form-group ${row ? "row" : ""}`}>
            <label className={ row ? "" : ""}>
                {label}
            </label>
            <div className={row ? "" : ""}>
                {getElement()}
            </div>
            {extraElement}
        </div>
    );
};

export default FormInputGroup;
