import { StudioActionTypes} from "../actions";
import {Studio, StudioActions} from "../actions/studio";

const initialState = {
    id: "",
    name: "",
    facebook: "",
    instagram: "",
    image: "",
    preferences: {}
} as Studio

export const studioReducer = (state = initialState, action: StudioActions) => {
    switch (action.type) {
        case StudioActionTypes.RETRIEVED_STUDIO:
            return action.payload
        default:
            return state
    }
}