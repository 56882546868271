import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Formik, Form as FormikForm, Field} from "formik";
import {Alert, Button, Col, Row} from "react-bootstrap";
import {faPen, faTrashAlt, faEdit, faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Branch, BranchesState, removeBranch} from "../../../actions";
import {StoreState} from "../../../reducers";
import baseApi from "../../../apis/baseApi";
import {setStudio, Studio} from "../../../actions/studio";
import ToolboxHeader from "../ToolboxHeader";
import ImageField from "../../../components/form/ImageField";
import FormInputGroup from "../../../components/form/FormInputGroup";
import CreateEditBranchModal from './CreateEditBranchModal';
import DeleteModal from "../../../components/modals/DeleteModal";
import yup from '../../../utils/yup';
import FieldError from "../../../components/form/FieldError";

const Branches = () => {
    const dispatch = useDispatch()
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)

    const [showCreate, setShowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [img, setImg] = useState<null | File>(null);
    const [editName, setEditName] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState<Branch>();
    const [success, setSuccess] = useState<boolean>();

    const ValidationSchema = yup.object().shape({
        whatsapp: yup.string().test('len', 'El numero debe ser exactamente de 10 dígitos', val => val === undefined ? true : val.length === 10)
    })

    const renderAlert = () => {
        if (success === undefined) return <React.Fragment/>
        return <Alert className="mt-2"
                      variant={success ? "success" : "danger"}>{success ? "Estudio actualizado correctamente." : "Error"}</Alert>
    }

    return (
        <div style={{overflowY: "auto"}} className="p-4">
            <CreateEditBranchModal show={showCreate} onHide={() => {
                setShowCreate(false)
                setSelectedBranch(undefined)
            }} branch={selectedBranch}/>
            <DeleteModal url={`/studios/branch/${selectedBranch?.id}/`} onSuccess={() => {
                dispatch(removeBranch(selectedBranch?.id || ""))
            }} show={showDelete} onHide={() => {
                setShowDelete(false)
                setSelectedBranch(undefined)
            }}>
                <p>¿Estas seguro que quieres eliminar la sucursal <strong>{selectedBranch?.name}</strong></p>
            </DeleteModal>

            {/* <ToolboxHeader title="Mi Estudio"/> */}
            {/* <button className='btn btn-link-primary mb-3'><FontAwesomeIcon icon={faLongArrowAltLeft} size="lg" className='mr-3' />Configuración</button> */}
            <div className=" align-items-center mb-5">
                <h2 className="pt-2 font-size-h2 font-weight-bolder">General</h2>
                <div className="d-md-flex ">
                    <p className="font-size-lg text-dark-50 d-block">Ingresa el nombre de tu gimnasio, logo y dirección.</p>
                </div>
            </div>
            {/* <h1 className='text-primary font-weight-bolder'>General</h1> */}

            <hr />
            <div className=" p-5 col-12 col-lg-8 col-xxl-6">
                <Row className="">
                    <Col className="border-bottom mb-3 px-15 col-12">
                        <Formik
                            validationSchema={ValidationSchema}
                            initialValues={{name: studio.name, instagram: studio.instagram, whatsapp: studio.whatsapp}}
                            onSubmit={(values, {setSubmitting}) => {
                                setSuccess(undefined)
                                if (img) {
                                    const formData = new FormData();
                                    formData.append("image", img, img.name);
                                    baseApi.patch('/studios/', formData).then(resp => {
                                        dispatch(setStudio({...studio, ...resp.data}))
                                    })
                                }
                                baseApi.patch("/studios/", values).then((resp) => {
                                    dispatch(setStudio({...studio, ...resp.data}))
                                    setSuccess(true)
                                    setEditName(false)
                                    setSubmitting(false)
                                }).catch((err) => {
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            }}>
                            {({isSubmitting}) => (
                                <FormikForm>
                                    {renderAlert()}
                
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="d-flex align-items-center pt-8">
                                                <h3 className="text-primary">Nombre estudio: </h3>
                                                {editName ? <Field className="form-control align-self-center w-25" name="name"/>
                                                    : <p className="h4  align-self-center align-self-middle ml-1">{studio.name}</p>
                                                }
                                                <Button
                                                    size="sm"
                                                    className="btn btn-primary btn-icon p-2"
                                                    onClick={() => setEditName(!editName)}
                                                >
                                                    <FontAwesomeIcon icon={faEdit} size="1x"/> <span></span>
                                                </Button>
                                            </div>
                                            <div className="d-block pb-2"/>
                
                                            {/* INSTAGRAM Y WHATSAPP INPUTS DESACTIVADOS */}
                                            {/* INSTAGRAM Y WHATSAPP INPUTS DESACTIVADOS */}
                                            {/* <h3 className="text-muted pt-5 font-size-lg mb-n3 mt-10">Instagram</h3>
                                            <FormInputGroup name="instagram" label="" groupText="instagram.com/"
                                                            prepend={true} row={false}/>
                                             <h3 className="text-muted pt-5 font-size-lg mb-n3">WhatsApp</h3>
                                            <FieldError name="whatsapp"/>
                                            <FormInputGroup name="whatsapp" label="" groupText="+52"
                                                            prepend={true} row={false}/> */}
                                        </div>
                                        <div className=" col-12">
                                            <div className="">
                                                <div className="mr-15">
                                                    <h3 className="pt-2 text-primary">Logo</h3>
                                                    <ImageField src={studio.image} onChange={(file) => setImg(file)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Button className='font-weight-bolder mt-5 mb-8' type="submit" disabled={isSubmitting}>Guardar Cambios</Button>
                                </FormikForm>
                            )}
                
                        </Formik>
                    </Col>
                    <Col className="px-15 col-12">
                        {/* <div className="d-flex align-items-center my-5">
                            <h3 className="text-muted pt-2 font-size-sm">SUCURSALES</h3>
                            <button onClick={() => setShowCreate(true)} className="btn btn-light-primary btn-pill ml-3">+
                                Nueva Sucursal
                            </button>
                        </div> */}
                        {branchesState.branches.map((branch) => {
                            return <div key={branch.id} className="mb-3">
                                <div className="card-body mt-3">
                                    <div className="d-flex align-items-center justify-content-between mb-5">
                                        <div className=' mt-'>
                                            <div className='text-primary font-weight-bolder font-size-h6'>Dirección y Zona Horaria</div>
                                            <h3 className="text-muted font-size-lg font-weight-normal">Información visible para tus clientes en app.</h3>
                                            <h3 className="text-muted font-size-lg font-weight-normal mt-n2">Te recomendamos llenarlos correctamente.</h3>

                                        </div>
                                        <div>
                                            <Button onClick={() => {
                                                setSelectedBranch(branch)
                                                setShowCreate(true)
                                            }} className="btn btn-primary btn-sm font-weight-bold font-size-lg mt-n3">
                                                Editar<FontAwesomeIcon icon={faEdit} className="ml-2"/>
                                            </Button>
                                            {/* <Button onClick={() => {
                                                setSelectedBranch(branch)
                                                setShowDelete(true)
                                            }} className="btn btn-icon btn-light-danger btn-sm bg-white">
                                                <FontAwesomeIcon icon={faTrashAlt}/>
                                            </Button> */}
                                        </div>
                                    </div>
                
                                    <div className="mb-6">
                                        <h3 className="text-primary font-size-sm mb-n1">Dirección</h3>
                                        <div className="font-size-lg">{branch.address.line1}. {branch.address.city}, {branch.address.state}.</div>
                                    </div>
                                    {/* CODIGO POSTAL */}
                                    {/* <div className="mb-6">
                                        <h3 className="text-muted font-size-sm mb-n1">Código Postal</h3>
                                        <div className="font-size-lg">{branch.address.zip_code}</div>
                                    </div> */}
                                    {/* <div className="mb-6">
                                        <h3 className="text-muted font-size-sm mb-n1">Ciudad</h3>
                                        <div className="font-size-lg">{branch.address.city}</div>
                                    </div>
                                    <div className="mb-6">
                                        <h3 className="text-muted font-size-sm mb-n1">Estado</h3>
                                        <div className="font-size-lg">{branch.address.state}</div>
                                    </div> */}
                
                                    <div className="mb-6">
                                        <h3 className="text-primary font-size-sm mb-n1">Teléfono</h3>
                                        <div className="font-size-lg">{branch.phone}</div>
                                    </div>
                
                                </div>
                            </div>
                        })}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Branches;
