import React from 'react';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEllipsisH, faTrashAlt} from "@fortawesome/free-solid-svg-icons";

type TableActionsCellProps = {
    onClickDetails?: () => void
    onClickEdit?: () => void
    onClickDelete?: () => void
}

const TableActionsCell = ({onClickDetails, onClickDelete, onClickEdit}: TableActionsCellProps) => {
    return (
        <div>
            <Button onClick={onClickDetails} className="btn btn-icon btn-light-primary bg-light-primary btn-xs mr-3"
                    hidden={!onClickDetails}>
                <FontAwesomeIcon icon={faEllipsisH} size="sm"/>
            </Button>
            <Button onClick={onClickEdit} className="btn btn-icon btn-light-primary bg-light-primary btn-xs mr-3"
                    hidden={!onClickEdit}>
                <FontAwesomeIcon icon={faEdit} size="sm"/>
            </Button>
            <Button onClick={onClickDelete} className="btn btn-icon btn-light-danger bg-light-danger btn-hover-danger bg-white btn-xs "
                    hidden={!onClickDelete}>
                <FontAwesomeIcon icon={faTrashAlt} size="sm"/>
            </Button>
        </div>
    );
};

export default TableActionsCell;
