import React from 'react';

const BlankPage2 = () => {
    return (
        <div>
            <h1>Próximamente</h1>
            <div>Agrega tus entrenamientos y WOD's a la app para tus clientes.</div>
        </div>
    );
};

export default BlankPage2;
