import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import Select, {ActionMeta,  ValueType} from "react-select";
import {Row, Col} from "react-bootstrap";


type FormSelectProps = {
    label: string,
    icon: IconProp,
    placeholder: string
    options: { value: string | number | boolean, label: string }[],
    value: any,
    isClearable? : boolean
    onChange: (value: ValueType<any, any>, actionMeta: ActionMeta<any>) => void
}

const FormSelect = ({value, icon, placeholder, label, options, onChange, isClearable = false}: FormSelectProps) => {
    return (
        <Row style={{width: "400px"}} className="">
            <Col lg={12}>
                <div className="d-flex flex-row align-items-start justify-content-between">
                    <div style={{width: "30px"}}>
                        <FontAwesomeIcon icon={icon}/>
                    </div>

                    <div style={{width: "130px"}}>
                        <span className="ml-2" >{label}</span>
                    </div>
                    <div className="w-100">

                        <Select
                            // value={value}
                            value={value}
                            className="w-100 pl-2 pb-4"
                            options={options}
                            placeholder={placeholder}
                            onChange={onChange}
                            isClearable={isClearable}
                        />
                    </div>
                </div>
            </Col>
        </Row>

    );
};

export default FormSelect;
