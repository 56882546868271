import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field, ErrorMessage} from "formik";
import {MemberPlan} from "./MemberPlans";
import {formatDate} from "../../../utils/dateFormatters";
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {addDays, isBefore, parseISO} from "date-fns";
import yup from '../../../utils/yup';
import baseApi from "../../../apis/baseApi";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {Member} from "../index";

type Props = {
    show: boolean,
    onHide: Function
    membership?: MemberPlan,
    memberPlans: MemberPlan[]
    setMemberPlans: (plans: MemberPlan[]) => void
    member: Member,
    setMember: (member: Member) => void
}

const EditPlanExpirationModal = ({show, onHide, membership, memberPlans, setMemberPlans, member, setMember}: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        expire_on: yup.date().required()
    })

    if (!membership) return <React.Fragment/>
    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{expire_on: parseISO(membership?.expire_on)}}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        baseApi.patch(`/memberships/update/${membership?.id}/`, values).then((resp) => {
                            setSuccess(true)
                            setAlertMessage("Vigencia actualizada")
                            // if (isBefore(parseISO(membership!.expire_on), new Date()) && !membership!.plan.no_limit_credits) {
                            //     // Update member credits state
                            //     if (membership!.credits_available !== null && membership!.credits_available > 0) {
                            //         setMember({
                            //             ...member,
                            //             current_credits: member.current_credits - membership?.credits_available
                            //         })
                            //     }
                            // }
                            setMemberPlans(memberPlans.map(m => m.id !== membership?.id ? m : {
                                ...m,
                                expire_on: resp.data.expire_on
                            }))
                            setSubmitting(false)
                        }).catch(err => {
                            setSuccess(false)
                            setAlertMessage(getApiErrorMessage(err))
                            setSubmitting(false)
                        })
                    }}>
                    {({isSubmitting, setFieldValue, values}) => (
                        <FormikForm>
                            <Modal.Header>
                                Editar Vencimiento
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div className="border-bottom mb-5">
                                    <p>{membership?.plan.name}</p>
                                    <p>Fecha de vencimiento
                                        actual: <strong> {formatDate(membership?.expire_on)} </strong></p>
                                </div>
                                <p className="m-0">Nueva fecha de vencimiento</p>
                                <div className="customDatePickerWidth" style={{width: "220px"}}>

                                    <ReactDatePicker
                                        dateFormat="d 'de' MMMM 'del' yyyy"
                                        locale={es}
                                        selected={values.expire_on}
                                        onChange={date => {
                                            setFieldValue("expire_on", date)
                                        }}
                                        customInput={<input className="form-control"/>}
                                    />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Guardar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default EditPlanExpirationModal;
