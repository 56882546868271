import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {faCircle,faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Table} from 'react-bootstrap';


import {StoreState} from "../../../reducers";
import {removeClass, StudioClass} from "../../../actions/studioClasses";
import ToolboxHeader from "../ToolboxHeader";
import TableHeader from "../../../components/table/TableHeader";
import CreateEditClassModal from './CreateEditClassModal';
import TableActionsCell from "../../../components/table/TableActionsCell";
import DeleteModal from "../../../components/modals/DeleteModal";
import EmptyTr from "../../../components/table/rows/EmptyTr";

const StudioClasses = () => {
    const dispatch = useDispatch()
    const classes = useSelector<StoreState, StudioClass[]>(state => state.studioClasses.classes)

    const [showCreate, setShowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [selectedClass, setSelectedClass] = useState<StudioClass>();


    return (
        <div className="p-4">
            <DeleteModal url={`/studios/classes/${selectedClass?.id}/`} onSuccess={(resp) => {
                console.log("selected class: " + selectedClass?.id)
                dispatch(removeClass(selectedClass?.id || ""))
            }} show={showDelete} onHide={() => {
                setShowDelete(false)
                setSelectedClass(undefined)
            }}>
                <p className="font-size-h3">¿Seguro que quieres eliminar esta clase:
                    <strong> {selectedClass?.name}</strong> ?
                </p>
            </DeleteModal>
            <CreateEditClassModal show={showCreate} onHide={() => {
                setShowCreate(false)
                setSelectedClass(undefined)
            }
            } studioClass={selectedClass}/>

            <div className="">

                {/* <button className='btn btn-link-primary mb-3'><FontAwesomeIcon icon={faLongArrowAltLeft} size="lg" className='mr-3' />Configuración</button> */}
                <div className=" align-items-center m">
                    <h2 className="pt-2 font-size-h2 font-weight-bolder">Mis Clases</h2>
                    <div className="d-md-flex ">
                        <p className="font-size-lg text-dark-50 d-block">Crea las clases que impartes en tu gimnasio o estudio para asignarlas a tus horarios.</p>
                    </div>
                </div>
                <ToolboxHeader title="" action={() => setShowCreate(true)}/>

                <hr className='mt-10'/>

                    <div className=" col-12 col-md-8 col-lg-6 col-xl-4 ">
                        <Table className="text-left mt-5">
                            <TableHeader headers={["", "", ""]}/>
                            <tbody className="border-top font-size-lg">
                            {classes.length === 0 ? <EmptyTr /> : classes.map((c) => {
                                return <tr className="my-4" key={c.id}>
                                    {/* <td className="text-center" style={{color: c.color}}>
                                        <FontAwesomeIcon icon={faCircle}/>
                                    </td> */}
                                    <td className=""><FontAwesomeIcon icon={faCircle} size="xs"  style={{color: c.color}}/> {c.name}</td>
                        
                                    <td className="text-center px-1" >
                                        <TableActionsCell onClickEdit={() => {
                                            setSelectedClass(c)
                                            setShowCreate(true)
                                        }} onClickDelete={() => {
                                            setSelectedClass(c)
                                            setShowDelete(true)
                                        }}/>
                                    </td>
                                </tr>
                            })}
                            </tbody>
                        </Table>
                    </div>

            </div>
        </div>
    );
};

export default StudioClasses;
