import React from "react";
import {Switch, Route} from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Schedules from "../pages/Schedules";
import Toolbox from "../pages/Toolbox";
import Members from "../pages/Members";
import Reservations from "../pages/Reservations";
import Pos from "../pages/Pos";
import Registration from "../pages/Registration";
import Billing from "../pages/Billing";
import {ProtectedRoute} from "./misc/ProtectedRoute";
import NotFoundScreen from "./misc/NotFoundScreen";
import MemberDetails from "../pages/Members/MemberDetails";
import Reports from "../pages/Reports";
import Coaches from "../pages/Coaches";
import Notifications from "../pages/Notifications";
import WebsiteApp from "../pages/WebsiteApp";
import Success from "../pages/Billing/Checkout/Success";
import BlankPage1 from "../pages/_BlankPages/BlankPage1";
import BlankPage2 from "../pages/_BlankPages/BlankPage2";
import BlankPage3 from "../pages/_BlankPages/BlankPage3";
import BlankPage4 from "../pages/_BlankPages/BlankPage4";
import BlankPage5 from "../pages/_BlankPages/BlankPage5";
import BlankPage6 from "../pages/_BlankPages/BlankPage6";
import BlankPage7 from "../pages/_BlankPages/BlankPage7";
import ClientesActivos from "../pages/_BlankPages/ClientesActivos";

import Reports2 from "../pages/Reports2";



const Routes = () => {
    return <Switch>
        <Route path="/" strict exact component={Schedules} />
        <ProtectedRoute path="/schedules" component={Schedules} permission="schedules" />
        <ProtectedRoute path="/users/:memberId" component={MemberDetails} permission="clients" />
        <ProtectedRoute path="/users" component={Members} permission="clients" />
        <ProtectedRoute path="/reservations" component={Reservations} permission="reservations"/>
        <ProtectedRoute path="/toolbox" component={Toolbox} permission="settings"/>
        <ProtectedRoute path="/billing" component={Billing} permission="billing"/>
        <ProtectedRoute path="/pos" component={Pos} permission="pos"/>
        <ProtectedRoute path="/reports" component={Reports} permission="reports"/>
        <ProtectedRoute path="/reports2" component={Reports2} permission="reports"/>
        <ProtectedRoute path="/coaches" component={Coaches} permission="coaches" />
        <ProtectedRoute path="/notifications" component={Notifications} permission="notifications" />
        <ProtectedRoute path="/blank7"  component={BlankPage7} permission="billing" /> {/*Plans*/}
        <Route path="/registration" exact component={Registration} />
        <Route path="/checkout-success" component={Success} />
        <Route path="/website-app" component={WebsiteApp} />
        <Route path="/blank1" exact component={BlankPage1} />
        <Route path="/blank2" exact component={BlankPage2} />
        <Route path="/blank3" exact component={BlankPage3} />
        <Route path="/blank4" exact component={BlankPage4} />
        <Route path="/blank5" exact component={BlankPage5} />
        <Route path="/blank6" exact component={BlankPage6} />
        <Route path="/clientes-activos" exact component={ClientesActivos} />



        <Route component={NotFoundScreen} />
    </Switch>
}



export default Routes