import React from 'react';
import {MinCoach, TimeSlot} from "./index";
import {format, parse} from "date-fns";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faUserCircle, faTrashAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from 'react-bootstrap';

type ScheduleCardProps = {
    timeSlot: TimeSlot,
    onDetails: () => void
    onEdit: () => void
    onDelete: () => void
}


const ScheduleCard = ({timeSlot, onDetails, onEdit, onDelete}: ScheduleCardProps) => {

    const renderCoachImage = (coaches: MinCoach[]) => {
        const coach = coaches.find(c => c.img !== null)
        if (coaches.length === 0 || coach === undefined) {
            return <FontAwesomeIcon className="text-muted" icon={faUserCircle} style={{fontSize: "45px"}}/>
        }
        return <img alt="coach" src={coach.img}/>
    }
    let start = timeSlot.start
    if (timeSlot.start_time !== null) {
        start =  parse(timeSlot.start_time, "HH:mm:ss", timeSlot.start)
    }
    return (
        <div className="card card-custom overlay mt-2 px-2 py-0 text-left mb-2 border">
            <div className='ml-auto mb-n7'>
            <Dropdown>
                        <Dropdown.Toggle variant="link" bsPrefix="p-0">
                            <button className="btn btn-clean  btn-sm  p-0 m-0 text-muted text-hover-danger">
                                <FontAwesomeIcon icon={faTimes}/>
                            </button>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {/* <Dropdown.Item onClick={onDetails}>Información</Dropdown.Item> */}
                            {/*<Dropdown.Item href="#/action-3">Reservaciones</Dropdown.Item>*/}
                            {/* <Dropdown.Item onClick={onEdit}>Editar Horario</Dropdown.Item> */}
                            <Dropdown.Item onClick={onDelete} className="text-danger"><FontAwesomeIcon className='mr-2 mt-1' icon={faTrashAlt}/>Eliminar Horario</Dropdown.Item>
                        </Dropdown.Menu>
                </Dropdown>
            </div>
            <p className="text-primary font-weight-bolder font-size-lg mb-n1 mt-4 pb-2">  {format(start, "h:mm aa")}</p>
            <p className='font-weight-bold font-size-sm mt-n1'>{timeSlot.studio_class.name}</p>
            <p className="text-muted font-size-sm m-0 p-0 mt-n5">{timeSlot.coaches.map(c => c.full_name).join(", ")}</p>

            {/* <hr className='my-1' /> */}
            <div className="d-flex align-items-start justify-content-between ">
                
                <button
                    className="btn btn-light-primary btn-sm btn-block mb-2 mt-1"
                    data-toggle="tooltip"
                    onClick={onEdit}
                    title=""
                    data-original-title="Editar Horario">
                    {/* <FontAwesomeIcon icon={faEdit} size="lg"/> */}
                    Editar
                </button>


            </div>
        </div>
    );
};

export default ScheduleCard;
