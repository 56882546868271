import React, {useState} from 'react';
import EmptyAlert from "../../components/misc/Empty";
import {Row} from "react-bootstrap";
import MembershipCard from "./MembershipCard";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {StudioPlan, StudioPlansState} from "../../actions/studioPlans";
import CreateEditPlanModal from "../Toolbox/Plans/CreateEditPlanModal";
import {PlanSaleItem} from "./index";

interface Props {
    cart: PlanSaleItem[],
    setCart: (items: PlanSaleItem[]) => void
}

const Memberships = ({cart, setCart}: Props) => {
    const studioPlansState = useSelector<StoreState, StudioPlansState>(state => state.studioPlans)
    const [showCreatePlanModal, setShowCreatePlanModal] = useState(false)
    const [noLimitCredits, setNoLimitCredits] = useState(false)

    return (
        <div>
            <CreateEditPlanModal show={showCreatePlanModal} onHide={() => setShowCreatePlanModal(false)}
                                 noLimitCredits={noLimitCredits}/>
            {studioPlansState.plans.length === 0 ? <EmptyAlert/> :
                <div>
                    <div className="d-flex align-items-center mb-5 mt-2">
{/* 
                        <h4 className="text-dark">Paquetes de Clases</h4>
                        <button onClick={() => {
                            setNoLimitCredits(false)
                            setShowCreatePlanModal(true)
                        }
                        } className="btn btn-white btn-icon btn-circle btn-sm shadow ml-3">
                            <i className="fi-rr-plus-small text-dark-50"/>
                        </button> */}
                    </div>

                    <h6 className="mb-1 font-size-h3">1 - Selecciona el plan</h6>
                    <div className="text-dark-50 font-size-sm mb-3">Crea/edita más planes desde la sección <span><u>"Mis planes"</u></span></div>
                    <Row>
                        {studioPlansState.plans.filter((p) => !p.no_limit_credits && !p.is_subscription).map(p => {
                            return <MembershipCard key={p.id} plan={p} onClick={() => {
                                if (!cart.map(i => i.plan).includes(p)) {
                                    setCart([...cart, {
                                        plan: p,
                                        quantity: 1,
                                        price: parseFloat(p.price),
                                        activate_on: new Date()
                                    }])
                                }
                            }}/>
                        })}
                    </Row>

                    <div className="d-flex align-items-center mb-5">
{/* 
                        <h4 className="text-dark">Membresías</h4>
                        <button onClick={() => {
                            setNoLimitCredits(true)
                            setShowCreatePlanModal(true)
                        }} className="btn btn-white btn-icon btn-circle btn-sm shadow ml-3">
                            <i className="fi-rr-plus-small text-dark-50"/>
                        </button> */}
                    </div>


                    <Row className="h-100">
                        {studioPlansState.plans.filter((p) => p.no_limit_credits  && !p.is_subscription).map(p => {
                            return <MembershipCard key={p.id} plan={p} onClick={() => {
                                if (!cart.map(i => i.plan).includes(p)) {
                                    setCart([...cart, {
                                        plan: p,
                                        quantity: 1,
                                        price: parseFloat(p.price),
                                        activate_on: new Date()
                                    }])
                                }
                            }}/>
                        })}
                    </Row>
                </div>
            }
        </div>
    );
};

export default Memberships;
