import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBirthdayCake, faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom';
import {isBefore, parseISO, isSameMonth, isSameDay} from "date-fns"

import {Member} from "../../../pages/Members";
import TablePill from "../LabelPill";
import {formatDate} from "../../../utils/dateFormatters";
import defaultUser from "../../../assets/img/default-user.jpg"
import {OverlayTrigger, Tooltip} from "react-bootstrap";

type MemberTrProps = {
    member: Member
}

const MemberTr = ({member}: MemberTrProps) => {
    const today = new Date()
    const isBirthdayToday = member.birthday ? parseISO(member.birthday).getDate() === today.getDate() && parseISO(member.birthday).getMonth() === today.getMonth() : false
    const birthdayTooltip = (props: any) => (
        <Tooltip id="birthdayTT" {...props}>
            Hoy es el cumpleaños de {member.full_name}.
        </Tooltip>
    );
    return (

        
        <tr className="font-size-sm">
            
            {/* MOBILE PRUEBA */}
            <td className="">
                <div className="d-flex justify-content-between">
                    <div>
                        {/* <div className="text-muted font-size-sm">{member.external_id}</div> */}
                        
                        <div className="font-size-h6 font-weight-bold text-dark">{member.full_name}</div>
                        <div className="">{member.user.email}</div>
                        <div className="">Créditos disponibles: {member.current_credits}</div>
                        <div className="">Vencimiento Membresía: {formatDate(member.current_membership?.expire_on)}</div>
                        <div className="mb-2">
                            {member.current_membership ?
                            isBefore(new Date(), parseISO(member.current_membership.expire_on)) ?
                                <TablePill text="Vigente"/> : <TablePill text="Vencido" variant="danger"/>
                            : member.current_credits > 0 ? <TablePill text=""/> :
                                <TablePill text="Sin plan" variant=""/>
                            }
                        </div>
                    </div>
                    <div className="align-self-center text-right">
                        <Link to={{
                            pathname: `/users/${member.id}`,
                            state: {member: member}
                        }}>
                            <button className="btn btn-sm btn-outline-info btn-hover-info mb-1" data-toggle="tooltip"
                                title=""
                                data-original-title="Ver detalles de cliente">
                                Detalle Cliente
                            </button>
                        </Link>
                        {/* <button className="btn btn-sm btn-outline-success d-block" data-toggle="tooltip"
                                title="Vender Plan"
                                data-original-title="Ver detalles de cliente">
                                + Agregar Plan
                            </button> */}
                    </div>
                </div>
            </td>
            

        </tr>
        
    );
};

export default MemberTr;
