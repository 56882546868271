import * as yup from "yup"

yup.setLocale({
    mixed: {
        default: 'Invalido',
        required: 'Campo obligatorio',
        cast: "Invalido",
        isValid: "Invalido",
        typeError: "Invalido"
    },
    number: {
        min: 'El numero debe ser mayor/igual a  ${min}',
        max: 'El numero debe ser menor/igual a ${max}',
        positive: 'La duración no puede ser 0',
        integer: 'El numero debe ser entero'
    },
    string: {
        min: 'El texto debe incluir al menos ${min} caracteres.',
        max: 'El texto no puede ser mayor a ${max} caracteres.',
    }
});

export default yup