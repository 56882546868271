import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBirthdayCake, faEllipsisH, faDollarSign, faCashRegister, faUser, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom';
import {isBefore, parseISO, isSameMonth, isSameDay} from "date-fns"

import {Member} from "../../../pages/Members";
import TablePill from "../LabelPill";
import {formatDate} from "../../../utils/dateFormatters";
import defaultUser from "../../../assets/img/default-user.jpg"
import {OverlayTrigger, Tooltip} from "react-bootstrap";

type MemberTrProps = {
    member: Member
}

const MemberTr = ({member}: MemberTrProps) => {
    const today = new Date()
    const isBirthdayToday = member.birthday ? parseISO(member.birthday).getDate() === today.getDate() && parseISO(member.birthday).getMonth() === today.getMonth() : false
    const birthdayTooltip = (props: any) => (
        <Tooltip id="birthdayTT" {...props}>
            Hoy es el cumpleaños de {member.full_name}.
        </Tooltip>
    );
    return (


        <tr className="font-size-sm table-bordered text-dark">
            <td className="align- pl-2 pr-0">
                <div className="font-size-lg">{member.full_name}
                    <OverlayTrigger placement="bottom" overlay={birthdayTooltip} delay={{ show: 250, hide: 250 }}>
                        <i hidden={!isBirthdayToday} className="text-success ml-2">
                            <FontAwesomeIcon icon={faBirthdayCake} />
                        </i>
                    </OverlayTrigger>
                </div>
            </td>
            <td className="align-m">
                <div className="font-size-sm ">{member.user.email}</div>
            </td>
            <td className="">{member.phone}</td>
            <td className="text-center px-0">{member.external_id}</td>
            <td className="align-middle text-center font-size-lg">
                {member.current_credits > 0 ? <TablePill text="Vigente"/>  : member.current_membership ?
                    isBefore(new Date(), parseISO(member.current_membership.expire_on)) ?
                        <TablePill text="Vigente"/> : <TablePill text="Vencido" variant="danger"/>
                    : <TablePill text="N/A" variant="danger"/>
                }
            </td>
            <td className="align-middle text-center font-size-lg">Créditos: <span className='text-primary'>{member.current_credits}</span>
                <div className="font-weight-normal text-dark-50 font-size-md">
                Expiran: {formatDate(member.credits_expiration)}
                </div>
                {/* <div>
                {member.current_membership ?
                    isBefore(new Date(), parseISO(member.current_membership.expire_on)) ?
                        <TablePill text="Creditos vigentes"/> : <TablePill text="Creditos Expirados" variant="danger"/>
                    : member.current_credits > 0 ? <TablePill text=""/> :
                        <TablePill text="Sin Creditos" variant=""/>
                }
                </div> */}
            </td>

            <td className="align-middle text-center font-size-lg">
                <div className="d-block">Membresía</div>
                <div className="font-weight-normal text-dark-50 font-size-md">Vence: {formatDate(member.current_membership?.expire_on)}</div>
                {/* <div>
                {member.current_membership ?
                    isBefore(new Date(), parseISO(member.current_membership.expire_on)) ?
                        <TablePill text="Membresia Vigente" variant="info"/> : <TablePill text="Membresia Vencida" variant="danger"/>

                : member.current_credits > 0 ? <TablePill text="Creditos Vigentex"/> :
                        <TablePill text="" variant="danger"/>
                }
                </div> */}

            </td>

            <td className="align-middle text-center font-size-lg px-1">
                <Link to={{
                    pathname: `/users/${member.id}`,
                    state: {member: member}
                }}>

                    {/* BOTON AGREGAR PLAN */}
                        {/* <button className="btn  btn-sm btn-outline-dark text-primary font-weight-bold  mr-1 btn-hover-success" data-toggle="tooltip"
                                title=""
                                data-original-title="Ver detalles de cliente">
                            <FontAwesomeIcon icon={faCashRegister} size="lg"/>
                            +Agregar Plan
                        </button> */}
                    {/* FIN BOTÓN AGREGAR PLAN */}
                    
                    <button className="btn btn-sm btn-outline-primary btn-hover-primary mr-1 font-weight-bold" data-toggle="tooltip"
                            title=""
                            data-original-title="Ver detalles de cliente">
                            <FontAwesomeIcon icon={faUser} size="lg" className='mr-1'/>
                            Detalles de cliente
                            <FontAwesomeIcon icon={faChevronRight} size="sm" className='ml-1'/>

                    </button>

                </Link>
                {/*<div className="dropdown dropdown-inline">*/}

                {/*    <button type="button" className="btn btn-icon btn-light btn-xs btn-hover-primary"*/}
                {/*            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                {/*        <FontAwesomeIcon icon={faDollarSign} size="xs"/>*/}
                {/*    </button>*/}
                {/*    <div className="dropdown-menu">*/}
                {/*        ...*/}
                {/*    </div>*/}
                {/*</div>*/}
            </td>
        </tr>
    );
};

export default MemberTr;
