import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm} from "formik";
import FormInputGroup from "../../../components/form/FormInputGroup";
import baseApi from "../../../apis/baseApi";
import {addDays, endOfDay} from "date-fns";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {CreditsTransaction} from "./MemberCredits";
import {Member} from "../index";
import yup from '../../../utils/yup';
import FieldError from "../../../components/form/FieldError";

import {useParams, useLocation, useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';


type Props = {
    show: boolean,
    onHide: Function,
    member: Member
    setMember: (member: Member) => void
    transactions: CreditsTransaction[]
    setTransactions: (transactions: CreditsTransaction[]) => void
}



const AddCreditsModal = ({show, onHide, member, setMember, transactions, setTransactions}: Props) => {

    
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const history = useHistory()


    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        original_amount: yup.number().positive().required(),
        days: yup.number().positive().required()
    })

    return (
        <React.Fragment>
            <Modal size="sm" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{original_amount: 0, days: 30}} onSubmit={(values, {setSubmitting}) => {
                    setSuccess(null)
                    baseApi.post("/credits/add/", {
                        original_amount: values.original_amount,
                        current_amount: values.original_amount,
                        member: member.id,
                        expire_on: endOfDay(addDays(new Date(), values.days))
                    }).then((resp) => {
                        setTransactions([{
                            id: resp.data.id,
                            description: "Créditos agregados manualmente",
                            created_on: resp.data.created_on,
                            amount: resp.data.original_amount,
                            transaction_by: "",
                            credits: resp.data.id,
                            expire_on: resp.data.expire_on
                        }, ...transactions])
                        setMember({...member, current_credits: member.current_credits + resp.data.original_amount})
                        setSuccess(true)
                        setAlertMessage("Se han agregado los créditos al usuario correctamente.")
                    }).catch(err => {
                        setAlertMessage(getApiErrorMessage(err))
                        setSuccess(false)
                        setSubmitting(false)
                    })
                }}>
                    {({isSubmitting}) => (
                        <FormikForm>

                            <Modal.Header className='font-weight-bolder text-success px-0'>
                                Ajuste (+)
                            </Modal.Header>
                            <Modal.Body className='px-0'>
                                {renderAlert()}
                                <div className='font-size-lg'>*Los AJUSTES no se guardan como venta ni en historial de compras</div>
                                {/* <div className='font-size-sm text-muted mt-n4 mb-3'>Utiliza el botón "vender un plan" si estas realizando la venta de un paquete.</div> */}
                                <Button variant="" onClick={()  => history.push ("/pos")} className="mb-6 btn-link font-size-lg btn-hover-primary"
                                size="sm" >
                                <u>
                                    Registrar venta / renovación
                                    <FontAwesomeIcon icon={faChevronRight} size="sm" className='ml-1'/>

                                </u>

                        </Button>

                                <div className='ml-n3 font-size-lg font-weight-bolder'>Agregar</div>
                                <FormInputGroup name="original_amount" label="" groupText="+ créditos"
                                                placeholder="0"/>
                                <FieldError name="original_amount"/>

                                <div className='ml-n3 font-size-lg font-weight-bolder'>Vigencia (de estos créditos)</div>
                                <FormInputGroup name="days" label="" groupText="días"
                                                placeholder="30"/>
                                <FieldError name="days"/>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button className='' variant="light-dark font-weight-bolder font-size-lg text-white" onClick={handleClose}>
                                    Atrás
                                </Button>
                                <Button className='btn-success font-weight-bolder font-size-lg' type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "+ Agregar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default AddCreditsModal;
