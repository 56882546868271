import React from 'react';

type TablePillProps = {
    text: string
    variant?: string
}

const TablePill = ({text, variant = "success"} : TablePillProps) => {
    return (
        <span className={`label label-inline label-${variant} font-weight-bolder`}> {text} </span>
    );
};

export default TablePill;
