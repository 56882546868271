import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const BlankPage3 = () => {
    return (
        <div>
            {/* <h1>Pagos en línea, agregalos a tu plan por solo $99 adicionales, 3 primeros meses gratis</h1>
            <h1>Siempre buscamos mejorar para ti, nuevo sistema y diseño.</h1>


            <div className="h2 label label-inline label-light-primary py-5">Cambios</div>
                <div>Bloques horarios - Ahora podras editar o eliminar los horarios individualmente, la herramienta de repeticion aun esta disponible</div>
                <div>Clientes solo podían estar registrados en 1 gimnasio con el mismo correo</div>
                <div>Verificacion de clientes - por default todos los clientes deberan contar con una membresia activa o creditos para poder reservar</div>




            <div className="h2 label label-inline label-light-success py-5">Nuevo</div>
            <div>Pagos en línea</div>
            <div>Lista de espera</div>
            <div>Cancelaciones</div>
            <div>Funcionamiento de creditos y membresias</div>

            <div>Paquetes de creditos</div>
            <div>membresias</div>
            <div>Reservas manuales para clientes desde sistema (ahora resta el credito automaticamente y se refleja en app de cliente)</div>
            <div>Nuevos permisos para usuarios staff</div>
            <div>Eliminar usuarios staff</div>
            <div>Entrenamientos- podras publicar los WOD's del dia en la app de los clientes</div>
            <div>Productos: agrega, vende y lleva un control de inventario y ventas</div>
            <div>Ahora tambien puedes registrar clientes desde sistema</div>





            <div className='h5'>¿Tienes alguna pregunta sobre el nuevo sistema? No dudes en contactarnos </div>
            <a href="">
                <button className='btn btn-success font-weight-bold'>WhatsApp</button>
            </a>

            <div className="mb-5">
                <a href="https://api.whatsapp.com/send/?phone=526621323750&type=phone_number&app_absent=0" target="_blank">
                    <button className='btn btn-light-success btn-pill text-white font-weight-bold font-size-h6' style={{ backgroundColor: "#128C7E" }}>
                        ¿Preguntas?
                        WhatsApp
                        <FontAwesomeIcon className="ml-1 mr-3 font-size-h4 align-self-center" icon={faWhatsapp} />
                    </button>
                </a>
            </div> */}



        </div>
    );
};

export default BlankPage3;
