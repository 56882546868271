import React from 'react';
import {Reservation} from "./index";
import {isBefore} from "date-fns"
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {TimeSlot} from "../Schedules";
import {updateReservation} from "../../actions/timeslotReservations";
import {Button} from "react-bootstrap";
import {formatDateTime} from "../../utils/dateFormatters";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

type CheckInPillProps = {
    rsvp: Reservation
    editDisabled: boolean
}

const CheckInPill = ({rsvp, editDisabled = false}: CheckInPillProps) => {
    const dispatch = useDispatch()
    const selectedTimeSlot = useSelector<StoreState, TimeSlot>(state => state.timeSlotReservations.timeslot)

    if (rsvp.cancelled) {
        return <div className="align-middle mt-2 font-weight-bold font-size-h6 mr-2 text-danger">
            <div>CANCELADA</div>
            <div>{formatDateTime(rsvp.cancelled_on)}</div>
        </div>
    }
    if (rsvp.assistance) {
        return <Button
            disabled={editDisabled}
            onClick={() => {
                dispatch(updateReservation({...rsvp, assistance: false}))
            }}
            className="btn btn-sm btn-success font-weight-bold font-size-md mr-2 min-w-50px align-items-center border-0" style={{backgroundColor:"#28a745"}}>
                {/* <i className="fi fi-rr-check ml-2"></i> */}
                <FontAwesomeIcon icon={faCheck}/>
                <div>Asistencia</div>


                </Button>
    }
    if (isBefore(selectedTimeSlot.start, new Date())) {
        return <div
            onClick={() => {
                if (editDisabled) return
                dispatch(updateReservation({...rsvp, assistance: true}))
            }}
            className="btn btn-sm btn-danger font-weight-bold font-size-md mr-2 min-w-85px">
                <FontAwesomeIcon icon={faTimes}/>
                <div>Falta</div>
        </div>
    }

    return (
        <button
            disabled={editDisabled}
            onClick={() => {
                if (editDisabled) return
                dispatch(updateReservation({...rsvp, assistance: true}))
            }}
            className="btn btn-sm btn-outline-secondary font-weight-bold font-size-md mr-2 min-w-50px text-muted">
            {/* <i className="fi fi-rr-check ml-2 text-muted"></i> */}
            <FontAwesomeIcon icon={faCheck}/>
                <div>Tomar asistencia</div>

        </button>
    );
};

export default CheckInPill;
