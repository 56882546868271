import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import TableHeader from "../../../components/table/TableHeader";
import baseApi from "../../../apis/baseApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCreditCard, faCross, faReceipt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {format} from "date-fns";
import {currencyFormat} from "../../../utils/currencyFormatters";
import {faCcAmex, faCcMastercard, faCcVisa} from "@fortawesome/free-brands-svg-icons";
import {billingPlans} from "../../../data/plans";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Studio} from "../../../actions/studio";

interface Address {
    city?: any;
    country?: any;
    line1?: any;
    line2?: any;
    postal_code?: any;
    state?: any;
}

interface StripeBillingDetails {
    address: Address;
    email?: any;
    name?: any;
    phone?: any;
}


interface Checks {
    address_line1_check?: any;
    address_postal_code_check?: any;
    cvc_check: string;
}

interface Card {
    brand: string;
    checks: Checks;
    country: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    installments?: any;
    last4: string;
    network: string;
    three_d_secure?: any;
    wallet?: any;
}

export interface PaymentMethodDetails {
    card: Card;
    type: string;
}

interface Refunds {
    object: string;
    data: any[];
    has_more: boolean;
    url: string;
}

export interface Charge {
    id: string;
    object: string;
    amount: number;
    amount_captured: number;
    amount_refunded: number;
    application?: any;
    application_fee?: any;
    application_fee_amount?: any;
    balance_transaction: string;
    billing_details: StripeBillingDetails;
    calculated_statement_descriptor?: any;
    captured: boolean;
    created: number;
    currency: string;
    customer?: any;
    description: string;
    disputed: boolean;
    failure_code?: any;
    failure_message?: any;
    invoice?: any;
    livemode: boolean;
    on_behalf_of?: any;
    order?: any;
    outcome?: any;
    paid: boolean;
    payment_intent?: any;
    payment_method: string;
    payment_method_details: PaymentMethodDetails;
    receipt_email?: any;
    receipt_number?: any;
    receipt_url: string;
    refunded: boolean;
    refunds: Refunds;
    review?: any;
    shipping?: any;
    source_transfer?: any;
    statement_descriptor?: any;
    statement_descriptor_suffix?: any;
    status: string;
    transfer_data?: any;
    transfer_group?: any;
}

const BillingDetails = () => {
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const [charges, setCharges] = useState<Charge[]>([]);

    useEffect(() => {
        baseApi.get("/cbb/history/").then(resp => {
            setCharges(resp.data.data)
        })
    }, []);


    return (
        <div>
            {/* NUEVO */}

            {/* TITULO */}
            <h1 className="text-primary font-size-h1 my-5">Mi Suscripción</h1>

            <div className="row justify-content-center mb-10">
                <div className="col-12 col-lg-8 col-xxl-6">
                    <div className="bg-light-primary  p-5 rounded">
                        <div className='text-primary font-size-h4 font-weight-bolder text-center'>Recomienda Safe Gym</div>
                        <div className='text-center text-primary font-size-lg'>Recomienda Safe Gym a un amigo o estudio.</div>
                        <div className='text-center font-size-lg mt-3'>Tu recomendado recibe <strong>$300</strong> de descuento en su primer mes y tu recibes <strong>$300</strong> de descuento en tu siguiente mes.</div>
                    </div>
                </div>
            </div>

            <Row>
                <Col lg={4}>
                    <div className="card card-custom py-10 px-12 h-100 border">
                    {/* <div className="col-1 col-lg-6">
                        <div className='w-50 safegymlogo mx-auto'></div>
                    </div> */}

                        {/* <p className="text-muted text-center font-size-h3 mb-0">Suscripción Actual</p> */}
                        
                        <p className=" font-size-h4">{billingPlans.find(plan => plan.productId === studio.product_id)?.name}</p>
                        {/* <button className="btn btn-link d-block mt-n6"><u>Cambiar Plan</u></button> */}


                        {/* <p>{billingPlans.find(plan => plan.productId === studio.product_id)?.features.map(f => {
                            return <p className="text-muted mb-0">{f.text}</p>
                        })}</p> */}

                        <div className="d-flex align-items-baseline border-bottom ">
                            <div className="text-danger font-size-h6 font-weight-bold">
                                <FontAwesomeIcon icon={faTimes} size="lg" className='mr-2'/>
                                Pago Fallido
                            </div>
                            <button style={{color: "#3699ff"}} className="font-size- font-weight-bold btn-link btn">Reintentar Pago</button>
                        </div>

                        <div className="text-success font-size-h6 font-weight-bold align-items-baseline border-bottom py-3">
                            <FontAwesomeIcon icon={faCheck} size="lg" className='mr-2'/>
                                Activo - Cuenta al corriente
                        </div>

                        <div className="font-size-h6  align-items-baseline border-bottom py-3 d-flex justify-content-between">
                                <div className="">Período actual:</div>
                                <div className="text-muted">3 de marzo - 3 de abril</div>
                        </div>

                        <div className="font-size-h6  align-items-baseline border-bottom py-3 d-flex justify-content-between">
                                <div className="">Próximo pago:</div>
                                <div className="text-muted">$599 - 3 de abril</div>
                        </div>

                        <div className="font-size-h6  align-items-baseline py-3 d-flex justify-content-between">
                                <div className="align-self-">Plan Actual:</div>
                                <div className="text-muted">80 clientes
                                    <button className="btn btn-link d-block mt-n4"><u>Cambiar</u></button>
                                </div>
                        </div>

                        <hr />
                      

                        <div className="text-center mt-3 font-size-lg text-muted">Envíanos un <u>WhatsApp</u> para resolver cualquier duda</div>
                        

                        

                    </div>
                </Col>
                <Col lg={6} className="card card-custom shadow-none bg-transparent py-10 px-5">
                    <p className="text-muted font-size-h3 mb-0">Último Pago</p>
                    <hr/>
                    <table>
                        <TableHeader headers={["FECHA", "MONTO", "MÉTODO", "STATUS", "PERIODO", "RECIBO"]}/>
                        <tbody className="text-center">
                        {charges.map(c => {
                            let cardIcon = c.payment_method_details.card.brand === "visa" ? faCcVisa : c.payment_method_details.card.brand === "mastercard" ? faCcMastercard : c.payment_method_details.card.brand === "amex" ? faCcAmex : faCreditCard
                            return <tr key={c.id}>
                                <td>{format(new Date(c.created * 1000), "dd-MMM-yyyy")}</td>
                                <td>{currencyFormat.format(c.amount / 100)} {c.currency.toUpperCase()}</td>
                                <td><FontAwesomeIcon className="text-muted"
                                                     icon={cardIcon}/> &bull;&bull;&bull;{c.payment_method_details.card.last4}
                                </td>
                                <td className={c.status === "succeeded" ? "text-success" : "text-danger"}>{c.status === "succeeded" ?
                                    <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faTimes}/>}</td>
                                <td>{format(new Date(c.created * 1000), "dd-MMM-yyyy")}</td>

                                <td>
                                    <a href={c.receipt_url} target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faReceipt}/>
                                    </a>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>

                    <div className="py-15"></div>
                    <p className="text-muted font-size-h3 mb-0">Métodos de Pago
                        <button style={{color: "#3699ff"}} className="font-size- font-weight-bold btn-link btn">+ Agregar nuevo</button>

                    </p>
                    <hr/>
                    <table>
                        <TableHeader headers={[""]}/>
                        <tbody className="text-left">
                        {charges.map(c => {
                            let cardIcon = c.payment_method_details.card.brand === "visa" ? faCcVisa : c.payment_method_details.card.brand === "mastercard" ? faCcMastercard : c.payment_method_details.card.brand === "amex" ? faCcAmex : faCreditCard
                            return <tr key={c.id}>
                                
                                <td><FontAwesomeIcon className="text-muted"
                                                     icon={cardIcon}/> &bull;&bull;&bull;{c.payment_method_details.card.last4}
                                </td>
                                
                            </tr>
                        })}
                        </tbody>
                    </table>
                </Col>
            </Row>
            












            {/* F */}
            {/* <div className="mt-5 mb-8">
                <div className="d-flex align-items-center">
                    <i className="fi-rr-credit-card mr-3 text-dark"/>
                    <h2 className="text-dark">Tu suscripción</h2>
                </div>
            </div>
            <Row>
                <Col lg={4}>
                    <div className="card card-custom py-10 px-12 h-100">
                        <p className="text-muted text-center font-size-h3 mb-0">Suscripción Actual</p>
                        
                        <hr/>
                        <p className="text-center font-size-h4">{billingPlans.find(plan => plan.productId === studio.product_id)?.name}</p>
                        <button className="btn btn-link d-block mt-n6"><u>Cambiar Plan</u></button>


                        <p>{billingPlans.find(plan => plan.productId === studio.product_id)?.features.map(f => {
                            return <p className="text-muted mb-0">{f.text}</p>
                        })}</p>

                        <div className="d-flex align-items-baseline border-bottom justify-content-between">
                            <div className="text-danger font-size-h6 font-weight-bold">
                                <i className="fi-rr-shield-exclamation mr-3 text-danger"/>
                                Pago Fallido
                            </div>
                            <button style={{color: "#3699ff"}} className="font-size- font-weight-bold ml-4 btn-link btn"> Actualizar método de pago</button>
                        </div>

                        <div className="text-success font-size-h6 font-weight-bold align-items-baseline border-bottom py-3">
                                <i className="fi-rr-check mr-1 text-success"/>
                                Activo
                        </div>

                        <div className="font-size-h6  align-items-baseline border-bottom py-3 d-flex justify-content-between">
                                <div className="">Período actual:</div>
                                <div className="text-muted">3 de marzo - 3 de abril</div>
                        </div>

                        <div className="font-size-h6  align-items-baseline border-bottom py-3 d-flex justify-content-between">
                                <div className="">Próximo pago:</div>
                                <div className="text-muted">$599 - 3 de abril</div>
                        </div>

                        <div className="font-size-h6  align-items-baseline py-3 d-flex justify-content-between">
                                <div className="align-self-">Plan Actual:</div>
                                <div className="text-muted">80 clientes
                                    <button className="btn btn-link d-block mt-n4"><u>Cambiar</u></button>
                                </div>
                        </div>

                        <hr />
                        <div className="font-size-h4">Add-Ons</div>

                        <div className="font-size-h6  align-items-end py-3 d-flex justify-content-between">
                                <div className="align-self-center d-flex">
                                    <i className="fi-rr-credit-card mr-2 ml-3 align-self-end"/>
                                    <div>Pagos en línea:</div>
                                    <i className="fi-rr-interrogation mr-2 ml-2 align-self-end text-primary"/>
                                </div>
                                <i className="fi-rr-check mr-1 text-success ml-3"/>
                        </div>

                        <div className="font-size-h6  align-items-end py-3 d-flex justify-content-between">
                                <div className="align-self-center d-flex">
                                    <i className="fi-rr-computer mr-2 ml-3 align-self-end"/>
                                    <div>Página web:</div>
                                    <i className="fi-rr-interrogation mr-2 ml-2 align-self-end text-primary"/>
                                </div>
                                <div className="text-muted">$100
                                    <a className="font-weight- ml-2 font-size-h3" style={{color: "#3699ff"}}>+</a>
                                </div>
                        </div>

                        <div className="font-size-h6  align-items-end py-3 d-flex justify-content-between">
                                <div className="align-self-center d-flex">
                                    <i className="fi-rr-play mr-2 ml-3 align-self-end"/>
                                    <div>Plataforma clases en línea:</div>
                                    <i className="fi-rr-interrogation mr-2 ml-2 align-self-end text-primary"/>
                                </div>
                                <div className="text-muted">$250
                                    <a className="font-weight- ml-2 font-size-h3" style={{color: "#3699ff"}}>+</a>
                                </div>
                        </div>

                 

                        <div className="font-size-h6  align-items-end py-3 d-flex justify-content-between">
                                <div className="align-self-center d-flex">
                                    <i className="fi-rr-smartphone mr-2 ml-3 align-self-end"/>
                                    <div>App Personalizada:</div>
                                    <i className="fi-rr-interrogation mr-2 ml-2 align-self-end text-primary"/>
                                </div>
                                <div className="text-muted">Contactar
                                    <a className="font-weight- ml-2 font-size-h3" style={{color: "#3699ff"}}>+</a>
                                </div>
                        </div>

                        <div className="text-center mt-3 font-size-lg text-muted">Envíanos un <u>WhatsApp</u> para resolver cualquier duda</div>
                        

                        

                    </div>
                </Col>
                <Col lg={6} className="card card-custom shadow-none bg-transparent py-10 px-5">
                    <p className="text-muted font-size-h3 mb-0">Último Pago</p>
                    <hr/>
                    <table>
                        <TableHeader headers={["FECHA", "MONTO", "MÉTODO", "STATUS", "PERIODO", "RECIBO"]}/>
                        <tbody className="text-center">
                        {charges.map(c => {
                            let cardIcon = c.payment_method_details.card.brand === "visa" ? faCcVisa : c.payment_method_details.card.brand === "mastercard" ? faCcMastercard : c.payment_method_details.card.brand === "amex" ? faCcAmex : faCreditCard
                            return <tr key={c.id}>
                                <td>{format(new Date(c.created * 1000), "dd-MMM-yyyy")}</td>
                                <td>{currencyFormat.format(c.amount / 100)} {c.currency.toUpperCase()}</td>
                                <td><FontAwesomeIcon className="text-muted"
                                                     icon={cardIcon}/> &bull;&bull;&bull;{c.payment_method_details.card.last4}
                                </td>
                                <td className={c.status === "succeeded" ? "text-success" : "text-danger"}>{c.status === "succeeded" ?
                                    <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faTimes}/>}</td>
                                <td>{format(new Date(c.created * 1000), "dd-MMM-yyyy")}</td>

                                <td>
                                    <a href={c.receipt_url} target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faReceipt}/>
                                    </a>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>

                    <div className="py-15"></div>
                    <p className="text-muted font-size-h3 mb-0">Métodos de Pago
                        <button style={{color: "#3699ff"}} className="font-size- font-weight-bold btn-link btn">+ Agregar nuevo</button>

                    </p>
                    <hr/>
                    <table>
                        <TableHeader headers={[""]}/>
                        <tbody className="text-left">
                        {charges.map(c => {
                            let cardIcon = c.payment_method_details.card.brand === "visa" ? faCcVisa : c.payment_method_details.card.brand === "mastercard" ? faCcMastercard : c.payment_method_details.card.brand === "amex" ? faCcAmex : faCreditCard
                            return <tr key={c.id}>
                                
                                <td><FontAwesomeIcon className="text-muted"
                                                     icon={cardIcon}/> &bull;&bull;&bull;{c.payment_method_details.card.last4}
                                </td>
                                
                            </tr>
                        })}
                        </tbody>
                    </table>
                </Col>
            </Row> */}
        </div>
    );
};

export default BillingDetails;
