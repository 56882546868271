import React, {useEffect, useState} from 'react';
import {Button, Table} from "react-bootstrap";
import CreateStaffModal from "./CreateStaffModal";
import TableHeader from "../../../components/table/TableHeader";
import baseApi from "../../../apis/baseApi";
import {StaffUser} from "../../../actions/staffUser";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import ToolboxHeader from "../ToolboxHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashAlt, faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import DeleteModal from "../../../components/modals/DeleteModal";

export interface UserWithStaff {
    full_name: string
    id: string
    username: string
    staff: StaffUser
}

const Staff = () => {
    const branches = useSelector<StoreState, Branch[]>(state => state.branches.branches)
    const [showCreate, setShowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [staff, setStaff] = useState<UserWithStaff[]>([]);
    const [selectedStaff, setSelectedStaff] = useState<UserWithStaff>()

    useEffect(() => {
        baseApi.get("/staff/").then((resp) => {
            setStaff(resp.data)
        })
    }, []);

    const renderBranchNames = (branchIds: string[]) => {
        const names = branchIds.map((id, index) => branches.find((branch) => branch.id === id)?.name)
        return names.join(", ")
    }

    return (
        <div className="p-4">
            <CreateStaffModal
                show={showCreate}
                onHide={() => {
                    setShowCreate(false)
                    setSelectedStaff(undefined)
                }}
                user={selectedStaff}
                staffUsers={staff}
                setStaffUsers={setStaff}
            />
            <DeleteModal url={`/staff/update/${selectedStaff?.id}/`} onSuccess={() => {
                setStaff(staff.filter(s => s.id !== selectedStaff?.id))
            }} show={showDelete} onHide={() => {
                setShowDelete(false)
                setSelectedStaff(undefined)
            }}>
                <p className="font-weight-bolder font-size-h3">¿Seguro que quieres <span className="text-danger">eliminar</span> esta cuenta de staff?</p>
                <p className="m-0 p-0 font-size-h3">{selectedStaff?.full_name}</p>
                <p className="m-0 p-0 font-size-h3">{selectedStaff?.username}</p>
            </DeleteModal>
            {/* <button className='btn btn-link-primary mb-3'><FontAwesomeIcon icon={faLongArrowAltLeft} size="lg" className='mr-3' />Configuración</button> */}
                <div className=" align-items-center m">
                    <h2 className="pt-2 font-size-h2 font-weight-bolder">Cuentas de Staff</h2>
                    <div className="">
                        <p className="font-size-lg text-dark-50 d-block">Crea los usuarios de tu staff e instructores que tendrán acceso a manejar la app staff y software.</p>
                        <p className="font-size-lg text-dark-50 d-block mt-n4">Define los permisos y restricciones de cada usuario.</p>
                    </div>
                </div>
                <ToolboxHeader title="Usuarios para Staff / Instructores" action={() => setShowCreate(true)}/>
                <div className="p-3"></div>

                <hr  className='mb-10'/>
            <Table bordered>
                {/* <TableHeader headers={["", "", ""]}/> */}
                <tbody>
                {staff.length === 0 ? <EmptyTr/> : staff.map((staffUser) => {
                    return <tr key={staffUser.id} className="font-size-lg">
                        <td className="font-weight-bolder">{staffUser.full_name}</td>
                        <td>{staffUser.username}</td>
                        {/* <td>{renderBranchNames(staffUser.staff.is_staff_of)}</td> */}
                        <td className="text-right px-1">
                            <Button onClick={() => {
                                setSelectedStaff(staffUser)
                                setShowCreate(true)
                            }
                            } className="btn btn-light-primary bg-light-primary btn-sm mr-3"><span className='mr-2 d-none d-md-inline'>Editar permisos</span>
                                <FontAwesomeIcon icon={faEdit} size="sm"/>
                            </Button>
                            <Button onClick={() => {
                                setSelectedStaff(staffUser)
                                setShowDelete(true)
                            }
                            } className="btn btn-icon btn-light-danger bg-light-danger btn-hover-danger bg-white btn-xs ">
                                <FontAwesomeIcon icon={faTrashAlt} size="sm"/>
                            </Button>

                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
            {/* <div className='font-size-h6 text-primary'>¿Como ingresan los usuarios staff e instructores?</div>
            <div>
                <div>Software: www.safegymadmin.com</div>
                <div>APP STAFF: disponible para iOS y Android como "Safe Gym STAFF"</div>
            </div> */}
        </div>
    );
};

export default Staff;
