import React, {useEffect, useRef, useState} from 'react';
import {Table, Button, Alert} from "react-bootstrap";

import AddMemberPlanModal from './AddMemberPlanModal';
import MemberPlanTr from "../../../components/table/rows/MemberPlanTr";
import {StudioPlan} from "../../../actions/studioPlans";
import baseApi from "../../../apis/baseApi";
import TableFooter from "../../../components/table/TableFooter";
import TableHeader from "../../../components/table/TableHeader";
import DeleteModal from "../../../components/modals/DeleteModal";
import {formatDate} from "../../../utils/dateFormatters";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import EditPlanExpirationModal from "./EditPlanExpirationModal";
import {Member} from "../index";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";

// agregado k
import MemberPlanTr2 from "../../../components/table/rows/MemberPlanTr2";
import MemberPlanTr3 from "../../../components/table/rows/MemberPlanTr3";

import AddMemberCreditsModal from "./AddMemberCreditsModal";
import RemoveCreditsModal from "./RemoveMemberCreditsModal";

import {faBell, faCoins, faStar, faEdit, faCashRegister} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// agregado k


// agregado k
export interface CreditsTransaction {
    id: string
    amount: number
    created_on: string
    credits: string
    description: string
    transaction_by: string
    expire_on: string
}
//  agregadok


export interface MemberPlan {
    id: string
    expire_on: string
    created_on: string
    cancelled: boolean
    plan: StudioPlan
    credits_available: number | null
    activate_on: string
}

type MemberPlanProps = {
    member: Member
    setMember: (member: Member) => void
}

const MemberPlans = ({member, setMember}: MemberPlanProps) => {
    const studioPlans = useSelector<StoreState, StudioPlan[]>(state => state.studioPlans.plans)
    const [memberPlans, setMemberPlans] = useState<MemberPlan[]>([])
    const [showAdd, setShowAdd] = useState(false)
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [showEditExpirationModal, setShowEditExpirationModal] = useState(false);
    const [selectedMembership, setSelectedMembership] = useState<MemberPlan>()

// agregado k
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [transactions, setTransactions] = useState<CreditsTransaction[]>([]);
// agregado k

    const count = useRef(0)


    const [submittingEventInvite, setSubmittingEventInvite] = useState(false);
    const [inviteMsg, setInviteMsg] = useState<string>();
    const [inviteSuccess, setInviteSuccess] = useState<boolean>();

    useEffect(() => {
        baseApi.get(`/memberships/history/?member=${member.id}`).then((resp) => {
            count.current = resp.data.count
            setMemberPlans(resp.data.results)
        }).catch((err) => alert("ERROR"))
    }, [member])

    const handlePageChange = (page: number) => {
        baseApi.get(`/memberships/history/?member=${member.id}&page=${page}`).then((resp) => {
            setMemberPlans(resp.data.results)
        }).catch((err) => alert("ERROR"))
    }

    const showAlert = () => {
        if (inviteMsg === undefined) return <React.Fragment />
        return <Alert variant={inviteSuccess ? "success" : "danger"}>{inviteMsg}</Alert>
    }

    const renderInviteButton  = () => {
        if (studioPlans.some(e => e.id === "7645bea5-09cb-46f2-9b34-ab17b31ec22b")) {
            return <div className="text-right">
                <Button disabled={submittingEventInvite} onClick={() => {
                    setSubmittingEventInvite(true)
                    baseApi.patch("/plans/invite/7645bea5-09cb-46f2-9b34-ab17b31ec22b/", {
                        "member_id": member.id,
                    }).then(resp => {
                            setInviteSuccess(true)
                            setInviteMsg("Usuario invitado a evento especial")
                        }
                    ).catch((err) => {
                        setInviteSuccess(false)
                        setInviteMsg(getApiErrorMessage(err))
                    })
                }} size="sm" variant="link">Invitar a evento especial</Button>
            </div>
        }
        return <React.Fragment />
    }


    return (
        <div className="p-4">
            <AddMemberPlanModal show={showAdd} onHide={() => setShowAdd(false)} memberId={member.id}/>
            <EditPlanExpirationModal
                membership={selectedMembership}
                show={showEditExpirationModal}
                memberPlans={memberPlans}
                setMemberPlans={setMemberPlans}
                onHide={() => {
                    setShowEditExpirationModal(false)
                    setSelectedMembership(undefined)
                }}
                member={member}
                setMember={setMember}/>


            {/*<div className="float-right">*/}
               {/* <Button onClick={() => setShowAdd(true)} size="sm">+ Agregar Plan</Button> */}
            {/*</div>*/}


            <DeleteModal
                url={`/memberships/update/${selectedMembership?.id}/`}
                onSuccess={() => {
                    if (selectedMembership?.credits_available && selectedMembership!.credits_available > 0) {
                        setMember({
                            ...member,
                            current_credits: member.current_credits - selectedMembership!.credits_available
                        })
                    }
                    setMemberPlans(memberPlans.map(m => m.id === selectedMembership?.id ? {
                        ...m,
                        cancelled: true
                    } : m))
                }}
                text={"Cancelar Plan"}
                successText={"Plan cancelado"}
                show={showCancelModal}
                onHide={() => {
                    setShowCancelModal(false)
                    setSelectedMembership(undefined)
                }}
            >
                <p className="h3 text-danger">¿Cancelar esta compra?</p>
                <p className="h6 mt-5 text-muted">Plan: {selectedMembership?.plan.name}</p>
                <p className="h6 text-muted">Vencimiento: {formatDate(selectedMembership?.expire_on)}</p>
                <p className="text-danger font-size-lg font-weight-bold mt-10">Reservaciones realizadas con este plan serán canceladas</p>

            </DeleteModal>

            {/* DESKTOP TABLE */}

            {/* TEXTO CREDITOS VIGENTES Y MEMBRES */}
            {/* <p className='font-size-h5'>
                <FontAwesomeIcon icon={faStar} size="sm" className='mr-1' />
                Total créditos vigentes:<span className='text-success ml-2 font-weight-bold'>{member.current_credits}</span>
            </p> */}
            {/* FIN TEXTO */}

            {/* botones agregar restar credios k */}
            {/* <AddMemberCreditsModal show={showCreateModal} onHide={() => setShowCreateModal(false)} member={member}
                                   setMember={setMember}
                                   transactions={transactions} setTransactions={setTransactions}/>
            <RemoveCreditsModal show={showRemoveModal} onHide={() => setShowRemoveModal(false)} setMember={setMember}
                                member={member}
                                transactions={transactions} setTransactions={setTransactions}/>
            <div className="text-left">
                <Button onClick={() => setShowCreateModal(true)} className="my-2 btn-success font-size-h6 font-weight-bolder " size="sm">
                    + Agregar créditos
                </Button>
                <Button variant="" onClick={() => setShowRemoveModal(true)} className="my-2 ml-2 btn-danger font-size-h6 font-weight-bolder"
                        size="sm">
                    - Restar créditos
                </Button>
                <Button variant="" onClick={() => setShowRemoveModal(true)} className="my-2 ml-2 btn-primary font-size-h6 font-weight-bolder"
                        size="sm">
                         <FontAwesomeIcon icon={faEdit} size="sm" className='mr-1'/>
                        Editar fecha membresía
                </Button>
                <Button variant="" onClick={() => setShowRemoveModal(true)} className="my-2 ml-2 btn-info font-size-h6 font-weight-bolder"
                        size="sm" style={{backgroundColor: "#17a2b8"}}>
                         <FontAwesomeIcon icon={faCashRegister} size="sm" className='mr-1'/>
                        Vender un plan
                </Button>
            </div> */}
            {/* botones agregar restar creditos k  */}

            {/* DESKTOP */}

            <div className='text-primary font-size-h6 font-weight-bold mt-4'>Historial de compras</div>
            <Table hover className="w-100">
                <TableHeader headers={[
                    "Fecha compra",
                    "Plan",
                    "Créditos",
                    "Vencimiento",
                    ""
                ]}/>



                <tbody>
                {memberPlans.length === 0 ? <EmptyTr/> : memberPlans.map((memberPlan) => {
                        return <MemberPlanTr
                            key={memberPlan.id}
                            memberPlan={memberPlan}
                            onCancel={() => {
                                setSelectedMembership(memberPlan)
                                setShowCancelModal(true)
                            }}
                            onEditExpiration={() => {
                                setSelectedMembership(memberPlan)
                                setShowEditExpirationModal(true)
                            }}


                        />
                    }
                )}
                </tbody>
            </Table>
            {/* PAGE NUMBERS */}
            <TableFooter count={count.current} pageCount={memberPlans.length} pageSize={10} onPageChange={handlePageChange}/>



            {/* MOBILE PRUEBA */}
            {/* <div className="d-lg-none bg-success">
                <Table hover>
                    <TableHeader
                        headers={[]} />

                    <tbody>
                        {memberPlans.length === 0 ? <EmptyTr /> : memberPlans.map((memberPlan) => {
                            return <MemberPlanTr2
                                key={memberPlan.id}
                                memberPlan={memberPlan}
                                onCancel={() => {
                                    setSelectedMembership(memberPlan)
                                    setShowCancelModal(true)
                                }}
                                onEditExpiration={() => {
                                    setSelectedMembership(memberPlan)
                                    setShowEditExpirationModal(true)
                                }}


                            />
                        }
                        )}
                    </tbody>

                </Table>
            </div> */}




            {/* <div className="">
            <Table hover className="">
                <TableHeader headers={[
                    "Fecha compra",
                    "Concepto",
                    "Créditos",
                    "Vencimiento",
                    ""
                ]}/>



                <tbody>
                {memberPlans.length === 0 ? <EmptyTr/> : memberPlans.map((memberPlan) => {
                        return <MemberPlanTr
                            key={memberPlan.id}
                            memberPlan={memberPlan}
                            onCancel={() => {
                                setSelectedMembership(memberPlan)
                                setShowCancelModal(true)
                            }}
                            onEditExpiration={() => {
                                setSelectedMembership(memberPlan)
                                setShowEditExpirationModal(true)
                            }}


                        />
                    }
                )}
                </tbody>
            </Table>
            <TableFooter count={count.current} pageCount={memberPlans.length} pageSize={10}
                         onPageChange={handlePageChange}/>
            </div> */}








        </div>
    );
};

export default MemberPlans;
