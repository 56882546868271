import React, {useState} from 'react';
import {Alert, Button, Col, Form} from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux';
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Formik, Field, Form as FormikForm} from "formik";
import {useHistory} from "react-router-dom";

import {StoreState} from "../../../reducers";
import {CheckoutState} from '../../../actions/checkout';
import baseApi from "../../../apis/baseApi";
import yup from "../../../utils/yup";
import FieldError from "../../../components/form/FieldError";
import {currencyFormat} from "../../../utils/currencyFormatters";
import {setStudio, Studio} from "../../../actions/studio";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock} from "@fortawesome/free-solid-svg-icons";


const ConfirmPayment = () => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch()
    const history = useHistory()

    const [errorMsg, setErrorMsg] = useState<string>();

    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const checkoutState = useSelector<StoreState, CheckoutState>(state => state.checkout)

    const renderError = () => {
        if (!errorMsg) return <React.Fragment/>
        return <Alert variant="danger">{errorMsg}</Alert>
    }

    const BillingSchema = yup.object().shape({
        billing_details: yup.object().shape({
            name: yup.string().required(),
            address: yup.object().shape({
                line1: yup.string().required(),
                city: yup.string().required(),
                state: yup.string().required(),
                postal_code: yup.string().required(),
            })
        })
    })

    return (
        <div>
            <Formik
                validationSchema={BillingSchema}
                initialValues={{
                    billing_details: {
                        name: "",
                        address: {
                            line1: "",
                            line2: "",
                            city: "",
                            state: "",
                            postal_code: "",
                            country: "MX",
                        }
                    },
                    titleError: ""
                }} onSubmit={(values, {setSubmitting}) => {
                setErrorMsg(undefined)
                if (!stripe || !elements) {
                    setErrorMsg("Error, la pagina no esta lista.")
                    return
                }
                const cardElement = elements.getElement(CardElement);
                stripe.createPaymentMethod({
                    type: "card",
                    // @ts-ignore
                    card: cardElement,
                    billing_details: values.billing_details,
                }).then((result) => {
                    if (result.error) {
                        setErrorMsg(result.error.message);
                        setSubmitting(false)
                    } else {
                        if (checkoutState.selectedPlan) {
                            baseApi.post("/cbb/subscribe/", {
                                payment_method_id: result.paymentMethod.id,
                                price_id: checkoutState.selectedPlan.priceId
                            }).then(() => {
                                dispatch(setStudio({
                                    ...studio,
                                    active: true,
                                    product_id: checkoutState.selectedPlan?.productId || null
                                }))
                                history.push("/checkout-success")
                            }).catch(e => {
                                console.log(e)
                                setErrorMsg(e.response.data.error.message)
                                setSubmitting(false)
                            })
                        }
                    }
                }).catch(result => {
                    setSubmitting(false)
                    setErrorMsg(result.error.message)
                })
            }}>
                {({isSubmitting}) => (
                    <FormikForm>
                        <div className='text-danger'>boton atras</div>
                        {/* <h3 className="text-center mt-5 mb-3">Confirmación</h3> */}

                        <div className="row justify-content-center">

                            <div className="col-12 col-sm-9 col-md-8 col-lg-10 col-xxl-9 card card-custom py-20 px-10 mt-5 mt-md-15 mr-md-5 shadow">
                                {/* {renderError()} */}

                                {/* RESUMEN PLAN */}
                                <div className="row">
                                    <div className='col-12 col-xl-6 col-xxl-6'>
                                        <h5 className=" text-muted">Suscríbete a {checkoutState.selectedPlan?.name}</h5>
                                        {/*@ts-ignore*/}
                                        <p className="mb-0 pb-0 font-size-h2">MXN {currencyFormat.format(checkoutState.selectedPlan?.price - (checkoutState.selectedPlan?.price * (checkoutState.selectedPlan?.discount_percent || 0)))} 
                                        /mes </p>
                                        <hr/>
                                    </div>
                                    <div className='col-12 col-xl-6 px-10'>
                                        <div className="py-2 pb-4 h3">Agrega tu método de pago</div>
                                        <div className='font-size-lg font-weight-bold'>{renderError()}</div>
                                        <div className='mb-n2 text-dark-50'>Información de la tarjeta</div>
                                        <div className="px-3 py-5 my-2 rounded border">
                                            <CardElement/>
                                        </div>
                                        <div className='mt-3 text-dark-50'>Nombre en la tarjeta</div>
                                        <Field className="form-control py-7" name="billing_details.name"
                                               placeholder="Títular"/>
                                        <FieldError name="billing_details.name"/>
                                        <div className=' mt-5 text-dark-50 mb-n1'>Dirección</div>
                                        <Field className="form-control mt-1 py-7" name="billing_details.address.line1"
                                               placeholder="Dirección"/>
                                        <FieldError name="billing_details.address.line1"/>
                                        <Form.Row>
                                            <Col md={4}>
                                                <Field className="form-control mt-1 py-7" name="billing_details.address.city"
                                                       placeholder="Ciudad"/>
                                                <FieldError name="billing_details.address.city"/>
                                            </Col>
                                            <Col md={4}>
                                                <Field className="form-control mt-1 py-7" name="billing_details.address.state"
                                                       placeholder="Estado"/>
                                                <FieldError name="billing_details.address.state"/>
                                            </Col>
                                            <Col md={4}>
                                                <Field className="form-control mt-1 py-7" name="billing_details.address.postal_code"
                                                       placeholder="Código Postal"/>
                                                <FieldError name="billing_details.address.postal_code"/>
                                            </Col>
                                        </Form.Row>
                                        <div className='d-flex align-items-center mt-2'>
                                            <FontAwesomeIcon icon={faLock} size="sm" className='mr-1 text-success'/>
                                            <div>SSL Encrypted</div>
                                        </div>
                                    
                                        <Button className='font-weight-bold font-size-h5 btn-primary  btn-block mt-5' type="submit" disabled={!stripe || isSubmitting}>
                                            Suscribirse
                                        </Button>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
};

export default ConfirmPayment;
