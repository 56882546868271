import React from "react";
import {Formik, Form as FormikForm, Field} from "formik";
import {Button, Col, Form, } from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {AuthState, login} from "../actions";
import {StoreState} from "../reducers";


const Login = () => {
    const dispatch = useDispatch()
    const authState = useSelector<StoreState, AuthState>(state => state.auth)

    return <div className="h-100 w-100 safegymlogin">
        <div className="safegymlogin row align-items-center">
            <div className="safegymlogin col-12">
                <div className="row justify-content-center">

                    <div className="col-12 col-md-6 col-xl-5 py-25 px-lg-0 w-100 ml-9">
                        <div className="card card-custom col-12 mx-auto p-10 py-15 shadow ">
                            {/* <h3 className=" mb-15 text-center h1">Inicia Sesión</h3> */}

                            {/* SAFE GYM LOGO LOGIN */}
                            <div className="col-8 col-lg-5 col-xxl-4 safegymlogo mx-auto mb-5 mt-n5"></div>

                            <h5 className="text-center mb-10">Administra tu gimnasio, crossfit o estudio.</h5>
                            {/* <div className="font-size-h6 text-center font-weight-bolder mb-2">{authState.error}</div> */}

                            <Formik
                                initialValues={{username: "", password: ""}}
                                onSubmit={(values) => {
                                    dispatch(login(values.username, values.password))
                                }}>
                                {() => (
                                    <FormikForm className="text-center">
                                        <div className="d-flex justify-content-center align-items-center text-left mb-n5">
                                            <Form.Group className="w-100 w-md-75 w-lg-75 w-xxl-50">
                                                <label className=" font-weight-bolder font-size-h6 mb-n1">E-mail</label>
                                                <Field name="username" className="form-control"/>
                                                <div className="font-size-xs text-left text-danger mb-2">{authState.error}</div>

                                            </Form.Group>

                                        </div>

                                        <div className="d-flex justify-content-center align-items-center text-left">
                                            <Form.Group className="w-100 w-md-75 w-lg-75 w-xxl-50">
                                                <label className=" font-weight-bolder font-size-h6 mb-n1">Contraseña</label>
                                                <Field type="password" name="password" className="form-control"/>
                                                <div className="font-size-xs text-left text-danger mb-2">{authState.error}</div>

                                            </Form.Group>
                                        </div>
                                        <div>
                                            <Button disabled={authState.loading} className="btn btn-primary btn-lg font-size-h5 font-weight-bolder"
                                                    type="submit">
                                                Iniciar Sesión
                                            </Button>
                                        </div>
                                        <br/>
                                    </FormikForm>
                                )}
                            </Formik>

                        </div>


                    </div>
                </div>
            </div>

        </div>
    </div>;
};

export default Login;
