import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const BlankPage4 = () => {
    return (
        <div>
            <div className="d-flex justify-content-between mb-5">
                <div className='text-info h2 mb-5 d-flex align-items-center'><div>Selecciona un plan para activar tu cuenta</div><div className="label label-light-info label-inline ml-2 font-weight-bold">PROMO POR TIEMPO LIMITADO: 15% de descuento</div></div>
                <div>
                <a href="https://api.whatsapp.com/send/?phone=526621323750&type=phone_number&app_absent=0" target="_blank">
                                <button className='btn btn-light-success btn-pill text-white font-weight-bold' style={{backgroundColor: "#128C7E"}}>
                                ¿Preguntas?
                                        WhatsApp
                                        <FontAwesomeIcon className="ml-1 mr-3 font-size-h4 align-self-center" icon={faWhatsapp}/>
                                </button>

                            </a>
                </div>
            </div>


                <div className="row justify-content-between">
                    {/* MINI */}
                    <div className="col-12 col-lg-3">
                        <div className=" card card-custom py-5 px-2 px-md-4 shadow text-left text-lg-center ">
                            <div className='font-weight-boldest text-muted'>MINI</div>
                            <div>Menos de 25 clientes activos</div>
                            {/* <div className='font-size-h3 font-weight-bold mb-n4 mt-2'><del>$565.00</del></div>
                            <div className="display4 display3-xxl font-weight-bolder">$479.00 <span className='font-size-sm ml-n3'>/mes</span></div>
                            <hr /> */}
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Todas las funciones</div>
                            </div>
                            {/* <div className="d-flex text-left">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Software + App</div>
                            </div>
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>App Staff</div>
                            </div>
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Todas las funciones</div>
                            </div>
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Reservaciones ilimitadas</div>
                            </div> */}
                            <a href="https://fontawesome.com/v5/search?q=check&s=brands%2Csolid">
                                <button className='btn btn-primary font-weight-bold font-size-h6 mt-5 btn-block'>Seleccionar Plan</button>
                            </a>
                        </div>
                    </div>

                    <div className="col-12 col-lg-3">
                        <div className=" card card-custom py-5 px-2 px-md-4 shadow text-left text-lg-center ">
                            <div className='font-weight-boldest text-muted'>ESTUDIO</div>
                            <div>Menos de 60 clientes activos</div>
                            {/* <div className='font-size-h3 font-weight-bold mb-n4 mt-2'><del>$565.00</del></div>
                            <div className="display4 display3-xxl font-weight-bolder">$589.00 <span className='font-size-sm ml-n3'>/mes</span></div>
                            <hr /> */}
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Todas las funciones</div>
                            </div>
                            {/* <div className="d-flex text-left">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Software + App</div>
                            </div>
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>App Staff</div>
                            </div>
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Todas las funciones</div>
                            </div>
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Reservaciones ilimitadas</div>
                            </div> */}
                            <a href="https://fontawesome.com/v5/search?q=check&s=brands%2Csolid">
                                <button className='btn btn-primary font-weight-bold font-size-h6 mt-5 btn-block'>Seleccionar Plan</button>
                            </a>
                        </div>
                    </div>

                    <div className="col-12 col-lg-3">
                        <div className=" card card-custom py-5 px-2 px-md-4 shadow text-left text-lg-center ">
                            <div className='font-weight-boldest text-muted'>GYM</div>
                            <div>Menos de 200 clientes activos</div>
                            {/* <div className='font-size-h3 font-weight-bold mb-n4 mt-2'><del>$565.00</del></div>
                            <div className="display4 display3-xxl font-weight-bolder">$679.00 <span className='font-size-sm ml-n3'>/mes</span></div>
                            <hr /> */}
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Todas las funciones</div>
                            </div>
                            <div className="d-flex text-left">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Software + App</div>
                            </div>
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>App Staff</div>
                            </div>
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Todas las funciones</div>
                            </div>
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Reservaciones ilimitadas</div>
                            </div>
                            <a href="https://fontawesome.com/v5/search?q=check&s=brands%2Csolid">
                                <button className='btn btn-primary font-weight-bold font-size-h6 mt-5 btn-block'>Seleccionar Plan</button>
                            </a>
                        </div>
                    </div>

                    <div className="col-12 col-lg-3">
                        <div className=" card card-custom py-5 px-2 px-md-4 shadow text-left text-lg-center ">
                            <div className='font-weight-boldest text-muted'>SIN LIMITE</div>
                            <div>Sin limite de clientes activos</div>
                            {/* <div className='font-size-h3 font-weight-bold mb-n4 mt-2'><del>$565.00</del></div>
                            <div className="display4 display3-xxl font-weight-bolder">$779.00 <span className='font-size-sm ml-n3'>/mes</span></div>
                            <hr /> */}
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Todas las funciones</div>
                            </div>
                            {/* <div className="d-flex text-left">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Software + App</div>
                            </div>
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>App Staff</div>
                            </div>
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Todas las funciones</div>
                            </div>
                            <div className="d-flex text-left mt-4">
                                <FontAwesomeIcon className="text-success ml-7 mr-3 font-size-h4" icon={faCheck}/>
                                <div className='font-size-h6 font-weight-bold'>Reservaciones ilimitadas</div>
                            </div> */}
                            <a href="https://fontawesome.com/v5/search?q=check&s=brands%2Csolid">
                                <button className='btn btn-primary font-weight-bold font-size-h6 mt-5 btn-block'>Seleccionar Plan</button>
                            </a>
                        </div>
                    </div>
                    
                    
                    
                </div>

                <div className="text-muted mt-5 text-center mb-8">
                    <div>*Cliente activo: Cliente que hace al menos 1 compra o reserva en el mes.</div>
                    {/* <div>Puedes cambiar de plan para aumentar clientes activos en cualquier momento.</div> */}
                </div>

                <div className='display4 text-center text-info font-weight-boldest mb-5 border-bottom'>Todos los paquetes incluyen:</div>

                {/* IMAGENES FUNCIONES */}
                <div className="col-12 funcionessafe "></div>
                <div className="col-12 funcionessafemob d-md-none"></div>

                {/* PREGUNTAS FRECUENTES */}
                {/* <div className="row justify-content-center mt-20">
                    <div className="col-12 col-lg-8 col-xl-7 col-xxl-6 shadow mb-15">
                        <div className="card card-custom p-5">
                            <div className='font-weight-bolder h3 text-center'>Preguntas frecuentes</div>
                            
                            <div className='font-weight-bolder font-size-h6'>¿Puedo cancelar?</div>
                            <div className="text-muted mb-4">Claro, no hay planes ni contratos forzosos, cancelar no tiene costo y puedes hacerlo cuando quieras.</div>

                            <div className='font-weight-bolder font-size-h6'>¿Puedo cambiarme de plan en el futuro?</div>
                            <div className="text-muted mb-4">Si, si tu numero de clientes activos disminuye u aumenta puedes cambiar de plan en cualquier momento.</div>

                            <div className='font-weight-bolder font-size-h6'>¿Hay costo inicial o adicional?</div>
                            <div className="text-muted mb-4">No, no hay costos iniciales ni sorpresas.</div>

                            <div className='font-weight-bolder font-size-h6'>¿Cuándo aparecerá mi gimnasio en la App?</div>
                            <div className="text-muted mb-4">Tan pronto actives tu cuenta, los clientes podrán buscar tu gimnasio por su nombre y registrarse.</div>

                            <div className='font-weight-bolder font-size-h6'>¿Qué es un cliente activo?</div>
                            <div className="text-muted mb-4">Uno de tus clientes registrados que realice al menos 1 compra ó reserva en el mes es considerado "cliente activo". Ejemplo: los clientes que no regresen a tu gimnasio dejarán de contar como activos.</div>

                            <a href="https://api.whatsapp.com/send/?phone=526621323750&type=phone_number&app_absent=0" target="_blank">
                                <button className='btn btn-link'>
                                    Resuelve tus dudas, mándanos <u>
                                        WhatsApp
                                        <FontAwesomeIcon className="text-primary ml-1 mr-3 font-size-h4" icon={faWhatsapp}/>
                                    </u>
                                    <span className='text-muted'>+52 (662) 132 3750</span>
                                </button>

                            </a>
                        
                        </div>
                    </div>
                </div> */}

            {/* BOTON WHATSAPP */}
            {/* <div className="fixed-bottom text-right mb-5 mr-2">
                <a href="https://api.whatsapp.com/send/?phone=526621323750&type=phone_number&app_absent=0" target="_blank">
                    <button className='btn btn-light-success btn-pill text-white font-weight-bold font-size-h6' style={{ backgroundColor: "#128C7E" }}>
                        ¿Preguntas?
                        WhatsApp
                        <FontAwesomeIcon className="ml-1 mr-3 font-size-h4 align-self-center" icon={faWhatsapp} />
                    </button>
                </a>
            </div> */}

            {/* BOTON WHATSAPP 2F */}
            {/* <div className="fixed-bottom text-right mb-5 mr-2">
                <a href="https://api.whatsapp.com/send/?phone=526621323750&type=phone_number&app_absent=0" target="_blank">
                    <button className='btn btn-light-success btn-pill text-white font-weight-bold font-size-h6' style={{ backgroundColor: "#E4BCA6" }}>
                        Ayuda
                        <FontAwesomeIcon className="ml-1 font-size-h4 align-self-center" icon={faWhatsapp} />
                    </button>
                </a>
            </div> */}

        </div>
    );
};

export default BlankPage4;
