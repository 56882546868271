import React, {useEffect, useState} from 'react';
import {currencyFormat} from "../../../utils/currencyFormatters";
import {Card, Col, Dropdown, DropdownButton, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {endOfDay, endOfMonth, format, formatISO, startOfDay, startOfMonth, subMonths} from "date-fns";
import {es} from "date-fns/locale";
import ReactDatePicker from "react-datepicker";
import CsvDownloadModal from "./CsvDownloadModal";
import baseApi from "../../../apis/baseApi";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";

interface SaleStats {
    total: number,
    online: number,
    cashier: number,
    cards: number,
    cash: number,
    transfers: number,
    other: number,
    range_cashier: number,
    range_online: number,
}

interface Props {
    rangeStart: Date;
    rangeEnd: Date;
    setRangeStart: (date: Date) => void;
    setRangeEnd: (date: Date) => void;
}

const SalesStats = ({rangeStart, rangeEnd, setRangeEnd, setRangeStart}: Props) => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [stats, setStats] = useState<SaleStats>()


    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [downloadRangeStart, setDownloadRangeStart] = useState<Date>();
    const [downloadRangeEnd, setDownloadRangeEnd] = useState<Date>();


    useEffect(() => {
        if (rangeStart && rangeEnd) {
            const start = formatISO(startOfDay(rangeStart))
            const end = formatISO(endOfDay(rangeEnd))
            baseApi.get<SaleStats>(`/sales/stats/?branch=${branch.id}&start=${start}&end=${end}`).then(resp => {
                setStats(resp.data)
            }).catch()
        }
    }, [branch, rangeStart, rangeEnd]);

    return (
        <div>
            {/* <TabNavigator tabLabels={["Ventas", "Clientes" , "Horarios", "Planes", "Instructores"]} panels={[]}/> */}


            <CsvDownloadModal show={showDownloadModal} onHide={() => setShowDownloadModal(false)}
                              start={downloadRangeStart} end={downloadRangeEnd} branch={branch}/>


            {/* <Row className="text-right my-5">
                <Col className="align-self-end" lg={4}>
                    <div  className="text-left text-uppercase font-size-lg text-danger">{format(new Date(), "MMMM yyyy", {locale: es})}</div>
                </Col>
                <Col lg={4} className=" font-size-sm align-self-end ">
                    <Row>
                        <Col className="text-left ml-5 align-self-end">
                            <div className="">
                                {
                                    `${format(rangeStart, "dd/MMM/yy", {locale: es})} -
                                    ${rangeEnd === null ? '' : format(rangeEnd, "dd/MMM/yy", {locale: es})}`
                                }
                            </div>
                        </Col>
                        <Col className="text-right">
                            <DropdownButton size="sm" variant="outline-success"
                                            title={<span><FontAwesomeIcon icon={faFileExcel}/> Descargar</span>}>
                                <Dropdown.Header>Rango Seleccionado</Dropdown.Header>
                                {!rangeStart || !rangeEnd ? <React.Fragment/> :
                                    <Dropdown.Item onClick={() => {
                                        setDownloadRangeStart(rangeStart)
                                        setDownloadRangeEnd(rangeEnd)
                                        setShowDownloadModal(true)
                                    }}>{format(rangeStart, "dd/MM/yy")} - {format(rangeEnd, "dd/MM/yy")}</Dropdown.Item>
                                }
                                <Dropdown.Divider/>
                                <Dropdown.Header>Periodos</Dropdown.Header>
                                {Array.from(Array(12).keys()).map(i => {
                                    const month = subMonths(new Date(), i)
                                    return <Dropdown.Item key={i} onClick={() => {
                                        setDownloadRangeStart(startOfMonth(month))
                                        setDownloadRangeEnd(endOfMonth(month))
                                        setShowDownloadModal(true)
                                    }}>
                                        {format(month, "MMMM - yyyy", {locale: es})}
                                    </Dropdown.Item>
                                })}
                            </DropdownButton>
                        </Col>
                    </Row>


                </Col>
                <Col lg={4} className="text-right align-self-end">
                    <ReactDatePicker
                        selectsRange
                        selected={rangeStart}
                        locale={es}
                        value={`${format(rangeStart, "dd/MMM/yy", {locale: es})} -  ${rangeEnd === null ? '' : format(rangeEnd, "dd/MMM/yy", {locale: es})}`}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        shouldCloseOnSelect={rangeEnd === null}
                        maxDate={new Date()}
                        onChange={(dates) => {
                            if (dates) {
                                const [start, end] = dates as Date[]
                                setRangeStart(start)
                                setRangeEnd(end)

                            }
                        }}
                        customInput={<input className="form-control"/>}
                    />
                </Col>
            </Row> */}



            <Row className="justify-content-between ">

                {/* <Col lg={2} className="d-flex border-bottom pb-8">
                    <Card className="card-custom px-xxl-15 py-5 w-100">

                        <Row>
                            <Col>
                                <div className="font-weight-bolder font-size-h6 text-center">Total este mes</div>
                                <div
                                    className="font-weight-bolder font-size-h1 text-center text-primary ">{currencyFormat.format(stats?.total || 0)}</div>
                            </Col>
                            
                        </Row>

                        <Row>
                        <Col className=" d-flex justify-content-between text-center mt-3">
                                <div>
                                    <div className="text-muted font-weight-bolder font-size-lg">En línea</div>
                                    <div
                                        className="font-weight-bolder font-size-h6">{currencyFormat.format(stats?.online || 0)}
                                    </div>
                                </div>
                                <div className="py-3"></div>
                                <div>
                                    <div className="font-weight-bolder font-size-lg text-muted">Presencial</div>
                                    <div
                                        className="font-weight-bolder font-size-h6">{currencyFormat.format(stats?.cashier || 0)}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col> */}

                

                
                {/* <Col lg={12} className="mt-12">
                <h4 className="text-dark">Corte Caja</h4>

                    <div className="d-flex align-items-center">
                        <Col className="text-right align-self-end col-xxl-4">



                            <div className="d-flex align-items-center ml-n3">
                                <ReactDatePicker
                                    selectsRange
                                    selected={rangeStart}
                                    locale={es}
                                    value={`${format(rangeStart, "dd MMMM yyyy", { locale: es })}  a  ${rangeEnd === null ? '' : format(rangeEnd, "dd MMMM yyyy", { locale: es })}`}
                                    startDate={rangeStart}
                                    endDate={rangeEnd}
                                    shouldCloseOnSelect={rangeEnd === null}
                                    maxDate={new Date()}
                                    onChange={(dates) => {
                                        if (dates) {
                                            const [start, end] = dates as Date[]
                                            setRangeStart(start)
                                            setRangeEnd(end)
                                        }
                                    }}
                                    customInput={<input className="form-control text-muted font-size-h6"/>}
                                />
                                <div className="mr-3"></div>
                                <DropdownButton size="sm" variant="btn-icon btn-circle btn-link text-success"
                                    title={<span className="text-center mx-auto font-size-h4"><FontAwesomeIcon icon={faFileExcel} /> </span>}>
                                    <Dropdown.Header className="text-center font-size-lg text-dark border-bottom mb-2">Descargar a Excel</Dropdown.Header>

                                    <Dropdown.Header>Rango Seleccionado</Dropdown.Header>
                                    {!rangeStart || !rangeEnd ? <React.Fragment /> :
                                        <Dropdown.Item onClick={() => {
                                            setDownloadRangeStart(rangeStart)
                                            setDownloadRangeEnd(rangeEnd)
                                            setShowDownloadModal(true)
                                        }}>{format(rangeStart, "dd/MM/yy")} - {format(rangeEnd, "dd/MM/yy")}</Dropdown.Item>
                                    }
                                    <Dropdown.Divider />
                                    <Dropdown.Header>Por mes</Dropdown.Header>
                                    {Array.from(Array(12).keys()).map(i => {
                                        const month = subMonths(new Date(), i)
                                        return <Dropdown.Item key={i} onClick={() => {
                                            setDownloadRangeStart(startOfMonth(month))
                                            setDownloadRangeEnd(endOfMonth(month))
                                            setShowDownloadModal(true)
                                        }}>
                                            {format(month, "MMMM - yyyy", { locale: es })}
                                        </Dropdown.Item>
                                    })}
                                </DropdownButton>
                            </div>
                        </Col>
                    </div>

                    
                </Col> */}
            </Row>

            <div className="col-12   col-lg-5 col-xl-4 col-xxl-3 float-lg-right ml-n4 mb-8 mb-lg-0">
                <div className="text-center py-1 font-size-lg border pt-2 px-3 mb-4 align-items-center">
                            <div className=''>Total acumulado del mes actual</div>
                            <div className='font-weight-bolder font-size-h1'>{currencyFormat.format(stats?.total || 0)}</div>
                    </div>
            </div>

            <div className="col-12  col-lg-5 col-xl-5 col-xxl-4 ml-n4">
                

                <div className="card card-custom border rounded-0 py-5 px-3">
                    
                    <div className='h6 text-muted'>Selecciona un rango de fechas</div>
                    <Col className="text-right align-self-end">
                        <div className="d-flex align-items-center ml-n5 mb-2">
                            <ReactDatePicker
                                selectsRange
                                selected={rangeStart}
                                locale={es}
                                value={`${format(rangeStart, "dd MMMM yyyy", { locale: es })}  -  ${rangeEnd === null ? '' : format(rangeEnd, "dd MMMM yyyy", { locale: es })}`}
                                startDate={rangeStart}
                                endDate={rangeEnd}
                                shouldCloseOnSelect={rangeEnd === null}
                                maxDate={new Date()}
                                onChange={(dates) => {
                                    if (dates) {
                                        const [start, end] = dates as Date[]
                                        setRangeStart(start)
                                        setRangeEnd(end)
                                    }
                                }}
                                customInput={<input className="form-control text-primary font-size-h6" />}
                            />
                            <div className="mr-3"></div>
                            <DropdownButton size="sm" variant="btn-icon btn-circle btn-link text-success mr-n8"
                                title={<span className="text-center mx-auto font-size-h4"><FontAwesomeIcon icon={faFileExcel} /> </span>}>
                                <Dropdown.Header className="text-center font-size-lg text-dark border-bottom mb-2">Descargar Excel</Dropdown.Header>

                                <Dropdown.Header>Rango Seleccionado</Dropdown.Header>
                                {!rangeStart || !rangeEnd ? <React.Fragment /> :
                                    <Dropdown.Item onClick={() => {
                                        setDownloadRangeStart(rangeStart)
                                        setDownloadRangeEnd(rangeEnd)
                                        setShowDownloadModal(true)
                                    }}>{format(rangeStart, "dd/MM/yy")} - {format(rangeEnd, "dd/MM/yy")}</Dropdown.Item>
                                }
                                <Dropdown.Divider />
                                <Dropdown.Header>Por mes</Dropdown.Header>
                                {Array.from(Array(12).keys()).map(i => {
                                    const month = subMonths(new Date(), i)
                                    return <Dropdown.Item key={i} onClick={() => {
                                        setDownloadRangeStart(startOfMonth(month))
                                        setDownloadRangeEnd(endOfMonth(month))
                                        setShowDownloadModal(true)
                                    }}>
                                        {format(month, "MMMM - yyyy", { locale: es })}
                                    </Dropdown.Item>
                                })}
                            </DropdownButton>
                        </div>
                    </Col>


                                

                    {/* <div className='text-primary'>Ventas acumulado del mes</div>
                    <div className="d-flex justify-content-between border-bottom font-size-lg">
                        <div>Total</div>
                        <div className='font-weight-bold'>$800</div>
                    </div>
                    <div className="d-flex justify-content-between border-bottom font-size-lg">
                        <div>Pagos presenciales</div>
                        <div className='font-weight-bold'>$800</div>
                    </div>
                    <div className="d-flex justify-content-between border-bottom font-size-lg">
                        <div>Pagos en línea</div>
                        <div className='font-weight-bold'>$800</div>
                    </div> */}


                    {/* <div className='text-primary'>Ventas Rango seleccionado</div> */}


                    <div className="d-flex justify-content-between border-bottom py-1 font-size-h6">
                        <div>Efectivo</div>
                        <div className='font-weight-bold'>${stats?.cash}</div>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-1 font-size-h6">
                        <div>Tarjetas</div>
                        <div className='font-weight-bold'>${stats?.cards}</div>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-1 font-size-h6">
                        <div>Transferencia</div>
                        <div className='font-weight-bold'>${stats?.transfers}</div>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-1 font-size-h6">
                        <div>Otros</div>
                        <div className='font-weight-bold'>${stats?.other}</div>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-1 font-size-h6">
                        <div className='text-success'>Total Presencial</div>
                        <div className='font-weight-bold text-success'>${stats?.range_cashier}</div>
                    </div>
                    <div className="d-flex justify-content-between border-bottom py-1 font-size-h6">
                        <div className='text-info'>Pagos en línea</div>
                        <div className='font-weight-bold text-info'>${stats?.range_online}</div>
                    </div>
                    
                </div>
                
            </div>

            <div className='text-muted mt-5 '></div>
        </div>
    );
};

export default SalesStats;

