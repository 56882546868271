import React from 'react';
import {Button, Dropdown, DropdownButton, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Branch, BranchesState, logout, selectBranch} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../reducers";

interface Props {
    showSidebar: boolean,
    setShowSidebar: (show: boolean) => void
}

const TopBar = ({showSidebar, setShowSidebar}: Props) => {
    const dispatch = useDispatch()
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)

    // TOOLTIP - cambiar nombre y id
    const logoutTooltip = (props: any) => (<Tooltip id='logout-tt' {...props}></Tooltip>)

    return (
        <div style={{height: "30px"}} className="">

            <div className="">

                {/* <div className="d-none d-md-inline">
                    <DropdownButton id="dropdown-basic-button" title={"Sucursal " + currentBranch.name}
                                    size="sm"
                                    bsPrefix={"btn btn-pill text-muted pl-6 border border-muted"}
                                    >
                        {branchesState.branches.map((b) => {
                            return <Dropdown.Item key={b.id}
                                                  onClick={() => dispatch(selectBranch(b))}>{b.name}
                            </Dropdown.Item>
                        })}
                    </DropdownButton>
                </div> */}

                {/* Solo aparece en cuentas demo */}
                {/*<span*/}
                {/*    className="label label-warning label-pill label-inline label-lg mr-2 align-self-center font-weight-bolder shadow">Mostrando informacion DEMO</span>*/}


                <div>
                    {/*<Button className="btn btn-white btn-icon btn-circle shadow ml-2 d-none d-md-inline">*/}
                    {/*    < i className="fi-rr-search text-dark-50"/>*/}
                    {/*</Button>*/}
                    {/*<Button className="btn btn-white btn-icon btn-circle shadow ml-2 d-none d-md-inline">*/}
                    {/*    < i className="fi-rr-dollar text-dark-50"/>*/}
                    {/*</Button>*/}
                    {/*<Button className="btn btn-white btn-icon btn-circle shadow ml-2 d-none d-md-inline">*/}
                    {/*    <i className="fi-rr-bell text-dark-50"/>*/}
                    {/*</Button>*/}
                    {/*overlay = nombre del tooltip*/}




                    {/* BOTON LOGOUT AQUIIIIIIIIIIIIIIIIIIIIII */}

                                            <div className='d-none d-md-inline'>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={logoutTooltip}
                                                    delay={{show: 100, hide: 400}}
                                                >
                                                    <Button onClick={() => {
                                                        dispatch(logout())
                                                    }} className="btn-light-danger">
                                                        Logout
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>

                    {/* FIN BOTOOOOOOOOOOON LOGOUT */}


                    {/* <div className="justify-content-start">
                        <Button onClick={() => setShowSidebar(!showSidebar)} className="btn btn-white btn-icon btn-circle shadow ml-2 d-lg-none">
                            <i className="fi-rr-menu-burger text-dark-50"/>
                        </Button>
                    </div> */}

                    <div className='  d-flex justify-content-between d-md-none'>
                        {/* MENU BURGER */}
                        <div className=''>
                            <Button onClick={() => setShowSidebar(!showSidebar)} className="btn btn-primary aling-items-center d-flex" >
                                    <i className="fi-rr-menu-burger aling-self-center"/>
                                    <div className="ml-2 font-size-h5 font-weight-bolder">Menú</div>
                            </Button>
                        </div>
                        {/* FIN MOBILE SIDEBAR TOGGLE */}
                        <div className=''>
                            <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={logoutTooltip}
                                                        delay={{show: 100, hide: 400}}
                                                    >
                                                        <Button onClick={() => {
                                                            dispatch(logout())
                                                        }} className="btn-light-danger">
                                                            Logout
                                                        </Button>
                                                    </OverlayTrigger>
                        </div>
                    </div>

                </div>
                    {/* <div>Versión Demo
                        <button className="btn btn-link text-info"><u>Selecciona un plan</u></button>
                    </div> */}
            </div>

        </div>
    );
};

export default TopBar;
