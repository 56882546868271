import React from 'react';
import {Col, Row} from "react-bootstrap";

type Layout39Props = {
    title: string,
    left: JSX.Element,
    right: JSX.Element
}

const Layout39 = ({title, left, right}: Layout39Props) => {
    return (
        <div className="h-100">
            <h4>{title}</h4>
            <Row className="h-100 mt-4">
                <Col className="pl-0 pr-1 " lg={12}>
                    <div className="rounded border-right border-left" style={{background: "", height: "90%", overflowY: "auto"}}>
                        {left}
                    </div>
                </Col>
                <Col style={{ height: "90%"}} lg={12}>
                    <div   className="h-100 bg-transparent mx-0 " >
                        {right}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Layout39;
