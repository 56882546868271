import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {IconDefinition} from "@fortawesome/free-regular-svg-icons";

type EmptyAlertProps = {
    text? : string
    icon? : IconDefinition
}

const EmptyAlert = ({text= "No hay datos"} : EmptyAlertProps) => {
    return (
        <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center text-muted">
                <p className=" font-size-h6-xl mt-2">{text}</p>
        </div>
    );
};

export default EmptyAlert;
