import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";
import TableHeader from "../../../components/table/TableHeader";
import LoadingTr from "../../../components/table/rows/LoadingTr";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import {formatDateTime} from "../../../utils/dateFormatters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCashRegister} from "@fortawesome/free-solid-svg-icons";
import TableFooter from "../../../components/table/TableFooter";
import baseApi from "../../../apis/baseApi";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import {endOfDay, formatISO, startOfDay} from "date-fns";
import {Paginated} from "../../../types";
import {Sale, SaleLocations} from "./index";

interface Props {
    rangeStart: Date
    rangeEnd: Date
}

const AllSales = ({rangeStart, rangeEnd}: Props) => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)

    const [saleReports, setSaleReports] = useState<Sale[]>([])
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0)


    useEffect(() => {
        if (rangeStart && rangeEnd) {
            setLoading(true)
            const start = formatISO(startOfDay(rangeStart))
            const end = formatISO(endOfDay(rangeEnd))
            baseApi.get<Paginated>(`/sales/?branch=${branch.id}&start=${start}&end=${end}`).then((resp) => {
                setSaleReports(resp.data.results)
                setCount(resp.data.count)
                setLoading(false)
            }).catch((err) => {
                alert("ERROR")
            })
        }
    }, [branch, rangeStart, rangeEnd]);


    const handlePageChange = (page: number) => {
        setLoading(true)
        const start = formatISO(startOfDay(rangeStart))
        const end = formatISO(endOfDay(rangeEnd))
        baseApi.get<Paginated>(`/sales/?branch=${branch.id}&page=${page}&start=${start}&end=${end}`).then((resp) => {
            setSaleReports(resp.data.results)
            setCount(resp.data.count)
            setLoading(false)
        }).catch((err) => {
            alert("ERROR")
        })
    }

    return (
        <div>
            <Table hover bordered className=''>
                <TableHeader
                    headers={["Cliente", "Concepto", "Monto", "Método de pago", "Atendió", "Fecha"]}/>
                <tbody>
                {loading ? <LoadingTr/> : saleReports.length === 0 ? <EmptyTr/> : saleReports.map(s => {
                    return <tr className="text-left" key={s.id}>
                        <td className="text-left">
                            <div className="font-weight-bold font-size-lg">{s.member.full_name}</div>
                            <div className="">{s.member.email}</div>
                        </td>

                        <td className="font-size-lg">
                            <div>{s.plan_items.map(i => <span>{i.plan}<span hidden={!i.cancelled} className="text-warning font-weight-bold">PLAN CANCELADO</span> - </span>)}</div>
                            <div>{s.products.map(i => i.product).join(", ")}</div>
                        </td>

                        <td className="font-size-h6 ">$ {s.total}</td>
                        <td className="font-size-lg">{s.payment_method} {s.location === SaleLocations.POS ?
                            <div className='text-primary font-size-xs'>Pago Presencial</div> : ""}
                            
                        </td>
                        
                        

                        {/* <td className="text-uppercase font-size-sm">{s.id.slice(-8)}</td> */}
                        
                        
                        <td className="font-size-lg">{s.transaction_by}</td>
                        <td>{formatDateTime(s.created_on)}</td>

                        
                        {/* <td className="text-center font-size-lg">{s.products.map(i => i.product).join(", ")}</td> */}
                    </tr>
                })}
                </tbody>

            </Table>
            <TableFooter count={count} pageCount={saleReports.length} pageSize={10}
                         onPageChange={handlePageChange}/>
        </div>
    );
};

export default AllSales;

