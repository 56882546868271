import React, {useEffect, useState} from 'react';

import CreateRoomModal from "./CreateRoomModal";
import ToolboxHeader from "../ToolboxHeader";
import {Table} from 'react-bootstrap';
import TableHeader from "../../../components/table/TableHeader";
import TableActionsCell from "../../../components/table/TableActionsCell";
import DeleteModal from "../../../components/modals/DeleteModal";
import baseApi from "../../../apis/baseApi";
import EmptyTr from "../../../components/table/rows/EmptyTr";

import {faCircle,faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export interface Seat {
    id: string;
    label: string;
    row: number;
    col: number;
    is_coach: boolean;
}

export interface Room {
    id: string;
    seats: Seat[];
    archived: boolean;
    name: string;
    people_limit: number;
    rows: number;
    cols: number;
    branch: string;
    uses_map: boolean;
}

const Rooms = () => {
    const [showCreate, setShowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const [rooms, setRooms] = useState<Room[]>([])
    const [selectedRoom, setSelectedRoom] = useState<Room>()

    useEffect(() => {
        baseApi.get<Room[]>("/rooms/").then((resp) => {
            setRooms(resp.data)
        }).catch(() => alert("Error"))
    }, [])

    return (
        <div className="p-4">
            <CreateRoomModal show={showCreate} onHide={() => {
                setShowCreate(false)
                setSelectedRoom(undefined)
            }} room={selectedRoom}/>
            <DeleteModal url={`/rooms/${selectedRoom?.id}/`} onSuccess={() => {
                setRooms(rooms.filter(r => r.id !== selectedRoom?.id))
            }} show={showDelete} onHide={() => {
                setShowDelete(false)
                setSelectedRoom(undefined)
            }}>
                <p>¿Eliminar este salón?</p>
                <strong className=''> {selectedRoom?.name} </strong>
                <p>Todos los horarios existentes que tengan este salón asignado permaneceran con la misma capacidad</p>
            </DeleteModal>

            <button className='btn btn-link-primary mb-3'><FontAwesomeIcon icon={faLongArrowAltLeft} size="lg" className='mr-3' />Configuración</button>
                <div className=" align-items-center m">
                    <h2 className="pt-2 font-size-h2 font-weight-bolder">Mis Salones</h2>
                    <div className="d-md-flex ">
                        <p className="font-size-lg text-dark-50 d-block">Crea los salones con diferente capacidad y acomodo de tu gimnasio o estudio.</p>
                    </div>
                </div>
            <ToolboxHeader title="Salones" action={() => setShowCreate(true)}/>
            <div className="mt-5 card card-custom col-12 col-md-9 col-lg-7 col-xl-5 mt-7 py-5">
                <Table bordered className="text-center">
                    <TableHeader headers={["Nombre", "#Lugares", "Mapa", "Acciones"]}/>
                    <tbody className="font-size-lg">
                    { rooms.length === 0 ? <EmptyTr /> : rooms.map((r) => (
                        <tr key={r.id}>

                            <td className="text-left">{r.name}</td>
                            <td>{r.people_limit}</td>
                            <td>{r.uses_map ? "MAPA" : "SIN MAPA"}</td>
                            <td className='text-right'><TableActionsCell
                                onClickDelete={() => {
                                    setSelectedRoom(r)
                                    setShowDelete(true)
                                }}
                                onClickEdit={() => {
                                    setSelectedRoom(r)
                                    setShowCreate(true)
                                }}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                *Edición: no es posible editar los salones. Crea uno nuevo y asignalo a los horarios.
            </div>
        </div>
    );
};

export default Rooms;
