import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Link, NavLink} from "react-router-dom";
import {Image, OverlayTrigger, Button, Tooltip} from "react-bootstrap";



import {StoreState} from "../reducers";
import {StaffUserPermissions} from "../actions/staffUser";
import {Studio} from "../actions/studio";
import "../assets/styles/sidebar.css";
import "../assets/styles/sidebarActive.css"

import {faBell, faCog, faChartLine, faDumbbell, faAddressCard, faCashRegister, faMoneyBill, faMoneyBillWave, faUsers, faCalendarCheck, faCalendarAlt, faChartPie, faDollarSign, faCoins} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

interface SidebarProps {
    showSidebar: boolean
}

const Sidebar = ({showSidebar}: SidebarProps) => {

    
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const permissions = useSelector<StoreState, StaffUserPermissions[]>(state => state.staffUser.permissions)



    let hideReservations = true
    let hideSchedules = true
    let hideClients = true
    let hideNotifications = true
    let hideReports = true
    let hidePersonalization = true
    let hideBilling = true
    let hideCoaches = true

    permissions.forEach((p) => {
        switch (p.feature) {
            case "reservations":
                hideReservations = !p.view
                break
            case "schedules":
                hideSchedules = !p.view
                break
            case "clients":
                hideClients = !p.view
                break
            case "notifications":
                hideNotifications = !p.view
                break
            case "reports":
                hideReports = !p.view
                break
            case "personalization":
                hidePersonalization = !p.view
                break
            case "billing":
                hideBilling = !p.view
                break
            case "coaches":
                hideCoaches = !p.view
                break
        }
    })

    return (
        <nav id="sidebar" className={showSidebar ? "active" : ""} style={{height: "100%"}}>

            {/* SAFE GYM LOGO */}
            <div className="col-8 col-lg-7 safegymlogo mx-auto mt-4"></div>

            {/* IMAGEN GYM */}
            <div>
                <div className="sidebar-header text-center text-primary mt-n4">
                    <Image
                        className="shadow-sm p-1"
                        src={studio.image}
                        roundedCircle
                        fluid
                    />
                </div>
                {/* <div className="text-center font-size-lg mt-n5 mb-8 font-weight-bold" style={{color: "#9BB2C5"}}>Burn Fitness Studio</div> */}
            </div>

            {/* <div className=" ml-5 d-none d-md-block">
                <div className="d-flex mt-12 mb-10 text-center justify-content-center">
                    <div className="w-25 mr-2">
                            <div className="">
                                <Image
                                    className="shadow-lg"
                                    src={studio.image}
                                    roundedCircle
                                    fluid
                                />
                            </div>
                    </div>
                    <div className=" text-left font-size-h6 font-weight-bold align-self-center text-dark" style={{color: "#9BB2C5"}}>
                        Burn Fitness
                    </div>
                </div>
            </div> */}


            {/* MOBILE - logo area sidebar */}
            {/* MOBILE - logo area sidebar */}
            {/* MOBILE - logo area sidebar */}
            <div className="d-md-none">
                <div className="mb-5 text-center justify-content-center mx-auto">
                    <div className="w-50 mx-auto">
                            <div className="">
                                <Image
                                    className="shadow-lg"
                                    src={studio.image}
                                    roundedCircle
                                    fluid
                                />
                            </div>
                    </div>
                    {/* <div className=" text-center font-size-h6 font-weight-bold align-self-center text-dark" style={{color: "#9BB2C5"}}>Burn Fitness</div> */}
                </div>
            </div>
            {/* FIN MOBILE - logo area */}


            {/* <hr className="w-75 d-none d-md-block" style={{borderColor: "#e1e7ed"}}/> */}

            <ul className="list-unstyled components ">


                {/* DESKTOP SIDEBAR */}
                {/* DESKTOP SIDEBAR */}
                {/* DESKTOP SIDEBAR */}
                {/* DESKTOP SIDEBAR */}
                {/* DESKTOP SIDEBAR */}
                {/* DESKTOP SIDEBAR */}
                {/* DESKTOP SIDEBAR */}

                {/* NUEVO SAFE GYM BANNER */}
                {/* <a href="">
                    <button className="btn btn-light-success btn-block rounded-0">
                        <div className="font-weight-boldest">NUEVO Safe Gym </div>
                        <div className="font-size-xs">Conoce las nuevas funciones</div>
                    </button>
                </a> */}

                {/* DESKTOP */}
                                {/* <li className="d-none d-lg-block">
                                    <NavLink exact to="/blank7" activeClassName="active">
                                        <div className="d-flex align-items-center">
                                            <FontAwesomeIcon className="ml-n9 mr-3" icon={faChartPie}/>
                                            <span className="font-size-lg text-hover-primary">Dashboard</span>
                                        </div>
                                    </NavLink>
                                </li> */}
                {/* FIN DESKTOP */}

                <li hidden={hideSchedules} className="d-none d-lg-block">
                    <NavLink exact to="/schedules" activeClassName="active">
                        <div className="d-flex align-items-center">
                            {/* <i className="fi fi-rr-calendar mr-5 ml-n3" style={{color: "#9BB2C5"}}></i> */}
                            <FontAwesomeIcon className="ml-n9 mr-3" icon={faCalendarAlt}/>
                            <span className="font-size-lg  text-hover-primary">Horarios</span>
                        </div>
                    </NavLink>
                </li>

                <li hidden={hideReservations} className="d-none d-lg-block">
                    <NavLink to="/reservations" activeClassName="active">
                        <div className="d-flex align-items-center">
                            {/* <i className="mr-5 fi-rr-checkbox ml-n3"/> */}
                            <FontAwesomeIcon className="ml-n9 mr-3" icon={faCalendarCheck}/>
                            <span className="font-size-lg text-hover-primary">Reservaciones</span>
                        </div>
                    </NavLink>
                </li>

                <li hidden={hideNotifications} className="d-none d-lg-block">
                    <NavLink to="/notifications" activeClassName="active">
                        <div className="d-flex align-items-center">
                            {/* <i className="mr-5 fi-rr-comment ml-n3"/> */}
                            <FontAwesomeIcon className="ml-n9 mr-3" icon={faBell}/>
                            <span className="font-size-lg text-hover-primary">Avisos</span>
                        </div>
                    </NavLink>
                </li>


                <li hidden={hideClients} className="d-none d-lg-block">
                    <NavLink to="/users" activeClassName="active">
                        <div className="d-flex align-self-center">
                            {/* <i className="mr-5 fi-rr-user ml-n3"/> */}
                            <FontAwesomeIcon className="ml-n9 mr-3" icon={faUsers}/>
                            <span className="font-size-lg  text-hover-primary">Clientes</span>
                        </div>
                    </NavLink>
                </li>

                

                <li hidden={hideBilling} className="d-none d-lg-block">
                    <NavLink to="blank7" activeClassName="active">
                        <div className="d-flex align-self-center">
                            {/* <i className="mr-5 fi-rr-money ml-n3"/> */}
                            <FontAwesomeIcon className="ml-n9 mr-3" icon={faAddressCard}/>
                            <span className="font-size-lg text-hover-primary">Mis Planes</span>
                            {/* <span className="label label-inline label-sm align-self-center label-success font-weight-bolder px-1 ml-1">Nuevo</span> */}

                        </div>
                    </NavLink>
                </li>
                
                

                <li className="d-none d-lg-block">
                    <NavLink to="/reports2" activeClassName="active">
                        <div className="d-flex align-self-center">
                            {/* <i className="mr-5 fi-rr-money ml-n3"/> */}
                            <FontAwesomeIcon className="ml-n9 mr-3" icon={faCoins}/>
                            <span className="font-size-lg text-hover-primary">Mis Ventas</span>
                            {/* <span className="label label-inline label-sm align-self-center label-success font-weight-bolder px-1 ml-1">Nuevo</span> */}
                        </div>
                    </NavLink>
                </li>


                

                
                {/* PAQUETES SAFE GYM */}
                                    {/* <li hidden={hideReports} className="d-none d-lg-block">
                                        <NavLink to="/blank4" activeClassName="active">
                                            <div className="d-flex align-items-center">                            
                                                <FontAwesomeIcon className="ml-n9 mr-3" icon={faChartLine}/>
                                                <span className="font-size-lg text-hover-primary">Paquetes</span>

                                            </div>
                                        </NavLink>
                                    </li> */}
                {/* PAQUETES SAFE GYM */}

                
                {/* REPORTES 1 */}
                                {/* <li hidden={hideReports} className="d-none d-lg-block">
                                    <NavLink to="/reports" activeClassName="active">
                                        <div className="d-flex align-items-center">
                                            <FontAwesomeIcon className="ml-n9 mr-3" icon={faChartLine}/>
                                            <span className="font-size-lg text-hover-primary">Reportes</span>
                                        </div>
                                    </NavLink>
                                </li> */}
                

                

                <li className="d-none d-lg-block">
                    <NavLink to="/pos" activeClassName="active">
                        <div className="d-flex align-self-center">
                            {/* <i className="mr-5 fi-rr-money ml-n3"/> */}
                            <FontAwesomeIcon className="ml-n9 mr-3" icon={faCashRegister}/>
                            <span className="font-size-lg text-hover-primary">Registrar Venta</span>
                            {/* <span className="label label-inline label-sm align-self-center label-success font-weight-bolder px-1 ml-1">Nuevo</span> */}

                        </div>
                    </NavLink>
                </li>

                <li hidden={hidePersonalization} className="settings d-none d-lg-block">
                    <NavLink to="/toolbox" activeClassName="active">
                        <div className="d-flex align-items-center">
                            {/* <i className="fi fi-rr-settings-sliders mr-5 ml-n3"></i> */}
                            <FontAwesomeIcon className="ml-n9 mr-3" icon={faCog}/>
                            <span className="font-size-lg text-hover-primary">Personalización</span>
                            {/* <span className="label label-inline label-sm align-self-center label-success font-weight-bolder px-1 ml-1 ">Nuevo</span> */}

                        </div>
                    </NavLink>
                </li>
                
                <div className="d-none d-lg-block">
                    <hr />
                    <div className="font-size-md text-center text-success">NUEVO WHATSAPP <span className="font-weight-boldest">SOPORTE</span> </div>
                    <div className="font-size-xs text-center">Estamos mejorando nuestro servicio y rapidez para ti</div>
                    <div className="text-center"><FontAwesomeIcon className="font-size-h3 text-success" icon={faWhatsapp}/> (662) 387 4554</div>
                </div>




                {/* ENTRENAMIENTOS */}
                            {/* <li className="d-none d-lg-block">
                                <NavLink to="/blank2" activeClassName="active">
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon className="ml-n9 mr-3" icon={faDumbbell}/>
                                        <span className="font-size-lg text-hover-primary">Entrenamientos</span>
                                    </div>
                                </NavLink>
                            </li> */}
                {/* ENTRENAMIENTOS */}

               

               


                
                {/* FIN DESKTOP SIDEBAR */}
                {/* FIN DESKTOP SIDEBAR */}
                {/* FIN DESKTOP SIDEBAR */}
                {/* FIN DESKTOP SIDEBAR */}
                {/* FIN DESKTOP SIDEBAR */}
                {/* FIN DESKTOP SIDEBAR */}
                {/* FIN DESKTOP SIDEBAR */}





                {/* MOBILE SIDEBAR */}
                {/* MOBILE SIDEBAR */}
                {/* MOBILE SIDEBAR */}
                {/* MOBILE SIDEBAR */}
                {/* MOBILE SIDEBAR */}
                {/* MOBILE SIDEBAR */}

                {/* DASHBOARD */}
                            {/* <li className="d-lg-none">
                                <NavLink exact to="/blank3" activeClassName="active">
                                    <div className="text-left mt-2">
                                    <FontAwesomeIcon className="font-size-h4 mr-2 ml-2" icon={faChartPie}/>
                                        <span className="font-size-h6 text-hover-primary">Dashboard</span>
                                    </div>
                                </NavLink>
                            </li> */}
                {/* DASHBOARD */}


                <li hidden={hideSchedules} className="d-lg-none">
                    <NavLink exact to="/schedules" activeClassName="active">
                        <div className="text-left mt-2">
                        <FontAwesomeIcon className="font-size-h4 mr-2 ml-2" icon={faCalendarAlt}/>
                            <span className="font-size-h6  text-hover-primary">Horarios</span>
                        </div>
                    </NavLink>
                </li>

                <hr className="w-75 d-md-none my-2" style={{borderColor: "#e1e7ed"}}/>

                <li hidden={hideReservations} className="d-lg-none">
                    <NavLink to="/reservations" activeClassName="active">
                        <div className="text-left mt-2">
                        <FontAwesomeIcon className="font-size-h4 mr-2 ml-2" icon={faCalendarCheck}/>
                            <span className="font-size-h6 text-hover-primary">Reservaciones</span>
                        </div>
                    </NavLink>
                </li>

                <hr className="w-75 d-md-none my-2" style={{borderColor: "#e1e7ed"}}/>


                <li hidden={hideClients} className="d-lg-none">
                    <NavLink to="/users" activeClassName="active">
                        <div className="text-left mt-2">
                        <FontAwesomeIcon className="font-size-h4 mr-2 ml-2" icon={faUsers}/>
                            <span className="font-size-h6  text-hover-primary">Clientes</span>
                        </div>
                    </NavLink>
                </li>

                <hr className="w-75 d-md-none my-2" style={{borderColor: "#e1e7ed"}}/>

                <li className="d-lg-none">
                    <NavLink to="/pos" activeClassName="active">
                        <div className="text-left mt-2">
                        <FontAwesomeIcon className="font-size-h4 mr-2 ml-2" icon={faCashRegister}/>
                            <span className="font-size-h6 text-hover-primary">Caja</span>
                        </div>
                    </NavLink>
                </li>

                <hr className="w-75 d-md-none my-2" style={{borderColor: "#e1e7ed"}}/>

                <li hidden={hideNotifications} className="d-lg-none">
                    <NavLink to="/notifications" activeClassName="active">
                        <div className="text-left mt-2">
                        <FontAwesomeIcon className="font-size-h4 mr-2 ml-2" icon={faBell}/>
                            <span className="font-size-h6 text-hover-primary">Avisos</span>
                        </div>
                    </NavLink>
                </li>

                <hr className="w-75 d-md-none my-2" style={{borderColor: "#e1e7ed"}}/>

                <li hidden={hideBilling} className="d-lg-none">
                    <NavLink to="/blank7" activeClassName="active">
                        <div className="text-left mt-2">
                            <FontAwesomeIcon className="font-size-h4 mr-2 ml-2" icon={faAddressCard}/>
                            <span className="font-size-h6 text-hover-primary">Mis Planes</span>
                        </div>
                    </NavLink>
                </li>

                <hr className="w-75 d-md-none my-2" style={{borderColor: "#e1e7ed"}}/>

                <li  className="d-lg-none">
                    <NavLink to="/reports2" activeClassName="active">
                        <div className="text-left mt-2">
                            <FontAwesomeIcon className="font-size-h4 mr-2 ml-2" icon={faCoins}/>
                            <span className="font-size-h6 text-hover-primary">Mis Ventas</span>
                        </div>
                    </NavLink>
                </li>

                <hr className="w-75 d-md-none my-2" style={{borderColor: "#e1e7ed"}}/>


                {/* REPORTES 1 */}
                        {/* <li hidden={hideReports} className="d-lg-none">
                            <NavLink to="/reports" activeClassName="active">
                                <div className="text-left mt-2">
                                    <FontAwesomeIcon className="font-size-h4 mr-2 ml-2" icon={faMoneyBill}/>
                                    <span className="font-size-h6 text-hover-primary">Reportes</span>
                                </div>
                            </NavLink>
                        </li> */}


                <li hidden={hidePersonalization} className="settings d-lg-none">
                    <NavLink to="/toolbox" activeClassName="active">
                        <div className="text-left mt-2">
                            <FontAwesomeIcon className="font-size-h4 mr-2 ml-2" icon={faCog}/>
                            <span className="font-size-h6 text-hover-primary">Configuración</span>
                        </div>
                    </NavLink>
                </li>

                <div className="d-lg-none">
                    <hr />
                    <div className="font-size-md text-center text-success">NUEVO WHATSAPP <span className="font-weight-boldest">SOPORTE</span> </div>
                    <div className="font-size-xs text-center">Estamos mejorando nuestro servicio y rapidez para ti</div>
                    <div className="text-center"><FontAwesomeIcon className="font-size-h3 text-success" icon={faWhatsapp}/> (662) 387 4554</div>
                </div>

                {/* FIN MOBILE SIDEBAR */}
                {/* FIN MOBILE SIDEBAR */}
                {/* FIN MOBILE SIDEBAR */}
                {/* FIN MOBILE SIDEBAR */}
                {/* FIN MOBILE SIDEBAR */}



            </ul>
            {/*  <div className="text-center d-none d-md-block">*/}
            {/*      <OverlayTrigger*/}
            {/*          placement="right"*/}
            {/*          overlay={*/}
            {/*              <Tooltip id="sidebarTooltip">*/}
            {/*                  {`${close ? "Maximizar" : "Minimizar"} sidebar`}*/}
            {/*              </Tooltip>*/}
            {/*          }*/}
            {/*      >*/}
            {/*<span className="" style={{cursor: "pointer", fontSize: "1.5rem"}}>*/}
            {/*  <FontAwesomeIcon*/}
            {/*      onClick={() => {*/}
            {/*          // setClose(!close);*/}
            {/*          // setOpenSidebar(close);*/}
            {/*      }}*/}
            {/*      icon={close ? faArrowAltCircleRight : faArrowAltCircleLeft}*/}
            {/*  />*/}
            {/*</span>*/}
            {/*      </OverlayTrigger>*/}
            {/*  </div>*/}


            {/* <div className="d-flex pt-30">
                <div className="px-3"></div>
                <button className="btn btn-white shadow btn-block btn-lg py-4 text-muted font-weight-bold btn-hover-dark">Planes y Precios</button>
                <div className="px-3"></div>
            </div> */}


            {/* MI SUSCRIPCION */}
                                {/* <Link style={{textDecoration: "none"}} to="/billing">
                                    <div hidden={hideBilling || studio.active} className="px-5 mt-10">
                                        <button className="btn btn-success shadow btn-block btn-lg py-4  font-weight-bold  font-size-md">
                                            <div>Planes y Precios</div>
                                        </button>
                                    </div>
                                    <div hidden={hideBilling || !studio.active} className="px-5 mt-10">
                                        <button
                                            className="btn btn-link-secondary font-size-md text-muted">
                                            <div>Mi Suscripción</div>
                                        </button>
                                    </div>
                                </Link> */}
            {/* MI SUSCRIPCION */}
            
            {/* SOPORTE Y AYUDA */}
                                {/* <Link style={{textDecoration: "none"}} to="/billing">
                                    <div hidden={hideBilling || studio.active} className="px-5 mt-10">
                                        <button className="btn btn-success shadow btn-block btn-lg py-4  font-weight-bold  font-size-md">
                                            <div>Planes y Precios</div>
                                        </button>
                                    </div>
                                    <div hidden={hideBilling || !studio.active} className="px-5 mt-4">
                                        <button
                                            className="btn btn-link-secondary font-size-md text-muted bg-danger">
                                            <div>Soporte y Ayuda</div>
                                        </button>
                                    </div>
                                </Link> */}
            {/* SOPORTE Y AYUDA */}


            {/* BOTON ORIGINAL BLANCO */}
                        {/* <Link style={{textDecoration: "none"}} to="/billing">
                            <div hidden={hideBilling || studio.active} className="px-5 mt-10">
                                <button
                                    className="btn btn-white shadow btn-block btn-lg py-4 text-muted font-weight-bold btn-hover-dark font-size-md">
                                    <div>Planes y Precios</div>
                                    <div className="label label-light-danger label-pill label-inline mt-2 font-weight-bold">50%
                                    </div>
                                </button>
                            </div>
                            <div hidden={hideBilling || !studio.active} className="px-5 mt-10">
                                <button
                                    className="btn btn-white shadow btn-block btn-lg py-4 text-muted font-weight-bold btn-hover-dark font-size-md">
                                    <div>Suscripción</div>
                                </button>
                            </div>
                        </Link> */}
            {/* BOTON ORIGINAL BLANCO */}


            {/* <div className="d-flex justify-content-between">
                <div className="px-3"></div>
                <button className="btn btn-clean btn-icon btn-circle ">
                    <i className="fi-rr-portrait text-dark-50"></i>
                </button>
                <button className="btn btn-clean btn-icon btn-circle ">
                    <i className="fi-rr-interrogation text-dark-50"></i>
                </button>
                <div className="px-3"></div>
            </div> */}

            {/* BOTON BLANCO WEBSITE Y APP */}
            {/* <Link to="website-app">
                <div className="d-flex mt-5">
                    <div className="px-3"></div>
                    <button
                        className="btn btn-white shadow btn-block btn-lg py-4 text-muted font-weight-bold btn-hover-dark font-size-md">
                        <div>
                            <i className="fi-rr-info text-muted"></i>
                            Website y App
                        </div>
                        <img src="../src/assets/img/app-store.png" alt="" />
                    </button>
                    
                    <div className="px-3"></div>
                </div>
            </Link> */}

            {/* <button className="btn btn-light-success font-weight-bolder text-center mx-auto">Activar un Plan</button> */}
            {/* <div className="fixed-bottom">
                
                <button className="btn"><i className="fi-rr-sign-out mr-2 ml-3 align-self-end text-hover-primary"/></button>
                <button className="btn"><i className="fi-rr-receipt mr-2 ml-3 align-self-end text-hover-primary"/></button>
                <button className="btn"><i className="fi-rr-credit-card mr-2 ml-3 align-self-end text-hover-primary"/></button>

            </div> */}

            {/* MOBILE - cerrar menu sidebar button */}
            {/* MOBILE - cerrar menu sidebar button */}
            {/* MOBILE - cerrar menu sidebar button */}
            {/* MOBILE - cerrar menu sidebar button */}
            {/* <div className="d-lg-none">
                <button className="btn btn-link align-items-center d-flex mx-auto">
                    <i className="fi fi-rr-cross icon-xs align-self-center"></i>
                    <div className="text-muted">Cerrar menú</div>
                </button>

                <div className="py-10"></div>

                <button className="btn btn-link align-items-center text-center">
                    <div className="text-muted">Cerrar Sesión</div>
                </button>

                <button className="btn btn-link align-items-center d-flex mx-auto">
                    <i className="fi fi-br-whatsapp icon-xs align-self-center"></i>
                    <div className="text-muted">Soporte</div>
                </button>

                <button className="btn btn-link align-items-center text-center">
                    <div className="">Selecciona un plan</div>
                </button>
            </div> */}
            {/* FIN MOBILE - cerrar menu sidebar button */}





        </nav>
    );
};


export default Sidebar;