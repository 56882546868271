import React, {useEffect, useState} from 'react';
import {Button, Card, Table} from 'react-bootstrap';
import {Branch} from "../../actions";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";

import baseApi from "../../apis/baseApi";
import TableHeader from "../../components/table/TableHeader";
import TableFooter from "../../components/table/TableFooter";
import CreateMemberModal from './CreateMemberModal';
import MemberTr from '../../components/table/rows/MemberTr';

import MemberTr2 from '../../components/table/rows/MemberTr2';

import EmptyTr from "../../components/table/rows/EmptyTr";
import {useParams, useLocation, useHistory} from 'react-router-dom';
import {format} from "date-fns";
import {CSVDownload} from "react-csv";


interface User {
    id: string,
    email: string,
    birthday?: string
    gender: string
    // last_name?: string
}

interface MemberPreferredBranch {
    id: string
    name: string
}

interface MemberCurrentPlan {
    id: string;
    name: string;
    expire_on: string;
}

export interface Member {
    id: string,
    external_id: string,
    full_name: string,
    last_name?: string;
    phone: string,
    preferred_branch: MemberPreferredBranch,
    current_credits: number,
    current_membership?: MemberCurrentPlan,
    credits_expiration?: string,
    user: User,
    created_on: string,
    emergency_contact_phone: string
    email?: string,
    img: string | null
    shoe_size: string
    birthday?: string
    id_number: string
}

const Members = () => {
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)

    const [membersList, setMembersList] = useState<Member[]>([])
    const [showAdd, setShowAdd] = useState(false)
    const [count, setCount] = useState(0)
    const [searchTerm, setSearchTerm] = useState("");
    const [activeMembersCount, setActiveMembersCount] = useState();

    const [fetchingDownloadData, setFetchingDownloadData] = useState(false);
    const [downloader, setDownloader] = useState(<div />);


    useEffect(() => {
        baseApi.get("/members/active-count/").then(resp => {
            setActiveMembersCount(resp.data['active_members'])
        }).catch()
    }, []);

    useEffect(() => {
        baseApi.get(`/studios/members/?branch=${currentBranch.id}`).then((resp) => {
            setCount(resp.data.count)
            setMembersList(resp.data.results)
        }).catch(() => alert("Error obteniendo los usuarios"))
    }, [currentBranch])

    const handlePageChange = (page: number) => {
        baseApi.get(`/studios/members/?branch=${currentBranch.id}&page=${page}`).then((resp) => {
            setMembersList(resp.data.results)
        }).catch(() => alert("Error obteniendo los usuarios"))
    }

    useEffect(() => {
        let timeout = setTimeout(() => {
            baseApi.get(`/studios/members/?search=${searchTerm}&branch=${currentBranch.id}&page=1`).then((resp) => {
                setMembersList(resp.data.results)
            })
        }, 1200)
        return () => {
            clearTimeout(timeout)
        };
    }, [searchTerm, currentBranch]);

    const onSelectDownload = async () => {
        setDownloader(<div />)
        setFetchingDownloadData(true)
        const resp = await baseApi.get(`/reports/members-active/download/`)
        const headers = ["ID", "CLIENTE", "CORREO", "PLAN", "ACTIVACIÓN", "VENCIMIENTO", "CRÉDITOS"]
        const data = [headers, ...resp.data]
        let filename = `Clientes activos ${format(new Date(), "dd/MM/yyyy")}`

        console.log(filename)
        setDownloader( <CSVDownload filename={filename} data={data} />)
        setFetchingDownloadData(false)
    }


    return (
        <div className="on-clients">
            {downloader}
            <CreateMemberModal show={showAdd} onHide={() => setShowAdd(false)} membersList={membersList}
                               setMembersList={setMembersList}/>

            <div className="row">
                <div className="col-12 col-md-7">
                    <div className="">
                        <div className="d-flex align-items-center">
                            {/* <i className="fi-rr-user mr-3 text-dark"/> */}
                            <h1 className="text-primary">Clientes</h1>
                    
                        </div>
                        <p className="font-size-h5 text-dark-50">Administra personas que se registren en tu gimnasio.</p>
                        <div className="d-inline-flex">
                            <Button className="btn btn-primary align-self-center font-weight-bolder" onClick={() => setShowAdd(true)}>
                                    {/* <i className="fi-rr-user-add mr-1"/> */}
                                    + Registrar Cliente
                            </Button>
                            <Button disabled={fetchingDownloadData} className="btn btn-outline-success align-self-center ml-2 " onClick={onSelectDownload}>
                                {/* <i className="fi-rr-user-add mr-1"/> */}
                                Descargar clientes activos ({activeMembersCount})
                            </Button>
                        </div>

                    </div>
                </div>

                {/* CLIENT STATE CARD */}
                    {/* <div className='col-12 col-md-5 d-flex justify-content-md-end pb-8 mt-2'>
                        <div className="card bg-transparent border-primary px-5 pt-5 text-primary text-center">
                            <div>Total</div>
                            <div>20</div>
                        </div>
                        <div className="card bg-transparent border-primary px-2 pt-5 text-primary text-center ml-2">
                            <div>Vigentes</div>
                            <div>20</div>
                        </div>
                        <div className="card bg-transparent border-primary px-2 pt-5 text-primary text-center ml-2">
                            <div>Vencidos</div>
                            <div>20</div>
                        </div>
                    </div> */}
                {/* FIN CLIENT STATE CARDS */}


            </div>
            {/* <div className="d-flex align-items-center justify-content-between mt-5 mb-8">
                <div className="">
                    <div className="d-flex align-items-center">
                        <i className="fi-rr-user mr-3"/>
                        <h2 className="text-dark">Clientes</h2>
                    </div>
                </div>

                <div className="text-right">
                    <Button className="btn btn-outline-info" onClick={() => setShowAdd(true)}>
                        <i className="fi-rr-user-add mr-1 text-info"/>
                        Agregar Cliente
                    </Button>
                </div>
            </div> */}

            <div className="ml-auto w-md-700px  pb-2 input-group">
                    {/* BOTON VER SOLO CLIENTES ACTIVOS */}
                                    {/* <Button className="btn btn-outline-success align-self-center font-weight-bolder mr-3 rounded-0" onClick={() => history.push ("/clientes-activos")}>
                                            Ver solo clientes activos
                                    </Button> */}
                <input className=" form-control form-control border-secondary font-size-h5" value={searchTerm}
                       onChange={(e) => setSearchTerm(e.target.value)} placeholder="Buscar"/>
            </div>

            {/* TABLA DESKTOP MemberTr*/}
            <Card className="card-custom px-5 d-none d-lg-block shadow pb-40">

                <Table hover>
                    <TableHeader
                        headers={["Nombre", "Correo", "Teléfono", "ID", "Estado", "Paquete de Créditos", "Membresías", ""]}/>
                    <tbody>
                    {membersList.length === 0 ? <EmptyTr/> : membersList.map((m) => (
                        <MemberTr key={m.id} member={m}/>))}
                    </tbody>

                </Table>
                <TableFooter count={count} pageCount={membersList.length} pageSize={10}
                             onPageChange={handlePageChange}/>
            </Card>

            {/* TABLA MOBILE MemberTr2*/}
            <Card className="d-lg-none">
                <Table hover>
                    <TableHeader
                        headers={[]}/>
                    <tbody>
                    {membersList.length === 0 ? <EmptyTr/> : membersList.map((m) => (
                        <MemberTr2 key={m.id} member={m}/>))}
                    </tbody>

                </Table>
                <TableFooter count={count} pageCount={membersList.length} pageSize={10} onPageChange={handlePageChange}/>
            </Card>
        </div>
    );
};

export default Members;
