import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';



const BlankPage6 = () => {



    return (
        <div>
            

        </div>
    );
};

export default BlankPage6;
