import React, {ChangeEvent, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faEdit, faImage} from "@fortawesome/free-solid-svg-icons";
import Resizer from "react-image-file-resizer";

type ImageFieldProps = {
    src?: string | null
    onChange: (file: File) => void
}

const ImageField = ({src, onChange}: ImageFieldProps) => {
    const [imgSrc, setImgSrc] = useState<string | null | undefined>(src)

    const imgBtnRef = useRef(null);

    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0]
            const extension = file.name.split(".").pop()
            const renamedFile = new File([file], `${uuidv4()}.${extension}`, {type: file.type})
            const reader = new FileReader()
            reader.readAsDataURL(renamedFile)
            reader.onloadend = (e) => {
                setImgSrc(reader.result as string)
            }
            Resizer.imageFileResizer(renamedFile, 400, 400, extension!, 75, 0, (resizedFile) => {
                onChange(resizedFile as File)
            }, 'file')
        }
    }
    return (
        <div>
            <div className="image-input" id="kt_image_1">
                <div className="image-input-wrapper border rounded-0 w-200px h-200px">

                    {imgSrc ?
                        <img className="image-input-wrapper w-200px h-200px" src={imgSrc} alt="studio logo"/> :
                        <React.Fragment/>}
                </div>
                <label
                    onClick={() => {
                        // @ts-ignore
                        imgBtnRef.current.click()
                    }}
                    className="btn btn-primary btn-icon "
                    data-action="change" data-toggle="tooltip" title=""
                    data-original-title="Change avatar">
                    <FontAwesomeIcon icon={faEdit}/>
                </label>

            </div>
            <input name="image" ref={imgBtnRef} style={{display: "none"}} type="file"
                   accept="image/png, image/jpeg" onChange={handleImageUpload}/>
        </div>
    );
};

export default ImageField;
