import React, {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field} from "formik";

import FormField from "../../components/form/FormField";
import {getApiErrorMessage} from "../../utils/apiErrors";
import baseApi from "../../apis/baseApi";
import {FormikSelect} from "../../components/form/FormikSelect";
import ModalSelect from "../../components/form/ModalSelect";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {BranchesState} from "../../actions";
import yup from "../../utils/yup";
import FieldError from "../../components/form/FieldError";
import {PushNotification} from "./index";

type CreateCoachModalProps = {
    show: boolean
    onHide: Function
    notifications: PushNotification[]
    setNotifications: (notifications: PushNotification[]) => void
}

const CreateNotificationModal = ({show, onHide, notifications, setNotifications}: CreateCoachModalProps) => {
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)
    const currentBranchId = useSelector<StoreState, string>(state => state.currentBranch.id)

    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        title: yup.string().min(3).max(150).required(),
        message: yup.string().max(500).required(),
        branch: yup.string().required()
    })

    return (
        <React.Fragment>
            <Modal size="sm" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{title: "", message: "", branch: currentBranchId}}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        baseApi.post("/notifications/", values).then(resp => {
                            setNotifications([resp.data, ...notifications])
                            setAlertMessage("Aviso enviado")
                            setSuccess(true)
                            setSubmitting(false)
                        }).catch(e => {
                            setAlertMessage("Intenta de nuevo, el máximo de caracteres es 500" + getApiErrorMessage(e))
                            setSuccess(false)
                            setSubmitting(false)
                        })

                    }}>
                    {({isSubmitting}) => (
                        <FormikForm>
                            <Modal.Header className='text-primary font-weight-bolder'>
                                Nuevo Aviso
                            </Modal.Header>

                            <Modal.Body>
                                {renderAlert()}

                                {/* SUCURSAL */}
                                {/* <div hidden={branchesState.selectOptions.length === 1}>
                                    <ModalSelect label="Sucursal">
                                        <Field name="branch" options={branchesState.selectOptions}
                                               component={FormikSelect}/>
                                        <FieldError name="branch"/>
                                    </ModalSelect>
                                </div> */}
                                {/* FIN SUCURSAL */}

                                <div className='font-size-h5 font-weight-bold'>Título</div>
                                <FieldError name="title"/>
                                <FormField label="" name="title"/>
                                <div className='font-size-h5 font-weight-bold'>Mensaje</div>
                                <FieldError name="message"/>
                                <div className="w-100">
                                    <FormField  component="textarea" label="" name="message"/>
                                </div>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="light-dark font-weight-bolder font-size-lg text-white" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button className='font-weight-bolder font-size-lg ' type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Enviar Aviso"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateNotificationModal;
