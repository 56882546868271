import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const BlankPage5 = () => {
    return (
        <div>
            holis

        </div>
    );
};

export default BlankPage5;
