import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm} from "formik";
import FormInputGroup from "../../../components/form/FormInputGroup";
import baseApi from "../../../apis/baseApi";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {CreditsTransaction} from "./MemberCredits";
import {Member} from "../index";
import yup from "../../../utils/yup";
import FieldError from "../../../components/form/FieldError";

type Props = {
    show: boolean,
    onHide: Function,
    member: Member
    setMember: (member: Member) => void
    transactions: CreditsTransaction[]
    setTransactions: (transactions: CreditsTransaction[]) => void
}

const RemoveCreditsModal = ({show, onHide, member, setMember, transactions, setTransactions}: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        amount: yup.number().positive().required(),
    })

    return (
        <React.Fragment>
            <Modal size="sm" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{amount: 0}} onSubmit={(values, {setSubmitting}) => {
                    setSuccess(null)
                    baseApi.post("/credits/remove/", {
                        amount: values.amount,
                        member: member.id,
                    }).then((resp) => {
                        setTransactions([...resp.data, ...transactions])
                        setMember({...member, current_credits: member.current_credits - values.amount})
                        setSuccess(true)
                        setAlertMessage("Créditos removidos.")
                    }).catch(err => {
                        setAlertMessage(getApiErrorMessage(err))
                        setSuccess(false)
                        setSubmitting(false)
                    })
                }}>
                    {({isSubmitting}) => (
                        <FormikForm>

                            <Modal.Header className='font-weight-boldest text-danger'>
                                Ajuste (-)
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <FieldError name="amount" />
                                <div className='ml-n3 font-size-lg font-weight-bolder'>Quitar</div>
                                <FormInputGroup name="amount" label="" groupText="- créditos"
                                                placeholder="0"/>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="light-dark font-weight-bolder font-size-lg text-white" onClick={handleClose}>
                                    Atrás
                                </Button>
                                <Button className='btn-danger font-weight-bolder font-size-lg' variant="btn-success font-weight-bolder font-size-lg" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "- Quitar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default RemoveCreditsModal;
