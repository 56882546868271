import React, {useEffect, useState} from 'react';
import {Card, Table} from "react-bootstrap";
import TableHeader from "../../components/table/TableHeader";
import baseApi from "../../apis/baseApi";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";
import CreateNotificationModal from "./CreateNotificationModal";
import EmptyTr from "../../components/table/rows/EmptyTr";
import {formatDate} from "../../utils/dateFormatters";

import {faBell} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export interface PushNotification {
    title: string
    message: string
    branch: string
    created_by: string
    created_on: string
}

const Notifications = () => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [notifications, setNotifications] = useState<PushNotification[]>([]);
    const [showCreate, setShowCreate] = useState(false);

    useEffect(() => {
        baseApi.get(`/notifications/?branch=${branch.id}`).then(resp => {
            setNotifications(resp.data.results)
        })
    }, [branch]);


    return (
        <div className="h-100 p-4">
            <CreateNotificationModal show={showCreate} onHide={() => setShowCreate(false)} notifications={notifications} setNotifications={setNotifications} />
            {/* <div className="d-flex align-items-center">
                <i className="fi-rr-comment mr-3" />
                <h2 className="text-dark">Avisos</h2>
                
            </div>
            <div className="font-size-lg text-muted ml-8 mb-4">Envía avisos, promociones o mensajes directamente al celular de tus clientes con notificaciones push.
                <button className="btn btn-link text-secondary">Más información</button>
            </div>
            <button onClick={() => setShowCreate(true)}
                        className="d-none d-md-inline ml-7 btn btn-primary  btn-p btn-lg border-0  font-weight-bolder mb-5" style={{color: "#007bff"}}>
                    + Enviar Nuevo Aviso
            </button> */}
            <div>
                        <div className="d-flex align-items-center">
                            <h1 className="text-primary font-size-h1">Avisos</h1>

                        </div>
                        <div className="d-md-flex ">
                            <p className="font-size-h6 text-dark-50 d-block">Envía notificaciones push con avisos o promociones directamente al celular de tus clientes.</p>
                            {/* <button className="btn btn-link text-secondary align-self-center mt-n4 d-block ml-n4 ml-md-2">¿Qué es una notificación push?</button> */}
                        </div>

                    </div>
            <button onClick={() => setShowCreate(true)}
                        className="btn btn-success btn-p btn-lg border-0  font-weight-bolder mb-5">
                                    <FontAwesomeIcon icon={faBell} size="lg"/>
                    <span className='ml-2'>Enviar nuevo aviso</span>
            </button>

            
            <div className="d-lg-flex justify-content-between">
                <Card className="card-custom p-5 col-12 justify-content-center">
                    {/* TITULO MEJOR DISENO */}
                    {/* <div className='text-center h5'>Historial</div> */}
                    <Table bordered>
                        <TableHeader
                            headers={[ "Título", "Mensaje", "Creado en"]}/>
                        <tbody>
                        {notifications.length === 0 ? <EmptyTr /> : notifications.map(n => {
                            return <tr key={n.created_on} className="text-left font-size-h6">

                                {/* SG ORIGINAL MEJOR DISENO */}
                                {/* <td className="text-left">
                                    <div className='font-size-sm text-primary'>{formatDate(n.created_on)}</div>
                                    <div className='font-size-sm text-muted'>Creado por: {n.created_by}</div>
                                    <div className='font-size-h6'>Título: {n.title}</div>
                                    <div className='font-size-h6'>Mensaje: {n.message}</div>
                                </td> */}
                                <td>{n.title}</td>
                                <td>{n.message}</td>
                                <td>
                                    <div className='font-size-sm text-primary'>{formatDate(n.created_on)}</div>
                                    <div className='font-size-sm '>Por: {n.created_by}</div>
                                </td>
                                {/* <td className="text-left">{n.message}</td>
                                <td>{formatDate(n.created_on)}</td>
                                <td>{n.created_by}</td> */}
                            </tr>
                        })}
                        {/*{saleReports.length === 0 ? <EmptyTr /> : saleReports.map(s => {*/}
                        {/*    return <tr className="text-center" key={s.id}>*/}
                        {/*        <td className="text-uppercase font-size-sm">{s.id.slice(-6)}</td>*/}
                        {/*        <td>{formatDate(s.created_on)}</td>*/}
                        {/*        <td>$ {s.total}</td>*/}
                        {/*        <td className="text-left">*/}
                        {/*            <div className="font-weight-bold font-size-sm">{s.member.full_name}</div>*/}
                        {/*            <div className="text-muted font-size-xs">{s.member.email}</div>*/}
                        {/*        </td>*/}
                        {/*        <td>{s.transaction_by}</td>*/}
                        {/*        <td className="text-center">{s.plan_items.map(i => i.plan).join(", ")}</td>*/}
                        {/*        <td>{formatDate(s.expiration)}</td>*/}
                        {/*    </tr>*/}
                        {/*})}*/}
                        </tbody>
                    </Table>
                    {/*<TableFooter count={count} pageCount={saleReports.length} pageSize={10}*/}
                    {/*             onPageChange={handlePageChange}/>*/}
                </Card>
                
                {/* FOTO NOTIFICACIONES */}
                {/* <div className='h-25 w-25 bg-light-primary rounded'>
                    <div className="bg-light-primary rounded safegymnotif mx-auto">
                    </div>
                </div> */}
                {/* FIN FOTO NOTIFICACIONES */}

            </div>
        </div>
    );
};

export default Notifications;
