import React, {useState} from 'react';
import Layout39 from "../../layouts/Layout-3-9";

import "../../assets/styles/personalization.css"

import Plans from "../Toolbox/Plans/index";

import {
    faCogs,
    faCreditCard,
    faIdBadge,
    faIdCard, faKaaba, faMountain,
    faSitemap, faTags, faTicketAlt,
} from "@fortawesome/free-solid-svg-icons";
// import ToolboxSidebarItem from "./SidebarItem";
import BlankPage6 from './BlankPage6';
import ToolboxSidebarItem from '../Toolbox/SidebarItem';


enum ToolboxOption {Branches, Classes, Rsvp, Rooms, Plans, Settings, Staff, PaymentProcessors, Coupons}

const BlankPage7 = () => {

    const [selectedOption, setSelectedOption] = useState(ToolboxOption.Branches)

    const renderComponent = (): JSX.Element => {

        switch (selectedOption) {
            
            case ToolboxOption.Plans:
                return <Plans/>
            
            default:
                return <div><Plans/></div>
        }
    }


    // Empieza aqui///////////////
    // Empieza aqui///////////////
    // Empieza aqui///////////////

    const renderSidebar = () => {
        return <div className=" border-0">
                    {/* <h1 className="text-primary font-size-h1">Mis Planes</h1>
                    <div className="d-flex align-items-top">
                        <span className='font-size-h5 text-dark-50 mb-3'>Crea los planes en venta de tu gimnasio, podrás asignarlos a tus clientes.</span>
                    </div> */}

                    {/* <Plans/> */}


            
                </div>
    }

    return (
        <div>
            <h1 className="text-primary font-size-h1 mt-3">Mis Planes</h1>
                    <div className="d-flex align-items-top">
                        <span className='font-size-h5 text-dark-50'>Crea los planes en venta de tu gimnasio, podrás asignarlos a tus clientes.</span>
                    </div>

            <div className="h-100">
                <Layout39 title="" left={renderSidebar()} right={renderComponent()}/>
            </div>
        </div>
    );
};

export default BlankPage7;
