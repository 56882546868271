import React from "react";

const Checkbox = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <label className="col-form-label py-1 checkbox">
      <input
        checked={field.value}
        type="checkbox"
        {...field}
        {...props}
      />
      <span />

      <div className="ml-2">{props.label}</div>
      {touched[field.name] && errors[field.name] && (
        <div className="error">{errors[field.name]}</div>
      )}
    </label>
  );
};

export default Checkbox;
