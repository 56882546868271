import React, {useState} from 'react';
import Select from "react-select";
import SalesReports from "./Sales";
import InventoryReport from "./Inventory";
import SchedulesCoaches from "./SchedulesCoaches";
import MemberRankings from "./MemberRankings";

import TabNavigator from "../../components/TabNavigator";

import MemberRankingsReport from "./MemberRankings/index";
import SalesReport from "./Sales/index";
import SchedulesCoachesReport from "./SchedulesCoaches/index";
import PlanSalesTable from "../Reports2/PlanSales";
import IncomeAndExpenses from "../Reports2/IncomeExpenses";
import ActiveMembers from "../Reports2/ActiveMembers";
import PlanSales from "../Reports2/PlanSales";







enum ReportViewOptions {Sales, IncomeExpense, PlanSales, ActiveMembers, Inventory, SchedulesCoaches, MemberRankings}

const Reports = () => {

    const [reportType, setReportType] = useState({value: 0, label: "Ventas"});

    const renderView = () => {
        switch (reportType.value) {
            case ReportViewOptions.Sales:
                return <SalesReports/>
            case ReportViewOptions.PlanSales:
                return <PlanSalesTable />
            case ReportViewOptions.IncomeExpense:
                return <IncomeAndExpenses/>
            case ReportViewOptions.ActiveMembers:
                return <ActiveMembers />
            case ReportViewOptions.Inventory:
                return <InventoryReport />
            case ReportViewOptions.SchedulesCoaches:
                return <SchedulesCoaches />
            case ReportViewOptions.MemberRankings:
                return <MemberRankings />
            default:
                return <SalesReports/>
        }
    }

    return (
        <div className="h-100 on-reports on-finish mt-1">
            <div className="">
                <div className="d-flex align-items-center">
                    
                    {/* <span style={{width: "200px"}}>
                        <Select value={reportType}
                                options={[
                                    {value: ReportViewOptions.Sales, label: "Ventas"},
                                    // {value: 1, label: "Ingresos y Gastos"},
                                    {value: ReportViewOptions.SchedulesCoaches, label: "Horarios y Coaches"},
                                    {value: ReportViewOptions.PlanSales, label: "Ventas por plan"},
                                    {value: ReportViewOptions.ActiveMembers, label: "Clientes activos"},
                                    {value: ReportViewOptions.Inventory, label: "Movimientos de Inventario"},
                                    {value: ReportViewOptions.MemberRankings, label: "Ranking clientes"},
                                ]}
                                onChange={(val) => {
                                    if (val !== null) {
                                        setReportType(val)
                                    }
                                }}/>
                    </span> */}
                </div>
            </div>

            {/* oculto */}
                            {/* <div className="pb-15"/>

                            <div className='mt-5'>
                                <TabNavigator tabLabels={["Ventas", "👤 Clientes Activos", "🏆 Ranking Clientes", "🚴 Coaches", "💳 Planes", "📦 Inventario"]} panels={
                                    [
                                        <SalesReport/>,
                                        <ActiveMembers/>,
                                        <MemberRankingsReport/>,
                                        <SchedulesCoachesReport/>,
                                        <PlanSales/>,
                                        <InventoryReport/>
                                    ]

                                }/>

                            </div>

                            <div className="d-flex align-items-center">
                                    
                                    <span style={{width: "300px"}}>
                                        <Select value={reportType}
                                                options={[
                                                    {value: 0, label: "Ventas"},
                                                    // {value: 1, label: "Ingresos y Gastos"},
                                                    {value: 2, label: "Ventas por plan"},
                                                    {value: 3, label: "Clientes activos"},
                                                    {value: 4, label: "Movimientos de Inventario"},
                                                ]}
                                                onChange={(val) => {
                                                    if (val !== null) {
                                                        setReportType(val)
                                                    }
                                                }}/>
                                    </span>
                                </div> */}
                {/* OCUTLO */}


            {/* {renderView()} */}
        </div>
    )

};

export default Reports;