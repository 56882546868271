import React from 'react';
import {Col, Form} from 'react-bootstrap';
import {Field} from 'formik';

type FormFieldProps = {
    label: string,
    name: string
    type?: string,
    disabled?: boolean,
    hidden?: boolean,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    min?: number
    max?: number
    autoComplete?: string
    component?: string
}

const FormField = ({label, name, autoComplete, onChange, min, max, hidden = false, disabled = false, type = "text", component = "input"}: FormFieldProps) => {
    return (
        <Form.Group hidden={hidden} className="">
            <Form.Label className="">{label}</Form.Label>
            <div className="">
                <Field component={component} autoComplete={autoComplete} min={min} max={max} className="form-control" type={type} name={name} disabled={disabled}/>
            </div>
        </Form.Group>
    );
};

export default FormField;
