import React, {useState} from 'react';
import Layout39 from "../../layouts/Layout-3-9";

import "../../assets/styles/personalization.css"
import StudioClasses from "./StudioClasses";
import Branches from "./Branches";
import Rooms from "./Rooms";
import Plans from "./Plans";
import Settings from "./Settings";
import Staff from "./Staff";
import PaymentProcessors from "./PaymentProcessors";
import {
    faCogs,
    faCreditCard,
    faIdBadge,
    faIdCard, faKaaba, faMountain,
    faSitemap, faTags, faTicketAlt,
} from "@fortawesome/free-solid-svg-icons";
import ToolboxSidebarItem from "./SidebarItem";
import Coupons from "./Coupons";
import Coaches from "../Coaches";


enum ToolboxOption {Branches, Classes, Rsvp, Rooms, Plans, Settings, Staff, PaymentProcessors, Coupons, Coaches}

const Toolbox = () => {

    const [selectedOption, setSelectedOption] = useState(ToolboxOption.Branches)

    const renderComponent = (): JSX.Element => {

        switch (selectedOption) {
            case ToolboxOption.Branches:
                return <Branches/>
            case ToolboxOption.Classes:
                return <StudioClasses/>
            case ToolboxOption.Rooms:
                return <Rooms/>
            case ToolboxOption.Plans:
                return <Plans/>
            case ToolboxOption.Coupons:
                return <Coupons />
            case ToolboxOption.Settings:
                return <Settings/>
            case ToolboxOption.Staff:
                return <Staff/>
            case ToolboxOption.PaymentProcessors:
                return <PaymentProcessors/>
            case ToolboxOption.Coaches:
                return <Coaches/>
            default:
                return <div>Error</div>
        }
    }


    // Empieza aqui///////////////
    // Empieza aqui///////////////
    // Empieza aqui///////////////

    const renderSidebar = () => {
        return <div className="">
            <h1 className="text-primary font-size-h1">Personalización</h1>
            <div className="d-flex align-items-top">
                <span className="label label-inline label-success font-weight-bolder px-1 ml-1 mr-2">Nuevo</span>
                <span className='font-size-lg'><strong>Pagos en línea</strong>, configura tu pasarela de pagos para comenzar a vender tus planes a través de la app.</span>
            </div>


            {/* <div className="card-header">
                <h2>Personalización</h2>
            </div> */}



            <div className="navi navi-hover navi-active d-flex flex-row align-items-stretch flex-wrap py-5 px-0">

                {/* <p className="text-muted font-size-h3 mb-2 mt-5 px-5 font-weight-light">Mi Estudio</p> */}

                <ToolboxSidebarItem text="Información General" onClick={() => setSelectedOption(ToolboxOption.Branches)}
                                    active={selectedOption === ToolboxOption.Branches} icon={faKaaba}/>
                <ToolboxSidebarItem text="Mis Clases" onClick={() => setSelectedOption(ToolboxOption.Classes)}
                                    active={selectedOption === ToolboxOption.Classes} icon={faMountain}/>

<ToolboxSidebarItem text="Instructores" onClick={() => setSelectedOption(ToolboxOption.Coaches)}
                                    active={selectedOption === ToolboxOption.Coaches} icon={faMountain}/>


                {/* SALONES */}
                                {/* <ToolboxSidebarItem text="Mis Salones" onClick={() => setSelectedOption(ToolboxOption.Rooms)}
                                    active={selectedOption === ToolboxOption.Rooms} icon={faSitemap}/> */}



                {/* <p className="text-muted font-size-h3 mb-2 mt-5 px-5 font-weight-light">Planes</p> */}

                {/* PAQUETES Y MEMBRESIAS CREAR */}
                                    {/* <ToolboxSidebarItem text="Mis Paquetes y Membresías"
                                                        onClick={() => setSelectedOption(ToolboxOption.Plans)}
                                                        active={selectedOption === ToolboxOption.Plans} icon={faIdCard}/> */}


                {/* <p className="text-muted font-size-h3 mb-2 mt-5 px-5 font-weight-light">Usuarios</p> */}

                <ToolboxSidebarItem text="Cuentas Staff" onClick={() => setSelectedOption(ToolboxOption.Staff)}
                                    active={selectedOption === ToolboxOption.Plans} icon={faIdCard}/>

                {/* CUPONES */}
                                {/* <ToolboxSidebarItem text="Cupones"
                                                    onClick={() => setSelectedOption(ToolboxOption.Coupons)}
                                                    active={selectedOption === ToolboxOption.Coupons} icon={faTicketAlt}/> */}



                {/* <p className="text-muted font-size-h3 mb-2 mt-5 px-5 font-weight-light">Ajustes</p> */}

                <ToolboxSidebarItem text="Cancelaciones" onClick={() => setSelectedOption(ToolboxOption.Settings)}
                    active={selectedOption === ToolboxOption.Settings} icon={faCogs}/>
                <ToolboxSidebarItem text="Pagos en línea"
                                    onClick={() => setSelectedOption(ToolboxOption.PaymentProcessors)}
                                    active={selectedOption === ToolboxOption.PaymentProcessors} icon={faCreditCard}/>

                {/* PAGINA WEB */}
                            {/* <ToolboxSidebarItem text="Mi Página Web"
                                                onClick={() => setSelectedOption(ToolboxOption.PaymentProcessors)}
                                                active={selectedOption === ToolboxOption.PaymentProcessors} icon={faCreditCard}/> */}

            </div>
        </div>
    }

    return (
        <div className="h-100">
            <Layout39 title="" left={renderSidebar()} right={renderComponent()}/>
        </div>
    );
};

export default Toolbox;
