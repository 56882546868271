import React from "react";
import {FieldProps} from "formik";
import {OptionsType, ValueType} from "react-select";
import CreatableSelect from "react-select/creatable";
import baseApi from "../../apis/baseApi";
import {addClass, StudioClass} from "../../actions/studioClasses";
import {useDispatch} from "react-redux";

interface Option {
    label: string;
    value: string;
}

interface FormikSelectProps extends FieldProps {
    options: OptionsType<Option>;
    onChange?: (option: ValueType<Option | Option[], boolean>) => void,
    isClearable?: boolean,
    isDisabled?: boolean
}

export const StudioClassSelect =
    ({
         field,
         form,
         options,
         onChange = () => {
         },
         isDisabled = false,
         isClearable,

     }: FormikSelectProps) => {
        const dispatch = useDispatch()

        const handleChange = (option: ValueType<Option, boolean>) => {
            if (option) {
                form.setFieldValue(field.name, (option as Option).value);
            } else {
                form.setFieldValue(field.name, null)
            }
            onChange(option)
        };

        const getValue = () => {
            if (options) {
                return options.find(option => option.value === field.value);
            } else {
                return ("" as any);
            }
        };

        const handleCreate = (inputValue: any) => {
            baseApi.post<StudioClass>("/studios/classes/", {name: inputValue, color: "#000000"}).then((resp) => {
                dispatch(addClass(resp.data))
                form.setFieldValue(field.name, resp.data.id)
            })
        }

        return (
            <CreatableSelect
                isDisabled={isDisabled}
                name={field.name}
                value={getValue()}
                onChange={handleChange}
                options={options}
                onCreateOption={handleCreate}
                isClearable={isClearable}
                formatCreateLabel={(value) => "Crear clase: " + value}
            />
        );
    };