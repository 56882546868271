import {StudioActionTypes} from "./types";

export interface Studio {
    id: string
    active: boolean,
    name: string
    image: string
    instagram: string
    facebook: string
    whatsapp: string
    product_id: string | null
    preferences: StudioPreferences
}

export interface StudioPreferences {
    automatic_waitlist: boolean;
    currency: string;
    hours_to_cancellation: number;
    payment_processor: string | null;
    paypal: boolean;
    public: string | null;
    shoe_size_country: string;
    uses_shoe_field: boolean;
    uses_last_name: boolean;
}


export type StudioActions = {
    type: StudioActionTypes,
    payload: Studio
}

export const setStudio = (studio: Studio) => {
    return {
        type: StudioActionTypes.RETRIEVED_STUDIO,
        payload: studio
    }
}