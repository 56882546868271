import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm} from "formik";
import baseApi from "../../apis/baseApi";
import {AxiosResponse} from 'axios';
import {getApiErrorMessage} from "../../utils/apiErrors";

type Props = {
    url: string
    onSuccess: (resp: AxiosResponse<any>) => void
    children?: JSX.Element | JSX.Element[]
    show: boolean,
    onHide: Function
    text?: string
    successText?: string
}

const CreateScheduleModal = ({show, onHide, onSuccess, url, children, text, successText}: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("")

    const renderAlert = () => {
        if (success === null) return <React.Fragment/>;
        return <Alert variant={success ? "success" : "danger"}>{alertMsg}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };
    return (
        <React.Fragment>
            <Modal size="sm" show={show} onHide={handleClose}>
                <Formik initialValues={{}} onSubmit={(values, {setSubmitting}) => {
                    setSuccess(null)
                    setAlertMsg("")
                    baseApi.delete(url).then((resp) => {
                        setSuccess(true)
                        setAlertMsg(successText ?? "Eliminado")
                        onSuccess(resp)
                    }).catch((err) => {
                        setAlertMsg(getApiErrorMessage(err))
                        setSuccess(false)
                        setSubmitting(false)
                    })
                }}>
                    {({isSubmitting}) => (
                        <FormikForm>
                            <Modal.Header>
                                {text ?? 'Eliminar'}
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                {children}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Atrás
                                </Button>
                                <Button variant="light-danger font-weight-bolder" type="submit" disabled={isSubmitting}>
                                    {text ?? 'Eliminar'}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateScheduleModal;
