import React, {useEffect, useState} from 'react';
import baseApi from "../../../apis/baseApi";
import ToolboxHeader from "../ToolboxHeader";
import MiniFormField from "../../../components/form/MiniFormField";
import {Formik, Form as FormikForm, Field} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faClock,
    faDollarSign,
    faMoneyBillWave,
    faSave,
    faShoePrints,
    faUserTimes,
    faLongArrowAltLeft
} from '@fortawesome/free-solid-svg-icons';
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {Alert} from "react-bootstrap";
import {FormikSelect} from "../../../components/form/FormikSelect";
import Checkbox from "../../../components/form/Checkbox";
import FieldError from "../../../components/form/FieldError";

export interface Penalizations {
    id: number,
    no_assistance_count_to_penalize_credits: number,
    "no_assistance_days_range_credits": number,
    "cash_amount": number,
    "no_assistance_count_to_penalize_membership": number,
    "no_assistance_days_range_membership": number,
}

export interface Preferences {
    id: number;
    hours_to_cancellation: number;
    penalizations: Penalizations;
    public: string | null
    payment_processor: string | null;
    paypal: boolean;
    shoe_size_country: string;
    uses_shoe_field: boolean;
    currency: string;
    automatic_waitlist: boolean;
    privacy_policy: string;
    terms_and_conditions: string;
    allow_in_shop_payment: boolean;
}

const Settings = () => {
    const [loading, setLoading] = useState(true)
    const [preferences, setPreferences] = useState<Preferences>()
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("");

    useEffect(() => {
        baseApi.get<Preferences>("/prefs/").then((resp) => {
            setPreferences(resp.data)
            setLoading(false)
        })
    }, [])

    const renderAlert = () => {
        if (success === null) return <React.Fragment/>
        return <Alert className="mb-5" variant={success ? "success" : "danger"}>{alertMsg}</Alert>
    }

    if (loading) return <div>
        
        <div className='spinner spinner-primary  mx-auto m-15 w-100px p-20'></div>
    </div>
    return (
        <div style={{overflowY: "auto"}} className="p-4">

{/* <button className='btn btn-link-primary mb-3'><FontAwesomeIcon icon={faLongArrowAltLeft} size="lg" className='mr-3' />Configuración</button> */}
            <div className=" align-items-center mb-5">
                <h2 className="pt-2 font-size-h2 font-weight-bolder">Cancelación de Reservas (Clientes)</h2>
                <div className="d-md-flex ">
                    <p className="font-size-lg text-dark-50 d-block">Define con cuanto tiempo deben cancelar los clientes su reservación para que se bonifiqué su clase (aplica para paquetes de clases)</p>
                </div>

            </div>

            <hr />


            {/* <ToolboxHeader title="Tiempo de cancelaciones"/> */}

            {/* <div className="my-10 border-bottom">
                <h2 className="ml-10">Tiempo de cancelaciones</h2>
                <div  className="ml-10 text-muted pb-5 font-size-lg font-weight-bold">Define el tiempo previo con el que deben cancelar sus reservaciones los clientes.</div>
            </div> */}

            <div className="col-12 col-md-6 p-5">
                {renderAlert()}
                <Formik initialValues={{
                    hours_to_cancellation: preferences?.hours_to_cancellation,
                    shoe_size_country: preferences?.shoe_size_country
                }}
                        onSubmit={(values, {setSubmitting}) => {
                            setSuccess(null)
                            baseApi.patch("/prefs/", values).then((resp) => {
                                setAlertMsg("Tiempo de cancelaciones actualizado correctamente.")
                                setSuccess(true)
                                setSubmitting(false)
                            }).catch((e) => {
                                setAlertMsg(getApiErrorMessage(e))
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        }}>
                    {({isSubmitting}) => (
                        <FormikForm>
                            {/* <h5>
                                <i className="text-dark mr-2">
                                    <FontAwesomeIcon icon={faUserTimes}/>
                                </i>
                                Define el tiempo previo con el que deben cancelar su reservacion los clientes.</h5> */}
                                <div className='ml-5 font-size-h6 font-weight-bolder text-primary'>Horas máximos antes de la reservación para cancelarla</div>

                            <div className="form-group row ml-5 align-items-center font-size-lg">
                                {/* <label className="col-auto col-form-label font-size-h6">
                                    Los clientes deberan cancelar con
                                </label> */}
                                <MiniFormField name="hours_to_cancellation" type="number"/>
                                <label className="col-auto col-form-label font-size-h6 ml-n4">
                                    <span className="font-weight-bolder">horas</span> antes de la reservación para que la clase sea devuelta a su paquete.
                                </label>
                                <div className="text-muted mt-n1">Las cancelaciones realizadas <span className='font-weight-bolder text-dark'>después</span> del tiempo permitido <span className='font-weight-bolder text-dark'>NO</span> recibirán su clase devuelta para utilizarla en otro horario.</div>
                            </div>
                            {/* <h5>
                                <i className="text-dark mr-2">
                                    <FontAwesomeIcon icon={faShoePrints} rotation={270}/>
                                </i>
                                País talla de zapatos</h5>
                            <div className="ml-5" style={{width: "300px"}}>
            <ToolboxHeader title="Preferencias"/>
            {renderAlert()}
            <Formik initialValues={{
                hours_to_cancellation: preferences?.hours_to_cancellation,
                shoe_size_country: preferences?.shoe_size_country,
                currency: preferences?.currency,
                uses_shoe_field: preferences?.uses_shoe_field,
                automatic_waitlist: preferences?.automatic_waitlist,
                terms_and_conditions: preferences?.terms_and_conditions,
                allow_in_shop_payment: preferences?.allow_in_shop_payment,
                privacy_policy: preferences?.privacy_policy,
            }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        baseApi.patch("/prefs/", values).then((resp) => {
                            setAlertMsg("Preferencias actualizadas correctamente")
                            setSuccess(true)
                            setSubmitting(false)
                        }).catch((e) => {
                            setAlertMsg(getApiErrorMessage(e))
                            setSuccess(false)
                            setSubmitting(false)
                        })
                    }}>
                {({isSubmitting}) => (
                    <FormikForm>
                        <div className="">
                            <h5><i className="text-dark mr-2"><FontAwesomeIcon icon={faDollarSign}/>
                            </i>Divisa</h5>
                            <div className="col-12 text-muted mt-n2 font-size-sm">*Esta es la divisa que se usara para
                                realizar cobros de paquetes y membresías
                            </div>
                            <div className="ml-5" style={{width: "300px"}}>
                                <Field name="currency" component={FormikSelect}
                                       options={[
                                           {value: "ars", label: "Argentina - ARS"},
                                           {value: "cad", label: "Canada - CAD"},
                                           {value: "cop", label: "Colombia - COP"},
                                           {value: "eur", label: "Euro - €"},
                                           {value: "hnl", label: "Honduras - HNL"},
                                           {value: "mxn", label: "México - MXN"},
                                           {value: "pen", label: "Perú - SOL"},
                                           {value: "usd", label: "United States - USD"},
                                       ]}/>
                            </div>

                        </div>
                        <h5 className="mt-5">
                            <i className="text-dark mr-2">
                                <FontAwesomeIcon icon={faUserTimes}/>
                            </i>
                            Cancelaciones</h5>
                        <div className="form-group row ml-5 align-items-center">
                            <label className="col-auto col-form-label font-size-lg">
                                Los usuarios cuentan con
                            </label>
                            <MiniFormField name="hours_to_cancellation" type="number"/>

                            <label className="col-auto col-form-label font-size-lg ml-n4">
                                horas antes de su reservación para cancelarla
                            </label>
                            <div className="col-12 text-muted mt-n2 font-size-sm">Crédito sera devuelto</div>
                        </div>
                        <div className="mb-5">
                            <h5>
                                <i className="text-dark mr-2">
                                    <FontAwesomeIcon icon={faClock}/>
                                </i>
                                Waitlist: Reservación automática</h5>
                            <div className="ml-5">
                                <Field name="automatic_waitlist" component={Checkbox}
                                       label="Al momento de liberarse un espacio, automáticamente se crea una reservación al primer usuario en la lista de espera."/>
                            </div>
                        </div>
                        <h5>
                            <i className="text-dark mr-2">
                                <FontAwesomeIcon icon={faShoePrints} rotation={270}/>
                            </i>
                            Zapatos</h5>
                        <div className="ml-5">
                            <Field name="uses_shoe_field" component={Checkbox}
                                   label="Mostrar campo talla de zapatos en registro de usuario"/>
                        </div>
                        <div className="ml-5 d-inline-flex align-content-center">
                            <div className="align-self-center mr-3">- País</div>
                            <div className="" style={{width: "300px"}}>
                                <Field name="shoe_size_country" component={FormikSelect}
                                       options={[{value: "MX", label: "México"}, {value: "US", label: "Estados Unidos"}]}/>
                            </div> */}
                            {/*<h5>*/}
                            {/*    <i className="text-dark mr-2">*/}
                            {/*        <FontAwesomeIcon icon={faUserClock}/>*/}
                            {/*    </i>*/}
                            {/*    Lista de Espera*/}
                            {/*</h5>*/}
                            {/*<div className="form-group row ml-5">*/}
                            {/*    <label className="col-auto col-form-label font-size-lg">Permitir lista de espera en*/}
                            {/*        horarios</label>*/}
                            {/*    <div className="col-auto ml-n4">*/}
                            {/*      <span className="switch">*/}
                            {/*        <label>*/}
                            {/*          <input type="checkbox" name=""/>*/}
                            {/*          <span/>*/}
                            {/*        </label>*/}
                            {/*      </span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <hr/>
                            <button className="btn btn-primary font-weight-bolder mt-5 ">
                                Guardar
                            </button>
                        </FormikForm>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default Settings;
