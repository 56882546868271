import {StaffUser, StaffUserActions} from "../actions/staffUser";
import {StaffActionTypes} from "../actions";

const initialState = {
    id: "",
    sms_verified: false,
    user: "",
    is_staff_of: [],
    permissions: [],
    show_onboarding: false,
    loading: true,
    archived: false,
} as StaffUser

export const staffUserReducer = (state = initialState, action: StaffUserActions) => {
    switch (action.type) {
        case StaffActionTypes.FETCHED:
            return {...action.payload, loading: false}
        default:
            return state
    }
}