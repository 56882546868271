import axios from "axios";
import {isTokenExpired, refreshAccess} from "../utils/jwt_utils";



// const client = axios.create({
//     baseURL: process.env.NODE_ENV === "production" ? "https://boostapp.top" : "http://192.168.1.143:8001",
// })
const client = axios.create({
    baseURL:  "https://api3.safegymapp.xyz" ,
})

client.interceptors.request.use(async request => {
    const access = localStorage.getItem("access")
    if (isTokenExpired(access ?? "")) {
        await refreshAccess()
    }
    request.headers["Authorization"] = "Bearer " + localStorage.getItem("access")
    return request
})

export default client
