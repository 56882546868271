import React, {useState} from 'react';
import Separator from "../../../components/misc/Separator";
import {
    faAddressCard,
    faBirthdayCake,
    faCalendarDay, faPhone,
    faIdCard,
    faPhoneAlt,
    faShoePrints,
    faStore,
} from "@fortawesome/free-solid-svg-icons";
import IconText from "../../../components/misc/IconText";
import {Member} from "../index";
import {formatDate} from "../../../utils/dateFormatters";
import ImageField2 from "../../../components/form/ImageField2";
import baseApi from "../../../apis/baseApi";
import {Button} from 'react-bootstrap';
import EditMemberModal from "./EditMemberModal";
import DeleteModal from "../../../components/modals/DeleteModal";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faSyncAlt, faSitemap, faWalking, faTrashAlt, faStar} from "@fortawesome/free-solid-svg-icons";
import {faClock, faCalendar, faUser} from "@fortawesome/free-regular-svg-icons";

type MemberProfileProps = {
    member: Member
    setMember: (member: Member) => void
}

const MemberProfile = ({member, setMember}: MemberProfileProps) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    return (
        <div>
            <EditMemberModal member={member} setMember={setMember} show={showEditModal} onHide={() => {
                setShowEditModal(false)
            }}/>
            <DeleteModal url={`/members/update/${member.id}/`} onSuccess={() => {
            }} show={showDeleteModal} onHide={() => {
                setShowDeleteModal(false)
            }}>
                <div className="h3">
                    <div className='bg-light-danger p-3 text-center'>Eliminar cliente</div>
                    <p className="font-weight-bold p-0 m-0 font-size-h5 mt-5 text-center">{member.full_name}</p>
                    <p className="font-size-sm text-center">{member.user.email}</p>
                </div>
                <p className="font-size-lg text-danger font-weight-bold mt-10">Los datos del cliente serán
                    eliminados</p>
                <p className="font-size-lg text-danger font-weight-bold mt-10">Esta acción es <u>irreversible</u></p>

            </DeleteModal>

            {/* EMPIEZA CARD*/}
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 border p-5 mb-5 ml-n4">
                <div className="d-flex justify-content-between">
                    <div className="font-weight-bolder h1 text-primary mb-4">{member.full_name}</div>
                    <div className='d-none d-sm-flex'>
                        <Button className='btn btn-link bg-transparent border-0' onClick={() => setShowEditModal(true)}><u>Editar
                            info</u></Button>
                        <Button className="text-danger" onClick={() => {
                            setShowDeleteModal(true)
                        }} size="sm" variant="">
                            <div className="d-flex">
                                <FontAwesomeIcon icon={faTrashAlt}/>
                                <div className=" pl-2 font-weight-bold">Eliminar usuario</div>
                            </div>
                        </Button>
                    </div>
                </div>

                <div className="d-block d-xl-flex">

                    {/* IMAGEN */}
                        {/* <div className="">
                            <div className="symbol symbol-50 symbol-md-70 symbol-xxl-70  symbol-inline mx-auto">
                                <ImageField2 src={member.img} onChange={file => {
                                    const formData = new FormData();
                                    formData.append("img", file, file.name);
                                    baseApi.patch(`/members/update/${member.id}/`, formData).then()

                                }}/>
                            </div>
                        </div> */}
                    {/* FIN IMAGEN */}



                    <div className="text-left ">
                        <div className="font-size-lg mt-2">{member.user.email}</div>
                        <div className="font-size-lg">Número de cliente: {member.external_id}</div>
                        <div className="font-size-lg">{member.phone}</div>
                        <div>Cumpleaños: {formatDate(member.birthday)}</div>
                        <div>Fecha de registro: {formatDate(member.created_on)}</div>
                    </div>
                </div>
                {/* BOTONES MOBILE EDIT/DELETE */}
                <div className='d-flex d-sm-none'>
                    <Button className='btn btn-link bg-transparent border-0' onClick={() => setShowEditModal(true)}><u>Editar
                        info</u></Button>
                    <Button className="text-danger" onClick={() => {
                        setShowDeleteModal(true)
                    }} size="sm" variant="">
                        <div className="d-flex">
                            <FontAwesomeIcon icon={faTrashAlt}/>
                            <div className=" pl-2 font-weight-bold">Eliminar usuario</div>
                        </div>
                    </Button>
                </div>
            </div>
            {/* FIN CARD */}


            {/* TOTAL CREDITOS */}
            <div className='font-size-h5 col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 text-center'>
                <FontAwesomeIcon icon={faStar} size="sm" className='mr-1 text-success'/>
                Total créditos vigentes:
                            <div className='text-success ml-2 font-weight-bolder display4'>{member.current_credits}</div>

            </div>
            {/* <p className='font-size-h5'>
                <FontAwesomeIcon icon={faIdCard} size="sm" className='mr-1 text-primary'/>
                Membresía vencimiento:<span
                className='text-primary ml-2 font-weight-bold'>{member.current_credits}</span>
            </p> */}
        </div>
    )
}

export default MemberProfile
