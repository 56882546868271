import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


type ToolboxHeaderProps = {
    title: string
    action?: () => void
    action2?: () => void
}

const ToolboxHeader = ({title, action, action2}: ToolboxHeaderProps) => {
    return (
        <div className="  border-bottom  px-1  border-0 justify-content-between">
            {/* <div className="">
                <h3 className="card-label h2">{title}
                    <span className="text-muted pt-2 font-size-sm d-block"></span>
                </h3>
            </div> */}
            <div className="d-block">
                {action ?
                    <button onClick={action} className="btn btn-success font-weight-bold font-size-h6 font-weight-bolder" >+ Nuevo</button>
                    : <React.Fragment/>
                }
                {action2 ?
                    <button onClick={action2} className="btn btn-success font-weight-bold font-size-h6 font-weight-bolder">+ Nuevo</button>
                    : <React.Fragment/>
                }
            </div>
        </div>
    );
};

export default ToolboxHeader;


