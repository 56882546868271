import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen, faTag, faTrashAlt, faEdit, faLongArrowAltLeft, faCog, faWarehouse, faRunning,faNetworkWired, faCreditCard, faUser, faAngleRight} from "@fortawesome/free-solid-svg-icons";

const BlankPage1 = () => {
    return (
        <div>
            {/* <h1>Configuración</h1>
            <div className="row">
                <div className="col-12 col-md-3 col-lg-3 col-xl-2 mb-3">
                    <button  className="btn btn-light-primary btn-block px-5 py-5 py-md-20 text-md-center text-left">
                        <div className="d-flex d-md-block">
                            <div><FontAwesomeIcon icon={faWarehouse} size="2x"/></div>
                            <div className='font-size-h5 font-weight-bold mt-2 ml-md-0 ml-2'>Configuración General</div>
                            <div className='align-self-center ml-3 mt-2 d-md-none'><FontAwesomeIcon icon={faAngleRight} size="1x"/></div>
                        </div>
                        <div className='text-dark-50'>Configura el nombre de tu gimnasio, logo y otros aspectos.</div>
                    </button>
                </div>

                <div className="col-6 col-md-3 col-lg-3 col-xl-2 mb-3">
                    <button className="btn btn-light-primary btn-block px-5 py-20">
                        <div><FontAwesomeIcon icon={faRunning} size="2x"/></div>
                        <div className='font-size-h5 font-weight-bold mt-2'>Mis Disciplinas</div>
                        <div className='text-dark-50'>Crea las disciplinas que impartes en tu gimnasio</div>
                    </button>
                </div>

                <div className="col-6 col-md-3 col-lg-3 col-xl-2 mb-3">
                    <button className="btn btn-light-primary btn-block px-5 py-20">
                        <div><FontAwesomeIcon icon={faRunning} size="2x"/></div>
                        <div className='font-size-h5 font-weight-bold mt-2'>Instructores</div>
                        <div className='text-dark-50'>Crea las disciplinas que impartes en tu gimnasio</div>
                    </button>
                </div>

                <div className="col-6 col-md-3 col-lg-3 col-xl-2 mb-3">
                    <button className="btn btn-light-primary btn-block px-5 py-20">
                        <div><FontAwesomeIcon icon={faNetworkWired} size="2x"/></div>
                        <div className='font-size-h5 font-weight-bold mt-2'>Mis Salones</div>
                        <div className='text-dark-50'>gggg</div>
                    </button>
                </div>

                <div className="col-6 col-md-3 col-lg-3 col-xl-2 mb-3">
                    <button className="btn btn-light-primary btn-block px-5 py-20 min-h-250px max-h-250px">
                        <div><FontAwesomeIcon icon={faCreditCard} size="2x"/></div>
                        <div className='font-size-h5 font-weight-bold mt-2'>Pasarela de pagos en línea</div>
                        <div className='text-dark-50'>Agrega una pasarela de pagos para recibir pagos en línea</div>
                    </button>
                </div>

                <div className="col-6 col-md-3 col-lg-3 col-xl-2 mb-3">
                    <button className="btn btn-light-primary btn-block px-5 py-20 min-h-250px max-h-250px">
                        <div><FontAwesomeIcon icon={faUser} size="2x"/></div>
                        <div className='font-size-h5 font-weight-bold mt-2'>Staff</div>
                        <div className='text-dark-50'>Crea los usuarios y permisos de tu staff.</div>
                    </button>
                </div>

                <div className="col-6 col-md-3 col-lg-3 col-xl-2 mb-3">
                    <button className="btn btn-light-primary btn-block px-5 py-20 min-h-250px max-h-250px">
                        <div><FontAwesomeIcon icon={faUser} size="2x"/></div>
                        <div className='font-size-h5 font-weight-bold mt-2'>Configuración Reservaciones</div>
                        <div className='text-dark-50'>Crea los usuarios y permisos de tu staff.</div>
                    </button>
                </div>

                <div className="col-6 col-md-3 col-lg-3 col-xl-2 mb-3">
                    <button className="btn btn-light-primary btn-block px-5 py-20 min-h-250px max-h-250px">
                        <div><FontAwesomeIcon icon={faUser} size="2x"/></div>
                        <div className='font-size-h5 font-weight-bold mt-2'>Suscripción</div>
                        <div className='text-dark-50'>Suscripcion Safe Gym</div>
                    </button>
                </div>

                <div className="col-6 col-md-3 col-lg-3 col-xl-2 mb-3">
                    <button className="btn btn-light-warning btn-block px-5 py-20 min-h-250px max-h-250px">
                        <div><FontAwesomeIcon icon={faUser} size="2x"/></div>
                        <div className='font-size-h5 font-weight-bold mt-2'>Primeros Pasos</div>
                        <div className='text-dark-50'>Guía de Ayuda</div>
                    </button>
                </div>

                <div className="col-6 col-md-3 col-lg-3 col-xl-2 mb-3">
                    <button className="btn btn-light-success btn-block px-5 py-20 min-h-250px max-h-250px">
                        <div><FontAwesomeIcon icon={faUser} size="2x"/></div>
                        <div className='font-size-h5 font-weight-bold mt-2'>Contactar Soporte y atencion a clientes</div>
                        <div className='text-dark-50'>Guía de Ayuda</div>
                    </button>
                </div>
            </div> */}
        </div>
    );
};

export default BlankPage1;
