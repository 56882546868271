import React from 'react';

const TableLoadingTr = () => {
    return (
        <tr className="text-center w-100">
            <td colSpan={7}>
                    <div className='spinner spinner-primary  mx-auto m-15 w-100px p-20'></div>
            </td>
        </tr>
    );
};

export default TableLoadingTr;
