import React, {useState, useEffect} from 'react';
import Select from "react-select";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTag, faTimes, faTrash, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Formik, Form, Field} from "formik";
import {paymentMethods} from "../../data/paymentMethods";
import baseApi from "../../apis/baseApi";
import {Alert, Button} from "react-bootstrap";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";
import yup from "../../utils/yup";
import FieldError from "../../components/form/FieldError";
import {PlanSaleItem, ProductSaleItem} from "./index";
import ReactDatePicker from "react-datepicker";
import {format, formatISO, isAfter, isBefore, isToday, parseISO} from "date-fns";
import {getApiErrorMessage} from "../../utils/apiErrors";
import {currencyFormat} from "../../utils/currencyFormatters";
import {StudioPlan} from "../../actions/studioPlans";
import FormField from "../../components/form/FormField";
import {AxiosInstance, AxiosResponse} from "axios";

export type CartMember = {
    id: number
    email: string
    full_name: string
    external_id: string
    last_name?: string
}

type PosCartProps = {
    cartPlans: PlanSaleItem[]
    setCartPlans: (plans: PlanSaleItem[]) => void,
    cartProducts: ProductSaleItem[],
    setCartProducts: (prods: ProductSaleItem[]) => void,
}

const PosCart = ({cartPlans, setCartPlans, cartProducts, setCartProducts}: PosCartProps) => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [searchTerm, setSearchTerm] = useState("")
    const [fetchingMembers, setFetchingMembers] = useState(false)
    const [members, setMembers] = useState<CartMember[]>([])
    const [member, setMember] = useState<CartMember>();
    const [success, setSuccess] = useState<boolean | null>(null);
    const [couponCode, setCouponCode] = useState();
    const [couponError, setCouponError] = useState("");
    const [coupon, setCoupon] = useState<ValidCoupon | null>(null);
    const [discountAmount, setDiscountAmount] = useState<number>();

    useEffect(() => {
        baseApi.get(
            `/members/search/?branch=${branch.id}`,
        ).then((response) => {
            setMembers(response.data.results);
            setFetchingMembers(false);
        }).catch();
    }, [])

    useEffect(() => {
        const searchFn = setTimeout(() => {
            if (searchTerm.length < 2) return
            setFetchingMembers(true)
            baseApi.get(
                `/members/search/?search=${searchTerm}&branch=${branch.id}`,
            ).then((response) => {
                setMembers(response.data.results);
                setFetchingMembers(false);
            }).catch();
        }, 800);
        return () => clearTimeout(searchFn);
    }, [searchTerm]);

    const renderPlans = () => {
        if (cartPlans.length === 0) {
            return <div>
                <p className=" font-size-sm text-danger mt-n5">*Debes seleccionar un plan</p>
                {/* <div className="separator separator-solid"/> */}
            </div>

        }
        return cartPlans.map((i, index) => {
            return <div key={index} className="mt-2">
                <div className="row justify-content-around align-items-middle gutter-0 mx-0 border border-primary">

                    {/* PLAN CARD IZQ */}
                    <div className="col-9 pl-3 pb-3" style={{ lineHeight: '8px' }}>
                        {/* <p className="text-muted font-size-xs">SKU: {i.plan.sku}</p> */}
                        <p className="font-weight-bold font-size-h2 mt-7 text-dark mb-6">{i.plan.name}</p>
                        <p>{i.plan.description}</p>

                        <div className="p-0 mt-3">
                            {/* <p className="text-dark-50 font-size-lg mb-n1 pb-1">Empieza:</p> */}
                            <ReactDatePicker
                                onChange={(date: Date) => {
                                    if (date !== null) {
                                        setCartPlans(cartPlans.map(planItem => planItem !== i ? planItem : { ...planItem, activate_on: date }))
                                    }
                                }}
                                customInput={
                                    <div className="clickable">
                                        <span className="font-size-lg mr-1">Empieza: </span>
                                        <span className='font-weight-bolder font-size-lg'>
                                            {isToday(i.activate_on) ? 'Hoy' : format(i.activate_on, "dd MMMM yyyy")}
                                            <span style={{ cursor: "pointer" }} className="text-primary ml-1">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </span>
                                        </span>
                                    </div>
                                }
                            />
                        </div>
                        <p className='mt-2 font-size-xs text-dark-50'>El cliente podrá reservar a partir de esta fecha</p>

                    </div>
                    
                    {/* PLAN CARD DERECHA */}
                    <div className="col-2 text-center p-0 align-self-center" style={{lineHeight: '5px'}}>
                        <p className=" font-size-lg font-weight-bold align-self-center">{currencyFormat.format(i.price)}</p>
                        <Button onClick={() => {
                            setCartPlans(cartPlans.filter(planItem => planItem !== i))
                        }} variant="text-danger p-0" size="sm">
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </div>

                    {/* COLUMNA BOTON ELIMINAR */}
                        {/* <div className="col-1 p-0 align-self-center text-right" style={{lineHeight: '4px'}}>
                            <Button onClick={() => {
                                setCartPlans(cartPlans.filter(planItem => planItem !== i))
                            }} variant="light-danger" size="sm">
                                <FontAwesomeIcon icon={faTrash}/>
                            </Button>
                        </div> */}
                    {/* FIN COLUMNA BOTON ELIMINAR */}


                    {/* <div className="col-4 text-center p-0" style={{lineHeight: '15px'}}>
                        <p className="text-muted font-size-xs">FECHA DE INICIO</p>
                        <ReactDatePicker
                            onChange={(date: Date) => {
                                if (date !== null) {
                                    setCartPlans(cartPlans.map(planItem => planItem !== i ? planItem : {...planItem, activate_on: date}))
                                }
                            }}
                            customInput={
                                <div className="clickable">
                                    <p>
                                        {isToday(i.activate_on) ? 'Hoy' : format(i.activate_on, "dd/MMM/yy")}
                                        <span style={{cursor: "pointer"}} className="text-primary ml-1">
                                            <FontAwesomeIcon icon={faEdit}/>
                                        </span>
                                    </p>
                                </div>
                            }
                        />
                    </div> */}


                    


                </div>


                <div className="separator separator-solid"/>
            </div>
        })
    }

    const renderProducts = () => {
        return cartProducts.map((i, index) => {
            return <div key={index} className="mt-4">

                {/* PRODUCTOS */}
                    {/* <div className="row justify-content-around align-items-start gutter-0">

                        <div className="col-4 p-0" style={{ lineHeight: '4px' }}>
                            <p className="text-muted font-size-xs">Producto {i.product.sku}</p>
                            <p className="font-weight-bold font-size-h6">{i.product.name}</p>
                            <p className="text-muted mt-1">{i.product.description.slice(0, 20)}</p>
                        </div>


                        <div className="col-2 text-center p-0 d-none d-xl-block" style={{ lineHeight: '4px' }}>
                            <p className="text-muted font-size-xs">Cantidad</p>
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <div onClick={() => {
                                    if (i.quantity > 1) {
                                        const items = cartProducts.map(item => item === i ? {
                                            ...item,
                                            quantity: item.quantity - 1
                                        } : item)
                                        setCartProducts(items)
                                    }
                                }
                                } style={{ padding: "0", width: "20px", height: "20px" }}
                                    className="clickable pt-2 text-info font-weight-boldest font-size-lg">-
                                </div>
                                <span className="mx-2 font-size-h6 font-weight-bold">{i.quantity}</span>
                                <div onClick={() => {
                                    const items = cartProducts.map(item => item === i ? {
                                        ...item,
                                        quantity: item.quantity + 1
                                    } : item)
                                    setCartProducts(items)
                                }
                                } style={{ width: "20px", height: "20px" }}
                                    className="text-center clickable  pt-2 text-info font-weight-boldest font-size-lg">
                                    <span className="align-middle">+</span>
                                </div>
                            </div>
                        </div>


                        <div className="col-3 text-center p-0 align-self-center" style={{lineHeight: '4px'}}>
                            <p className="text-muted font-size-xs">PRECIO</p>
                            <p className="align-self-center font-size-lg font-weight-bold">${i.product.price}</p>
                        </div>


                        <div className="col-1 p-0 text-right" style={{lineHeight: '4px'}}>
                            <Button onClick={() => {
                                setCartProducts(cartProducts.filter(cartProd => cartProd !== i))
                            }
                            } variant="light-danger" size="sm">
                                <FontAwesomeIcon icon={faTimes}/>
                            </Button>
                        </div>
                    </div> */}
                {/* FIN PRODUCTOS */}
                <div className="separator separator-solid"/>
            </div>
        })
    }

    const renderAlert = () => {
        if (success === null) return;
        return <Alert
            variant={success ? "success" : "danger"}>{success ? "Venta guardada" : "Intenta de nuevo."}</Alert>;
    };

    const getPlanPrice = (plan: StudioPlan): number => {
        let planPrice = parseFloat(plan.price)
        const promotionActive = plan.promotions.find(p => isAfter(new Date(), parseISO(p.activate_on)) && isBefore(new Date(), parseISO(p.expire_on)))
        if (promotionActive) {
            const promotion = plan.promotions[0]
            if (promotion.is_percent) {
                planPrice = planPrice - (planPrice * (promotion.discount_amount / 100))
            } else {
                planPrice = planPrice - promotion.discount_amount
            }
        }
        return Math.round(planPrice)
    }

    const getTotal = (ignoreDiscount=false): number => {
        const plansTotal = cartPlans.reduce((a, b) => a + getPlanPrice(b.plan), 0)
        const productsTotal = cartProducts.reduce((a, b) => a + (b.price * b.quantity), 0)
        let total = plansTotal + productsTotal
        // if (coupon !== null) {
        //     if (coupon.is_percent) {
        //         total -= coupon.discount_amount
        //     }
        // }
        if (!ignoreDiscount) {
            if (discountAmount !== undefined) {
                total -= discountAmount
            }
        }
        return total
    }

    const validateCoupon = async (): Promise<AxiosResponse<any>> => {
        const total = cartPlans.reduce((a, b) => a + getPlanPrice(b.plan), 0) +
            cartProducts.reduce((a, b) => a + (b.price * b.quantity), 0)
        return await baseApi.post("/coupons/staff-validate/", {
            "coupon_code": couponCode,
            "total": total,
            "member": member?.id
        },)
    }

    const ValidationSchema = yup.object().shape({
        member: yup.string().required("*Debes seleccionar un cliente"),
        payment_method: yup.string().defined("*Selecciona el método de pago").max(50).nullable(),
    })

    return (
        <div className=" h-100 border">
            <Formik
                validationSchema={ValidationSchema}

                initialValues={{
                    plan_items: [],
                    products: [],
                    member: "",
                    payment_method: undefined,
                    location: "CS"
                }}
                onSubmit={(values, {setSubmitting, setFieldValue}) => {
                    setSuccess(null)
                    baseApi.post("/sales/", {
                        ...values,
                        branch: branch.id,
                        plan_items: cartPlans.map((p) => ({
                            ...p,
                            plan: p.plan.id,
                            activate_on: formatISO(p.activate_on),
                            price: getPlanPrice(p.plan),
                            promotion: p.plan.promotions.find(i => isAfter(new Date(), parseISO(i.activate_on)) && isBefore(new Date(), parseISO(i.expire_on)))?.id
                        })),
                        products: cartProducts.map(p => ({...p, product: p.product.id})),
                        pos_discount: discountAmount,
                        total: getTotal()
                    }).then((resp) => {
                        setDiscountAmount(0)
                        setCartPlans([])
                        setCartProducts([])
                        setSuccess(true)
                        setSubmitting(false)

                    }).catch((err) => {
                        setSuccess(false)
                        alert(getApiErrorMessage(err))
                        setSubmitting(false)
                    })
                }}>
                {({isSubmitting, setFieldValue, values, setFieldError}) => (
                    <Form>
                        {renderAlert()}
                        <div className=" px-5" style={{minHeight: "100%"}}>
                        

                            <h3 className="text-dark font-weight-bold mr-5 align-middle  mt-9">
                                {/* <i className="fi-rr-receipt mr-2 ml- align-self-end text-dark-60"/> */}
                                2 - Resumen de Venta
                            </h3>
                            <p className=" text-muted mb-5 font-size-sm"></p>

                            <hr />



                            <p className="font-size-h6 font-weight-bolder text-primary">Plan seleccionado:</p>

                            {renderPlans()}
                            <FieldError name="plan_items"/>
                            {renderProducts()}

                            <hr />


                            <div className="font-size-h6 font-weight-bolder text-primary">Cliente:</div>
                            <Select className="font-size-h5  w-xxl-50 font-weight-bold " placeholder="Buscar cliente"
                                    options={members.map((m) => ({
                                        value: m.id,
                                        label: `${m.external_id}. ${m.full_name} ${m.last_name ?? ''}`
                                    }))}
                                    onInputChange={(text) => setSearchTerm(text)}
                                    noOptionsMessage={() => "Sin resultados"}
                                    isLoading={fetchingMembers} loadingMessage={() => "Cargando ..."}
                                    onChange={(option) => {
                                        setFieldValue("member", option?.value);
                                        setMember(members.find(m => m.id === option?.value))
                                    }}/>
                            <FieldError name="member"/>
                            <div className='text-dark-50 font-size-sm font-weight-normal'>El plan seleccionado se agregará a la cuenta de este cliente.</div>

                            <div className=" mt-8 mb-5"/>



                            <div className="separator separator-solid mb-4"/>


                            <div className="font-size-h6 font-weight-bolder text-primary">Método de pago utilizado:</div>
                            <Select  className="font-size-h5  w-xxl-50 font-weight-bold " placeholder="Método de pago" options={paymentMethods}
                                    onChange={(option) => setFieldValue("payment_method", option?.value)}/>
                            <FieldError name="payment_method"/>
                            
                            <hr />

                            {/*/!* CODIGO DESCUENTO *!/*/}
                            {/*<div className="separator separator-solid mb-8"/>*/}
                            {/*<div className="d-flex justify-content-between mt-5">*/}
                            {/*    <Field name="coupon_code" label="Código descuento"*/}
                            {/*           className="form-control form-control-sm mr-2" placeholder="Código de descuento"*/}
                            {/*           onChange={(event: React.ChangeEvent<any>) => {*/}
                            {/*               setCouponCode(event.target.value)*/}
                            {/*           }}/>*/}
                            {/*    <Button onClick={() => {*/}
                            {/*        validateCoupon().then(resp => {*/}
                            {/*            setFieldValue("coupon_code", couponCode)*/}
                            {/*            setCoupon(resp.data)*/}
                            {/*        }).catch(err => {*/}
                            {/*            console.log(err)*/}
                            {/*            setFieldError("coupon_code", getApiErrorMessage(err))*/}
                            {/*        })*/}
                            {/*    }} variant={"outline-success"} size="sm">Aplicar</Button>*/}
                            {/*</div>*/}
                            {/*<div className="text-success">*/}
                            {/*    {coupon === null ?*/}
                            {/*        <React.Fragment/> : `${coupon.title} -${coupon.discount_amount}${coupon.is_percent ? '%' : ''}`}*/}
                            {/*</div>*/}
                            {/*<div className="text-danger">*/}
                            {/*    {couponError}*/}
                            {/*</div>*/}
                            {/*<FieldError name="coupon_code"/>*/}
                            {/* Descuento */}
                            <div>
                                {/* <div className="text-right font-size-lg mt-5">Aplicar Descuento</div> */}
                                <div className="d-flex justify-content-end align-items-center">
                                    <div></div>
                                    <div className="text-right font-size-lg  mr-3">Descuento $</div>

                                    {/* <FontAwesomeIcon className="mr-2 text-muted" icon={faTag}/> */}
                                    <Field  name="pos_discount" label="Descuento"
                                           value={discountAmount}
                                           className="form-control form-control-sm mr-2 text-right font-size-lg col-5  col-lg-3 col-xl-2 col-xxl-2 border-danger rounded-0" placeholder="$"
                                           onChange={(event: React.ChangeEvent<any>) => {
                                               if (!isNaN(event.target.value)) {
                                                   const discount = parseFloat(event.target.value)
                                                   if (discount <= getTotal(true)) {
                                                       setDiscountAmount(discount)
                                                   } else {
                                                       setDiscountAmount(0)
                                                   }
                                               } else {
                                                   setDiscountAmount(0)
                                               }
                                           }}/>
                                </div>
                            </div>
                            {/*TOTAL*/}
                            <div className="d-flex justify-content-end align-items-center mt-8">
                                <p className="font-size-lg mr-3">Total</p>
                                <p className="font-weight-bold text-primary font-size-h1">$ {getTotal()}</p>
                            </div>


                            <div className="text-center">
                                <Button type="submit"
                                        disabled={isSubmitting || (cartProducts.length === 0 && cartPlans.length === 0)}
                                        className="btn btn-success btn-lg btn- mt-8 justify-content-center font-weight-bold border-0 "
                                        style={{backgroundColor: "#28a745" }}>Terminar y guardar
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
        ;
};

export default PosCart;

interface ValidCoupon {
    title: string;
    discount_amount: number;
    is_percent: boolean;
    redeem_code: string;
}