import React, {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm} from "formik";

import FormField from "../../components/form/FormField";
import ImageField from "../../components/form/ImageField";
import {getApiErrorMessage} from "../../utils/apiErrors";
import baseApi from "../../apis/baseApi";
import {Coach} from "./index";

type CreateCoachModalProps = {
    show: boolean
    onHide: Function
    coach?: Coach
    coaches: Coach[]
    setCoaches: (coaches: Coach[]) => void
}

const CreateEditCoachModal = ({show, onHide, coach, coaches, setCoaches}: CreateCoachModalProps) => {
    const [img, setImg] = useState<File>();
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };
    return (
        <React.Fragment>
            <Modal size="sm" show={show} onHide={handleClose}>
                <Formik initialValues={{full_name: coach?.full_name,}}
                        onSubmit={(values, {setSubmitting}) => {
                            setSuccess(null)
                            if (coach) { // UPDATE COACH
                                baseApi.patch(`/coaches/update/${coach.id}/`, values).then((resp) => {
                                    setSuccess(true)
                                    setCoaches(coaches.map(c => c.id === resp.data.id ? resp.data : c))
                                    if (img && coach.id) {
                                        const formData = new FormData();
                                        formData.append("img", img, img.name);
                                        baseApi.patch(`/coaches/update/${coach.id}/`, formData).then((resp) => {
                                                setCoaches(coaches.map(c => c.id === resp.data.id ? resp.data : c))
                                                setSubmitting(false)
                                            }
                                        )
                                    }
                                    setAlertMessage("Se ha actualizado el coach correctamente.")
                                    setSubmitting(false)
                                }).catch((err) => {
                                    setAlertMessage(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            } else { // CREATE
                                baseApi.post<Coach>('/coaches/', values).then((resp) => {
                                    if (img && resp.data.id) {
                                        const formData = new FormData();
                                        formData.append("img", img, img.name);
                                        baseApi.patch(`/coaches/update/${resp.data.id}/`, formData).then((resp) => {
                                                setCoaches([...coaches, resp.data])
                                            }
                                        )
                                    } else {
                                        setCoaches([...coaches, resp.data])
                                    }
                                    setSubmitting(false)
                                    setSuccess(true)
                                    setAlertMessage("Se ha creado el coach correctamente.")
                                }).catch((err) => {
                                    setAlertMessage(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            }
                        }}>
                    {({isSubmitting, setFieldValue, values}) => (
                        <FormikForm>
                            <Modal.Header className='text-primary font-weight-bolder'>
                                {coach ? "Editar" : "Crear"} Instructor
                            </Modal.Header>
                            <Modal.Body>
                                <div className='font-size-h6 font-weight-bolder ml'>Nombre Instructor</div>
                                <div className='text-muted font-size-sm ml'>Este es el nombre que se mostrará a tus clientes en la app cuando lo asignes a un horario.</div>
                                {renderAlert()}
                                <FormField name="full_name" label="" />

                                {/* AGREGAR IMAGEN */}
                                {/* AGREGAR IMAGEN */}
                                {/* <div className="">
                                    <h3 className="text-muted pt-2 font-size-sm">Imagen</h3>
                                    <ImageField src={coach?.img} onChange={(file) => setImg(file)}/>
                                </div> */}


                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Atrás
                                </Button>
                                <Button type="submit" disabled={isSubmitting} className="font-weight-bolder">
                                    {isSubmitting ? "..." : "Guardar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateEditCoachModal;
