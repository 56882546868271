import React from 'react';

const EmptyTr = () => {
    return (
        <tr className="text-center w-100 text-muted font-size-h6-xl ">
            <td colSpan={7}>No hay datos</td>
        </tr>
    );
};

export default EmptyTr;
