import {TimeSlot} from "../pages/Schedules";
import {TimeslotReservationsActions, TimeslotReservationsState} from "../actions/timeslotReservations";
import {TimeSlotReservationsTypes} from "../actions";

const initialState = {
    loading: true,
    reservations: [],
    timeslot: {} as TimeSlot
} as TimeslotReservationsState

export const timeSlotReservationsReducer = (state = initialState, action: TimeslotReservationsActions) => {
    switch (action.type) {
        case TimeSlotReservationsTypes.FETCHING:
            return {...state, loading: true}
        case TimeSlotReservationsTypes.FETCHED:
            return {loading: false, reservations: action.payload.reservations, timeslot: action.payload.timeslot}
        case TimeSlotReservationsTypes.UPDATE_RESERVATION:
            return {
                ...state,
                reservations: state.reservations.map((r) => r.id === action.payload.id ? action.payload : r)
            }
        case TimeSlotReservationsTypes.ADD_RESERVATION:
            const reservations = [action.payload, ...state.reservations]
            return {...state, reservations: reservations}
        default:
            return state
    }
}