import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm} from "formik";
import {SketchPicker} from 'react-color'
import {useDispatch} from "react-redux";

import FormField from "../../../components/form/FormField";
import baseApi from "../../../apis/baseApi";
import {addClass, StudioClass, updateClass} from "../../../actions/studioClasses";
import {getApiErrorMessage} from "../../../utils/apiErrors";

type CreateEditClassModalProps = {
    show: boolean
    onHide: Function
    studioClass?: StudioClass
}

const CreateEditClassModal = ({show, onHide, studioClass}: CreateEditClassModalProps) => {
    const dispatch = useDispatch()
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("")

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMsg}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };
    return (
        <React.Fragment>
            <Modal show={show} onHide={handleClose} size="sm">
                <Formik initialValues={{name: studioClass?.name, color: studioClass?.color || "#000000"}}
                        onSubmit={(values, {setSubmitting}) => {
                            setSuccess(null)
                            if (studioClass) {
                                baseApi.patch<StudioClass>(`/studios/classes/${studioClass.id}/`, values).then((resp) => {
                                    dispatch(updateClass(resp.data))
                                    setAlertMsg("Cambios guardados.")
                                    setSuccess(true)
                                    setSubmitting(false)
                                }).catch((err) => {
                                    setAlertMsg(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            } else {
                                baseApi.post<StudioClass>("/studios/classes/", values).then((resp) => {
                                    dispatch(addClass(resp.data))
                                    setAlertMsg("Clase creada.")
                                    setSuccess(true)
                                    setSubmitting(false)
                                }).catch((err) => {
                                    setAlertMsg(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            }
                        }}>
                    {({isSubmitting, setFieldValue, values}) => (
                        <FormikForm>

                            <Modal.Header>
                                {studioClass ? "Editar" : "Nueva"} clase
                            </Modal.Header>
                            <Modal.Body className='px-0'>
                                {renderAlert()}
                                <div className=" col-12">
                                    <div className='font-weight-bolder font-size-lg mb-n5'>Nombre</div>
                                    <FormField label="" name="name"/>
                                </div>
                                <div className="ml-3">
                                    <p  className='font-weight-bolder font-size-lg'>Color</p>
                                    <SketchPicker color={values.color} onChange={(c) => setFieldValue("color", c.hex)}
                                                  disableAlpha={true}/>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : studioClass ? "Guardar" : "Guardar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateEditClassModal;
