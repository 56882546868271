import {format, parseISO} from "date-fns";
import {es} from "date-fns/locale";

export const formatDate = (date: string | undefined) : string => {
    if (!date) return ""
    return format(parseISO(date), "d MMM yy", {locale: es})
}

export const formatDateTime = (date: string | undefined) : string => {
    if (!date) return ""
    return format(parseISO(date), "d MMMM yy HH:mm", {locale: es})
}