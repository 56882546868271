import React from 'react';

const NotFoundScreen = () => {
    return (
        <div>
            <h4 className='text-primary text-center mt-20'>Error 404 - No existe esta página</h4>
        </div>
    );
};

export default NotFoundScreen;
