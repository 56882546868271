import React, {useEffect} from "react";
import {useState} from "react";
import {Alert, Button, Modal,} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import {Formik, Form as FormikForm, Field} from "formik";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faSyncAlt, faSitemap, faWalking} from "@fortawesome/free-solid-svg-icons";
import {faClock, faCalendar, faUser} from "@fortawesome/free-regular-svg-icons";
import {addDays, addMinutes, formatISO, formatISO9075, isBefore, parseISO, isSameISOWeek, parse} from "date-fns";
import {es} from "date-fns/locale";
import yup from "../../utils/yup";

import {StoreState} from "../../reducers";
import {Branch, BranchesState} from "../../actions";
import {StudioClass} from "../../actions/studioClasses";
import {TimeSlot} from "./index";
import {Coach} from "../Coaches";
import {getApiErrorMessage} from "../../utils/apiErrors";
import {FormikSelect} from "../../components/form/FormikSelect";
import ModalSelect from "../../components/form/ModalSelect";
import MiniFormField from "../../components/form/MiniFormField";
import MiniFormField2 from "../../components/form/MiniFormField2";

import Checkbox from "../../components/form/Checkbox";
import FieldError from "../../components/form/FieldError";
import baseApi from "../../apis/baseApi";

import "react-datepicker/dist/react-datepicker.css";
import "../../assets/styles/datepicker.css"
import {StudioClassSelect} from "../../components/form/StudioClassSelect";

type CreateScheduleModalProps = {
    show: boolean
    onHide: Function
    timeslots: TimeSlot[]
    setTimeSlots: (slots: TimeSlot[]) => void
    weekOf: Date
    selectedTimeSlot?: TimeSlot
    disabled?: boolean
}

type BranchRoom = {
    id: number,
    name: string,
    people_limit: number
}

const ScheduleSchema = yup.object().shape({
    people_limit: yup.number().positive().required(),
    minutes_duration: yup.number().positive().required(),
    studio_class: yup.string().required(),
    activate_days_before: yup.number().min(1).max(99).nullable()
})

const EditCreateScheduleModal = ({
                                     show,
                                     onHide,
                                     selectedTimeSlot,
                                     timeslots,
                                     setTimeSlots,
                                     weekOf,
                                     disabled = false
                                 }: CreateScheduleModalProps) => {
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const classes = useSelector<StoreState, StudioClass[]>(state => state.studioClasses.classes)

    const [success, setSuccess] = useState<boolean | null>(null);

    const [coaches, setCoaches] = useState<Coach[]>([])
    const [rooms, setRooms] = useState<BranchRoom[]>([])
    const [alertMsg, setAlertMsg] = useState("")

    const minDate = new Date()

    useEffect(() => {
        baseApi.get(`/coaches/?branch=${currentBranch.id}`).then((resp) => {
            setCoaches(resp.data)
        })
        fetchRooms(currentBranch.id)
    }, [currentBranch]);

    const fetchRooms = (branchId: string) => {
        setRooms([])
        baseApi.get<BranchRoom[]>(`/rooms/?branch=${branchId}`).then((resp) => {
            setRooms(resp.data)
        }).catch(() => alert("Error obteniendo salones. Intenta de nuevo."))
    }

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMsg}</Alert>;
    };


    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const getInitialStartDate = () : Date =>  {
        if (selectedTimeSlot!.start_time !== null) {
            return parse(selectedTimeSlot!.start_time, "HH:mm:ss", selectedTimeSlot!.start)
        }
        return selectedTimeSlot!.start
    }

    return (
        <React.Fragment>
            <Modal id="schedule-modal" show={show} onHide={handleClose} size="lg">
                <div className="">
                    <Formik
                        validationSchema={ScheduleSchema}
                        initialValues={{
                            monday: false,
                            tuesday: false,
                            wednesday: false,
                            thursday: false,
                            friday: false,
                            saturday: false,
                            sunday: false,
                            is_recurrent: false,
                            branch: currentBranch.id,
                            publish_date: selectedTimeSlot ?
                                typeof selectedTimeSlot.publish_date === 'string'
                                    ? parseISO(selectedTimeSlot!.publish_date!)
                                    : undefined
                                : undefined,
                            activate_days_before: selectedTimeSlot ? selectedTimeSlot.activate_days_before : null,
                            studio_class: selectedTimeSlot ? selectedTimeSlot.studio_class.id : undefined,
                            coaches: selectedTimeSlot ? selectedTimeSlot.coaches.map(c => c.id) : [],
                            people_limit: selectedTimeSlot ? selectedTimeSlot.people_limit : undefined,
                            room: selectedTimeSlot ? selectedTimeSlot.room?.id : undefined,
                            minutes_duration: selectedTimeSlot ? selectedTimeSlot.minutes_duration : 0,
                            start: selectedTimeSlot ? getInitialStartDate() : addMinutes(new Date(), 30),
                            end: selectedTimeSlot ? selectedTimeSlot.start : addMinutes(new Date(), 30),
                        }}
                        onSubmit={(values, {setSubmitting}) => {
                            if (selectedTimeSlot) {
                                baseApi.patch(`/schedules/slots/${selectedTimeSlot.id}/`, {
                                    ...values,
                                    start: formatISO(values.start),
                                    start_time: formatISO9075(values.start, {representation: "time"}),
                                }).then((resp) => {

                                    setAlertMsg("Se actualizó el horario correctamente.")
                                    setSuccess(true)
                                    setSubmitting(false)
                                }).catch((err) => {
                                    setAlertMsg(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            } else {
                                baseApi.post("/schedules/", {
                                    ...values,
                                    start: formatISO(values.start),
                                    end: formatISO(values.end),
                                    publish_date: values.publish_date ? formatISO(values.publish_date) : null,
                                    start_time: formatISO9075(values.start, {representation: "time"}),
                                }).then(resp => {
                                    let newSlots = resp.data.slots.map((slot: any) => ({
                                        ...slot,
                                        start: parseISO(slot.start)
                                    }))
                                    newSlots = newSlots.filter((slot: TimeSlot) => isSameISOWeek(slot.start, weekOf))
                                    setTimeSlots([...timeslots, ...newSlots])
                                    setAlertMsg("Se creó el horario correctamente.")
                                    setSuccess(true)
                                    setSubmitting(false)
                                }).catch((err) => {
                                    setAlertMsg(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            }
                        }}
                    >
                        {({isSubmitting, values, setFieldValue}) => (
                            <FormikForm>

                                <Modal.Header className="font-weight-bolder font-size-h2  px-2 py-2">
                                    {selectedTimeSlot ? disabled ? "Detalles del horario" : "Editar Horario" : "Crear Horario"}
                                    {/* <div className="text-muted font-size-normal">{selectedTimeSlot ? `# ${selectedTimeSlot.id.slice(28).toUpperCase()}` : ""}</div> */}
                                    {renderAlert()}
                                </Modal.Header>

                                <Modal.Body className="px-0 ml-lg-n40">
                                    
                                    {/* ALERT */}
                                    {/* <div className="offset-lg-2">{renderAlert()}</div> */}

                                    {/* SUCURSAL */}
                                    {/* <div hidden={branchesState.branches.length === 1}>
                                        <ModalSelect label="Sucursal">
                                            <Field name="branch" options={branchesState.selectOptions}
                                                   onChange={(x: any) => {
                                                       if (x) {
                                                           setFieldValue("room", null)
                                                           fetchRooms(x.value)
                                                       }
                                                   }}
                                                   isDisabled={selectedTimeSlot !== undefined || disabled}
                                                   component={FormikSelect}/>

                                        </ModalSelect>
                                    </div> */}
                                    {/* FIN SUCURSAL */}

                                    {/* CLASE */}
                                    <ModalSelect label="">
                                        <div className="d-flex font-size-h5 font-weight-bold text-primary">
                                            {/* <div style={{width: "30px"}} className=" mr-n4 ">
                                                <FontAwesomeIcon icon={faWalking}/>
                                            </div> */}
                                            Nombre Clase
                                        </div>
                                        <Field name="studio_class"
                                               isDisabled={disabled}
                                               options={classes.map((c) => ({label: c.name, value: c.id}))}
                                               component={StudioClassSelect}/>
                                        <FieldError name="studio_class"/>
                                        <div className="text-dark-50 font-size-sm">Escribe y da enter para crear una nueva</div>
                                    </ModalSelect>


                                    {/* LIMITE DE PERSONAS */}
                                    <div className="col-7 col-lg-3 offset-lg-2 mt-n3 mb-7">
                                        <div className="font-size-h5 font-weight-bold ml-n2 text-primary">Límite de personas</div>
                                        <MiniFormField2 disabled={disabled} name="people_limit"/>
                                                     <FieldError name="people_limit"/>                                    </div>





                                    {/* FECHA */}
                                    <ModalSelect label="">
                                        <div className="d-flex font-size-h5 font-weight-bold text-primary">
                                            Fecha
                                            <div style={{width: "30px"}} className=" ml-3 ">
                                                <FontAwesomeIcon icon={faCalendar}/>
                                            </div>
                                        </div>
                                        <div className="customDatePickerWidth" style={{width: "220px"}}>
                                            <ReactDatePicker
                                                disabled={disabled}
                                                dateFormat="dd 'de' MMMM 'del' yyyy"
                                                locale={es}
                                                selected={values.start}
                                                minDate={minDate}
                                                onChange={date => {
                                                    if (date) {
                                                        setFieldValue("start", date)
                                                        if (isBefore(values.end, date as Date)) {
                                                            setFieldValue("end", date)
                                                        }
                                                    }
                                                }}
                                                customInput={<input className="form-control"/>}
                                            />
                                        </div>
                                    </ModalSelect>




                                    {/* HORA */}
                                    <ModalSelect label=""
                                                 extra={<div className="flex-row align-items-center">

                                                     {/* <div className="font-size-lg font-weight-bolder">Duración </div>
                                                     <div className="d-flex">
                                                        <MiniFormField disabled={disabled} name="minutes_duration"/>
                                                        <div className="d-flex text-dark font-size-bold align-self-center">
                                                            <small className="ml-n2">minutos</small>
                                                        </div>
                                                    </div>
                                                    <FieldError name="minutes_duration"/> */}
                                                 </div>}>

                                        <div className="d-flex font-size-h5 font-weight-bold text-primary">
                                            Hora
                                            <div style={{width: "30px"}} className=" ml-2 ">
                                                <FontAwesomeIcon icon={faClock}/>
                                            </div>
                                        </div>
                                        {/* hora*/}
                                        <div className="customDatePickerWidth" style={{width: "220px"}}>
                                                <ReactDatePicker
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    disabled={disabled}
                                                    locale={es}
                                                    selected={values.start}
                                                    minDate={minDate}
                                                    onChange={date => {
                                                        if (date) {
                                                            setFieldValue("start", date)
                                                            if (isBefore(values.end, date as Date)) {
                                                                setFieldValue("end", date)
                                                            }
                                                        }
                                                    }}
                                                    dateFormat="h:mm aa"
                                                    timeIntervals={15}
                                                    timeCaption={"Hora"}
                                                    customInput={<input className="form-control"/>}
                                                />
                                        </div>
                                    </ModalSelect>

                                    {/* DURACION */}
                                    <div className="col-7 col-lg-3 offset-lg-2 mb-7">
                                        <div className="font-size-h5 font-weight-bold ml-n2 text-primary">Duración <span className="text-muted ml-1"> (minutos)</span></div>
                                        <MiniFormField2 disabled={disabled} name="minutes_duration"/>
                                        <FieldError name="minutes_duration"/>

                                    </div>


                                    {/* SALON */}
                                    {/* <ModalSelect label=""

                                                 extra={<div className="flex-row align-items-center ">
                                                     <div className="font-size-lg font-weight-bolder"># Lugares </div>
                                    <ModalSelect label="Salón"
                                                 extra={<div className="d-flex flex-row align-items-center">
                                                     <div className="ml-4 mr-2">
                                                         <span>Capacidad</span>
                                                     </div>
                                                     <MiniFormField disabled={disabled} name="people_limit"/>
                                                     <FieldError name="people_limit"/>
                                                 </div>}>

                                        <div className="d-flex font-size-lg font-weight-bolder">
                                            <div style={{width: "30px"}} className=" mr-n2 ">
                                                <FontAwesomeIcon icon={faSitemap}/>
                                            </div>
                                            Salón
                                        </div>
                                        <Field isClearable={true} name="room"
                                               isDisabled={disabled}
                                               options={rooms.map((r) => ({value: r.id, label: r.name}))}
                                               component={FormikSelect}
                                               onChange={(room: any) => {
                                                   if (room) {
                                                       const x = rooms.find(r => r.id === room.value)
                                                       if (x !== undefined) {
                                                           setFieldValue("people_limit", x.people_limit)
                                                       }
                                                   }
                                               }}
                                        />
                                    </ModalSelect> */}



                                     {/* INSTRUCTOR */}
                                     <ModalSelect label="">
                                        <div className="d-flex font-size-h5 font-weight-bold text-primary">
                                            {/* <div style={{width: "30px"}} className=" mr-n3 ">
                                                <FontAwesomeIcon icon={faUser}/>
                                            </div> */}
                                            Instructor <span className="text-muted ml-1"> (opcional)</span>
                                        </div>
                                        <Field name="coaches"
                                               isDisabled={disabled}
                                               options={coaches.map((c) => ({label: c.full_name, value: c.id}))}
                                               component={FormikSelect}
                                               isMulti={true}
                                        />
                                        <div className="text-dark-50 font-size-sm">Crea nuevos instructores en "Personalización"</div>

                                    </ModalSelect>

                                    {/* <div className="d-flex font-size-lg offset-lg-3 pl-2 font-weight-bolder">
                                        <div style={{width: "30px"}} className=" mr-n3 ">
                                            <FontAwesomeIcon icon={faCalendar}/>
                                        </div>
                                        Fecha
                                    </div>

                                    <div style={{width: "550px"}} className="d-flex flex-row pb-3 offset-lg-1">
                                        <div style={{width: "30px"}} className=" invisible">
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                        </div>
                                        <div style={{width: "90px"}}>
                                            <span className="font-size-h5 invisible">Fecha</span>
                                        </div>
                                        <div className="customDatePickerWidth" style={{width: "220px"}}>
                                            <ReactDatePicker
                                                disabled={disabled}
                                                dateFormat="dd 'de' MMMM 'del' yyyy"
                                                locale={es}
                                                selected={values.start}
                                                minDate={minDate}
                                                onChange={date => {
                                                    if (date) {
                                                        setFieldValue("start", date)
                                                        if (isBefore(values.end, date as Date)) {
                                                            setFieldValue("end", date)
                                                        }
                                                    }
                                                }}
                                                customInput={<input className="form-control"/>}
                                            />
                                        </div>
                                    </div> */}


                                    {/* <div className="d-flex font-size-lg offset-lg-3 pl-2 font-weight-bolder">
                                        <div style={{width: "30px"}} className=" mr-n3 ">
                                            <FontAwesomeIcon icon={faClock}/>
                                        </div>
                                        Hora
                                    </div>


                                    <div className="d-flex flex-row align-items-center offset-lg-1">
                                        <div style={{width: "370px"}} className="d-flex flex-row pb-3">
                                            <div style={{width: "30px"}} className="invisible">
                                                <FontAwesomeIcon icon={faClock}/>
                                            </div>
                                            <div style={{width: "90px"}}>
                                                <span className="invisible">Hora</span>
                                            </div>
                                            <div className="customDatePickerWidth" style={{width: "220px"}}>
                                                <ReactDatePicker
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    disabled={disabled}
                                                    locale={es}
                                                    selected={values.start}
                                                    minDate={minDate}
                                                    onChange={date => {
                                                        if (date) {
                                                            setFieldValue("start", date)
                                                            if (isBefore(values.end, date as Date)) {
                                                                setFieldValue("end", date)
                                                            }
                                                        }
                                                    }}
                                                    dateFormat="h:mm aa"
                                                    timeIntervals={15}
                                                    timeCaption={"Hora"}
                                                    customInput={<input className="form-control"/>}
                                                />
                                            </div>
                                        </div>


                                        <MiniFormField disabled={disabled} name="minutes_duration"/>
                                        <div className="d-flex text-secondary font-size-bold">
                                            <small className="">minutos</small>
                                        </div>
                                        <FieldError name="minutes_duration"/>
                                    </div> */}



                                    <div className="border-top mt-5 offset-lg-2"></div>



                                    {/* REPETIR HORARIO */}
                                    <div className="font-size-lg offset-lg-2 pl-2 mt-5 font-weight-bold d-flex text-center justify-content-center">
                                        Repetir Horario <span className="text-muted ml-1"> (copias)</span>
                                    </div>


                                    <div className="offset-lg-3 pl-36">
                                        <div hidden={selectedTimeSlot !== undefined}>
                                            <ModalSelect label="">
                                                <Field name="is_recurrent"
                                                       options={[{value: false, label: "No crear copias"}, {
                                                           value: true,
                                                           label: "Si copiar en diferentes fechas"
                                                       }]}
                                                       component={FormikSelect}/>
                                            </ModalSelect>

                                            

                                        </div>
                                    </div>
                                    {/* <div className=" offset-lg-3 pl-2 text-muted font-size-sm mt-n">*Se crearan horarios igual a este en las fechas indicadas</div> */}
                                    {/* <div className=" offset-lg-3 pl-2 text-muted font-size-sm">*Puedes editar los horarios repetidos despues.</div> */}


                                    <div className="offset-lg-2" hidden={!values.is_recurrent}>
                                        <div style={{}} className=" mb-3">
                                        <p className="m-0 mx-5 ml-n1 font-size-sm text-center">Selecciona los días de la semana en los que se crearan horarios igual a este</p>

                                            <div className="d-flex flex-row align-items-center justify-content-center">
                                                <div className="d-flex align-items-center flex-column">
                                                    <Field name="monday" component={Checkbox}/>
                                                    <label>L</label>
                                                </div>
                                                <div
                                                    className="d-flex justify-content-center align-items-center flex-column">
                                                    <Field name="tuesday" component={Checkbox}/>
                                                    <label>M</label>
                                                </div>
                                                <div
                                                    className="d-flex justify-content-center align-items-center flex-column">
                                                    <Field name="wednesday" component={Checkbox}/>
                                                    <label>M</label>
                                                </div>
                                                <div
                                                    className="d-flex justify-content-center align-items-center flex-column">
                                                    <Field name="thursday" component={Checkbox}/>
                                                    <label>J</label>
                                                </div>
                                                <div
                                                    className="d-flex justify-content-center align-items-center flex-column">
                                                    <Field name="friday" component={Checkbox}/>
                                                    <label>V</label>
                                                </div>
                                                <div
                                                    className="d-flex justify-content-center align-items-center flex-column">
                                                    <Field name="saturday" component={Checkbox}/>
                                                    <label>S</label>
                                                </div>
                                                <div
                                                    className="d-flex justify-content-center align-items-center flex-column">
                                                    <Field name="sunday" component={Checkbox}/>
                                                    <label>D</label>
                                                </div>



                                            </div>
                                            <p className=" mb-n1 mt-2 font-size-sm text-center">¿Hasta que fecha deseas crear horarios igual a este?</p>
                                                <div className="customDatePickerWidth mx-auto mt-2" style={{width: "220px"}}>

                                                    <ReactDatePicker
                                                        dateFormat="dd 'de' MMMM 'del' yyyy"
                                                        locale={es}
                                                        selected={values.end}
                                                        minDate={addDays(values.start, 1)}
                                                        onChange={date => {
                                                            setFieldValue("end", date)
                                                        }}
                                                        customInput={<input className="form-control text-primary"/>}
                                                    />
                                                </div>

                                             <div className="mt-3 text-muted text-center mb-3">Podrás editar las copias individualmente si necesitas</div>

                                        </div>
                                    </div>

                                    {/* ABRIR RESERVAS */}
                                    <div className="offset-lg-2 pl-lg-17 border-top pt-2 justify-content-center">
                                        <FieldError name="activate_days_before"/>
                                        <div className="form-group row align-items-center ">
                                            <label className="col-auto col-form-label font-size-h6">
                                                Permitir reservar este horario solo con
                                            </label>
                                            <MiniFormField disabled={disabled} name="activate_days_before"
                                                           type="number"/>

                                            <label className="col-auto col-form-label font-size-h6 ml-n4">
                                                días de anticipación (OPCIONAL)
                                            </label>
                                        </div>
                                    </div>


                                    {/* PHORARIO */}
                                    {/* <div>
                                        <div style={{width: "550px"}} className="d-flex flex-row pb-1 align-items-center">
                                            <div className="customDatePickerWidth" style={{width: "250px"}}>
                                                <ReactDatePicker
                                                    disabled={disabled}
                                                    dateFormat="dd 'de' MMMM 'del' yyyy, HH:mm"
                                                    locale={es}
                                                    showTimeSelect={true}
                                                    timeCaption={"Hora"}
                                                    selected={values.publish_date}
                                                    minDate={new Date()}
                                                    onChange={date => {
                                                        setFieldValue("publish_date", date)
                                                    }}
                                                    customInput={<input className="form-control"/>}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* FINPHORARIO */}



                                </Modal.Body>

                                {/* BOTONES FOOTER */}
                                <Modal.Footer>
                                {renderAlert()}
                                    <Button variant="light-dark font-weight-bolder font-size-h6 text-white" onClick={handleClose}>
                                        Cerrar
                                    </Button>
                                    <Button className="font-weight-bolder font-size-h6 " type="submit" disabled={isSubmitting || disabled}>
                                        {isSubmitting ? "..." : "Crear Horario"}
                                    </Button>
                                </Modal.Footer>
                            </FormikForm>
                        )}
                    </Formik>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default EditCreateScheduleModal;
