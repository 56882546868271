import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";

export type TableFooterProps = {
    count: number
    pageCount: number
    pageSize: number
    onPageChange: (page: number) => void
}

const TableFooter = ({count, pageCount, pageSize, onPageChange}: TableFooterProps) => {
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const maxPages = Math.ceil(count / pageSize)

    const handlePageChange = (_page: number) => {
        if (_page < 1 || _page > maxPages) return;
        setLoading(true)
        onPageChange(_page)
        setPage(_page)
        setLoading(false)

    }

    return (

        

        <div>

            {/* NEW */}
            <div className="d-flex justify-content-between align-items-center flex-wrap">
                {/* lado izq  pagination*/}
                <div className="d-flex align-items-center py-3">
                    {/*<select className="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary" style={{width: '75px'}}>*/}
                    {/*    <option value={10}>10</option>*/}
                    {/*    <option value={20}>20</option>*/}
                    {/*    <option value={30}>30</option>*/}
                    {/*    <option value={50}>50</option>*/}
                    {/*    <option value={100}>100</option>*/}
                    {/*</select>*/}
                    <span className="text-dark-50 font-size-lg">{pageCount} de {count} resultados</span>
                    {loading ?
                        <div className="d-flex align-items-center">
                            <div className="mr-2 text-muted">Cargando</div>
                            <div className="spinner spinner-primary mr-10"/>
                        </div> : <React.Fragment/>
                    }
                </div>
                {/* BOTONES DERECHA */}
                <div className="d-flex flex-wrap py-2 mr-3">
                    {/* FLECHA IZQUIERDA */}

                    <Button
                        disabled={page === 1}
                        onClick={() => handlePageChange(page - 1)}
                        size="sm"
                        className="btn-icon py-5 btn-outline-secondary text-primary mr-2 my-1 font-size-h6"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} size="1x"/>
                    </Button>
                    {page > 1 ?
                        <button onClick={() => handlePageChange(page - 1)}
                                className={`btn btn-icon font-weight-bold btn-outline-secondary text-primary  btn-hover-primary mr-2 my-1`}>{page - 1}</button> :
                        <React.Fragment/>
                    }

                    <Button
                        size="sm"
                        className="btn btn-icon border-0 btn-hover-primary active mr-2 my-1 font-weight-bold font-size-h6">{page}</Button>
                    {count > page * pageSize ?
                        <Button size="sm" onClick={() => handlePageChange(page + 1)}
                                bsPrefix="btn font-weight-bold btn-outline-secondary text-primary btn-hover-primary btn-sm mr-2 my-1 font-weight-bold font-size-h6"
                                className="btn-icon "
                        >
                            {page + 1}
                        </Button>
                        : <React.Fragment/>
                    }
                    {count > (((page + 1) * pageSize) + 1) ?
                        <Button
                            size="sm"
                            bsPrefix="btn font-weight-bold btn-outline-secondary text-primary btn-hover-primary btn-sm mr-2 my-1 font-weight-bold font-size-h6"
                            onClick={() => handlePageChange(page + 2)}
                            className="btn-icon font-size-h6">{page + 2}</Button>
                        : <React.Fragment/>
                    }


                    <Button
                        onClick={() => handlePageChange(page + 1)}
                        disabled={page === maxPages}
                        size="sm"
                        className="btn-icon py-5 font-weight-bold btn-outline-secondary text-primary font-size-h6 mr-2 my-1">
                        <FontAwesomeIcon
                            icon={faChevronRight} size="1x"/>
                    </Button>
                </div>
            </div>































            {/* ORIGINAL */}
            {/* <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="d-flex align-items-center py-3">

                    <span className="text-muted">Mostrando {pageCount} de {count} resultados</span>
                    {loading ?
                        <div className="d-flex align-items-center">
                            <div className="mr-2 text-muted">Loading...</div>
                            <div className="spinner spinner-primary mr-10"/>
                        </div> : <React.Fragment/>
                    }
                </div>
                <div className="d-flex flex-wrap py-2 mr-3">
                    <Button
                        disabled={page === 1}
                        onClick={() => handlePageChange(page - 1)}
                        size="sm"
                        className="btn-icon btn-sm btn-circle btn-light mr-2 my-1"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} size="sm"/>
                    </Button>
                    {page > 1 ?
                        <button onClick={() => handlePageChange(page - 1)}
                                className={`btn btn-icon btn-sm btn-circle btn-light mr-2 my-1`}>{page - 1}</button> :
                        <React.Fragment/>
                    }
                    <Button
                        size="sm"
                        className="btn btn-icon btn-circle border-0 btn-hover-primary active mr-2 my-1">{page}</Button>
                    {count > page * pageSize ?
                        <Button size="sm" onClick={() => handlePageChange(page + 1)}
                                bsPrefix="btn btn-light btn-hover-primary btn-sm mr-2 my-1"
                                className="btn-icon btn-circle"
                        >
                            {page + 1}
                        </Button>
                        : <React.Fragment/>
                    }
                    {count > (((page + 1) * pageSize) + 1) ?
                        <Button
                            size="sm"
                            bsPrefix="btn btn-light btn-hover-primary btn-sm mr-2 my-1"
                            onClick={() => handlePageChange(page + 2)}
                            className="btn-icon btn-circle">{page + 2}</Button>
                        : <React.Fragment/>
                    }
                    <Button
                        onClick={() => handlePageChange(page + 1)}
                        disabled={page === maxPages}
                        size="sm"
                        className="btn-icon btn-circle btn-light mr-2 my-1">
                        <FontAwesomeIcon
                            icon={faChevronRight} size="sm"/>
                    </Button>
                </div>
            </div> */}
        </div>

    );
};

export default TableFooter;
