export const currencyFormat = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits:0,
    maximumFractionDigits:0,
});

export const preciseCurrency = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits:0,
    maximumFractionDigits:2,
});