import {Branch, BranchesActions,} from "../actions/branches";
import {BranchActionTypes} from "../actions";

const initialState= {
    id: "",
    name: "",
    phone: "",
    timezone: "",
    parent: ""
} as Branch

export const currentBranchReducer = (state: Branch = initialState, action: BranchesActions) => {
    switch (action.type) {
        case BranchActionTypes.SELECT_BRANCH:
            return action.payload
        default:
            return state
    }
}