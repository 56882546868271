import React, {useEffect, useState} from 'react';
import {Formik, Form as FormikForm, Field} from "formik";
import FormField from "../../../components/form/FormField";
import {Alert, Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import ToolboxHeader from "../ToolboxHeader";
import {Preferences} from "../Settings";
import baseApi from "../../../apis/baseApi";
import {faPencilRuler} from "@fortawesome/free-solid-svg-icons";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {FormikSelect} from "../../../components/form/FormikSelect";
import yup from "../../../utils/yup";
import FieldError from "../../../components/form/FieldError";

import {faCcVisa, faCcMastercard, faStripe} from "@fortawesome/free-brands-svg-icons";
import {faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";




const PaymentProcessors = () => {
    const [loading, setLoading] = useState(true)
    const [preferences, setPreferences] = useState<Preferences>()
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("");

    useEffect(() => {
        baseApi.get<Preferences>("/prefs/").then((resp) => {
            setPreferences(resp.data)
            setLoading(false)
        })
    }, [])

    const ValidationSchema = yup.object().shape({
        public_key: yup.string().when('payment_processor', (pp, schema) => pp !== null ? schema.required() : schema.notRequired().nullable()),
        private_key: yup.string().when('payment_processor', (pp, schema) => pp !== null ? schema.required().matches('^[^*]+$', {message: "La llave no puede incluir asteriscos (*)"}) : schema.notRequired().nullable()),
    })

    if (loading) return <div>
        
        <div className='spinner spinner-primary  mx-auto m-15 w-100px p-20'></div>
    </div>
    return (
        <div className="p-5">

            {/* <button className='btn btn-link-primary mb-3'><FontAwesomeIcon icon={faLongArrowAltLeft} size="lg" className='mr-3' />Configuración</button> */}
            <div className=" align-items-center mb-5">
                <h2 className="pt-2 font-size-h2 font-weight-bolder">Pasarela pagos en línea</h2>
                <div className="d-md-flex ">
                    <p className="font-size-lg text-dark-50 d-block">La pasarela te permite vender y aceptar pagos en línea de tus planes a través de la app.</p>
                </div>

            </div>

            <hr />
            {/* <div className="my-10 border-bottom">
                <h2 className="ml-10">Procesador de pagos en línea<FontAwesomeIcon className="text-muted mx-2" icon={faCcVisa}/><FontAwesomeIcon className="text-muted" icon={faCcMastercard}/>
                </h2>
                <div  className="ml-10 text-muted pb-5 font-size-lg font-weight-bold">El procesador de pagos es necesario para aceptar pagos en línea con tarjeta de crédito o débito de forma segura.
                </div>
                
            </div> */}

            <Alert variant={success ? "success" : "danger"} hidden={success === null}>{alertMsg}</Alert>

            <div className="col-12 col-md-7 col-xxl-6 p-5">
                <div className="pr-5">
                    <Formik
                        validationSchema={ValidationSchema}
                        initialValues={{
                            public_key: preferences?.public,
                            private_key: preferences?.public !== null ? "*".repeat(40) : "",
                            payment_processor: preferences?.payment_processor,
                        }}
                        onSubmit={(values, {setSubmitting}) => {
                            setSuccess(null)
                            baseApi.patch("/prefs/", values).then(resp => {
                                if (!values.payment_processor) {
                                    setAlertMsg("Preferencias actualizadas.")
                                    setSuccess(true)
                                    setSubmitting(false)
                                }
                            })
                            if (values.payment_processor) {
                                const url = values.payment_processor === "ST" ? "/spp/stripe/" : "/spp/mercadopago/"
                                baseApi.post(url, values).then((resp) => {
                                    console.log(resp)
                                    setAlertMsg("'Información Actualizada'.")
                                    setSuccess(true)
                                    setSubmitting(false)
                                }).catch((err) => {
                                    setAlertMsg(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            }
                        }}>
                
                        {({isSubmitting, values, setFieldValue}) => (
                            <FormikForm>
                                 <div className='mb-10'>Regístrate en <a href="https://dashboard.stripe.com/register"><u className='text-primary'>STRIPE</u></a> para crear tu cuenta y comenzar a recibir pagos en línea</div>

                                {/* PROCESADOR DE PAGOS */}
                                                    <div className="ml-10 font-size-lg font-weight-bold"><i className="fi fi-rr-info align-self-center mr-2"></i>
                                                        Las pasarelas de pago tienen una comisión por pago realizado, lee más en su página web oficial.
                                                    </div>


                                                    {/* <div className="ml-17 text-muted pb-5 font-weight-bold">
                                                    <FontAwesomeIcon className="text-muted mx-2 font-size-h1" icon={faStripe}/>
                                                    </div> */}


                                                    {/* <div className="ml-17 text-muted pb-5 font-weight-bold">
                                                        Da click en el procesador para conocer detalles de sus tarifas.
                                                    </div> */}

                                                    <Form.Group className="row ml-14">
                                                        <Form.Label className="col-12 col-form-label font-size-h6 font-weight-bolder"><span className='text-primary'>1. </span>Seleciona stripe para activarlo  </Form.Label>
                                                        <div className="col-lg-12">
                                                            <Field name="payment_processor" component={FormikSelect}
                                                                options={[
                                                                    // {value: null, label: "Selecciona un procesador de pagos"},
                                                                    {value: "ST", label: "Stripe"},
                                                                    // {value: "MP", label: "Mercado Pago"},
                                                                ]}/>
                                                        </div>
                                                        {/* <small className="align-self-center text-info font-weight-bold">¿Cómo conectarlo?</small> */}
                                                    </Form.Group>

                                                    <div className="ml-lg-15">
                                                        <div hidden={values.payment_processor === null}>
                                                            <div className="col-12 col-form-label font-size-h6 font-weight-bolder ml-n2 mb-n8"><span className='text-primary'>2. Pega </span> la "Clave Publicable"</div>
                                                            <FormField label="" name="public_key"/>
                                                            <FieldError name="public_key"/>

                                                            <div className="col-12 col-form-label font-size-h6 font-weight-bolder ml-n2 mb-n8"><span className='text-primary'>3. Pega </span> la "Clave Secreta"</div>
                                                            <FormField type="password" label="" name="private_key"/>
                                                            <FieldError name="private_key"/>
                                                        </div>
                                                    </div>
                                                    <div className="text-center btn-lg font-weight-boldest font-size-h1">
                                                        <Button className='font-weight-bolder' disabled={isSubmitting}
                                                                type="submit">{isSubmitting ? "..." : "Guardar"} </Button>
                                                    </div>
                                {/* FIN PROCESADOR DE PAGOS */}
                            </FormikForm>
                        )}
                    </Formik>
                </div>
            </div>
            {/* <hr/>
            <h4>PayPal</h4>
            <div className="text-muted text-center my-5">
                <FontAwesomeIcon icon={faPencilRuler} size="5x"/>
                <p>Próximamente</p>
            </div>
            <hr/> */}

        </div>
    );
};

export default PaymentProcessors;
