import React from 'react';
import {Col, Image, Row} from "react-bootstrap";
import {format} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons";

import {TimeSlot} from "../Schedules";

type TimeSlotListCardProps = {
    timeSlot: TimeSlot,
    setSelectedTimeSlot: (timeslot: TimeSlot | null) => void
}

const TimeSlotListCard = ({timeSlot, setSelectedTimeSlot}: TimeSlotListCardProps) => {
    return (

        // Starts HERE///
        // Starts HERE//
        // Starts HERE///
        <div onClick={() => setSelectedTimeSlot(timeSlot)} className="border-top align-items-center mx-0 py-5  justify-content-between" style={{minHeight: "66px", cursor:"pointer"}}>
            {/* <Col md={3} className="px-0 col-3">
                <div className="font-size-lg font-weight-bold">{format(timeSlot.start, "h:mm aa")}</div>
                <div className="font-size-sm text-muted"> {timeSlot.minutes_duration} min</div>
            </Col> */}
            {/* <Col md={3} xl={2} className="px-0 col-3 col-xxl-2">
                <div className="symbol symbol-40 symbol-lg-40 symbol-circle text-muted">
                    {timeSlot.coaches.length > 0
                        ? <Image src={timeSlot.coaches[0].img}/>
                        : <FontAwesomeIcon icon={faUserCircle} style={{fontSize: "40px"}}/>
                    }
                </div>
            </Col> */}

            {/* <Col md={6} className="px-0 col-6">
                <div className="font-size-lg font-weight-bold">{timeSlot.studio_class.name}</div>
            </Col>

            <Col md={2} xl={3} className="px-0 col-1 col-md-2 col-xxl-3">
                <div
                    className="font-size-lg btn btn-primary btn-circle btn-icon btn-hover-primary font-weight-bold btn-lg">{timeSlot.people_limit - timeSlot.available_spaces} / {timeSlot.people_limit}
                </div>
            </Col> */}
            <div className="d-flex justify-content-between align-items-center">
                <div className='text-left'>
                    <div className="font-size-lg font-weight-bold text-primary">{format(timeSlot.start, "h:mm aa")}</div>
                    <div className="font-size-lg">{timeSlot.studio_class.name}</div>
                    <div className="font-size-lg">Reservaciones: {timeSlot.people_limit - timeSlot.available_spaces} / {timeSlot.people_limit}</div>
                </div>
                <div className='text-primary pr-2'>Ver lista </div>
            </div>

        </div>
    )
};

export default TimeSlotListCard;
