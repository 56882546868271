import React, {useEffect} from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field} from "formik";
import FormField from "../../../components/form/FormField";
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {StudioPlan, StudioPlanPromotion, updatePlan} from "../../../actions/studioPlans";
import baseApi from "../../../apis/baseApi";
import Checkbox from "../../../components/form/Checkbox";
import FormInputGroup from "../../../components/form/FormInputGroup";
import {formatDateTime} from "../../../utils/dateFormatters";
import DeleteModal from "../../../components/modals/DeleteModal";
import {useDispatch} from "react-redux";
import yup from '../../../utils/yup';
import FieldError from "../../../components/form/FieldError";

type Props = {
    show: boolean,
    onHide: Function,
    studioPlan?: StudioPlan
}

const CreatePromotionModal = ({show, onHide, studioPlan}: Props) => {
    const dispatch = useDispatch()
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedPromotion, setSelectedPromotion] = useState<StudioPlanPromotion>()
    const [plan, setPlan] = useState<StudioPlan>()

    useEffect(() => {
        setPlan(studioPlan)
    }, [studioPlan]);

    const validationSchema = yup.object().shape({
        title: yup.string().required(),
        discount_amount: yup.number().required().test('finalOver10', 'El precio final del plan debe ser mayor o igual a $10', (value, c) => {
            if (!value) return true;
            let total = parseFloat(plan!.price)
            if (c.parent.is_percent) {
                const discount = (total) * (value / 100)
                total -= discount
            } else {
                total -= value
            }
            console.log(total)
            return total >= 10
        }),
        is_percent: yup.boolean(),
        activate_on: yup.date().required(),
        expire_on: yup.date().required(),
    })


    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const renderActivePromos = () => {
        if (plan!.promotions.length === 0) return <React.Fragment/>
        return <div>
            <h4 className='text-primary'>Promociones activas</h4>
            {plan!.promotions.map(promo => {
                return <div key={promo.id} className=" mb-5">
                    <div className="d-flex justify-content-between align-items-center mt-5">
                        <div>
                            <div className="font-weight-bolder font-size-lg text-success">{promo.title}</div>
                            <div className="">Descuento Aplicado: {promo.discount_amount}{promo.is_percent ? '%' : '$'}</div>
                            <div
                                className="">Vigente: {formatDateTime(promo.activate_on)} - {formatDateTime(promo.expire_on)}</div>
                        </div>
                        <div>
                            <Button onClick={() => {
                                setSelectedPromotion(promo)
                                setShowDeleteModal(true)
                            }} variant="light-danger" size="sm">Cancelar Promoción</Button>
                        </div>
                    </div>
                </div>
            })}
            <hr/>
        </div>
    }

    if (!plan) return <React.Fragment/>
    return (
        <React.Fragment>
            <DeleteModal
                url={`/plans/promotions/${selectedPromotion?.id}/`}
                onSuccess={() => {
                    const promos = plan?.promotions.filter(promo => promo.id !== selectedPromotion?.id)
                    dispatch(updatePlan({...plan, promotions: promos}))
                    setPlan({...plan, promotions: promos})
                }}
                text="Cancelar Promoción"
                successText="Promoción cancelada"
                show={showDeleteModal}
                onHide={() => {
                    setShowDeleteModal(false)
                    setSelectedPromotion(undefined)
                }}>
                <div>
                    {!selectedPromotion
                        ? <React.Fragment/>
                        : <div>
                            <div className="font-weight-bolder">{selectedPromotion?.title}</div>
                            <div>Descuento {selectedPromotion?.discount_amount}{selectedPromotion?.is_percent ? '%' : '$'}</div>
                            <div
                                className="">Activación: {formatDateTime(selectedPromotion!.activate_on)} - {formatDateTime(selectedPromotion!.expire_on)}</div>
                        </div>
                    }
                </div>
            </DeleteModal>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                    title: "",
                    discount_amount: "",
                    is_percent: false,
                    activate_on: new Date(),
                    expire_on: new Date(),
                    plan: plan.id
                }} onSubmit={(values, {setSubmitting}) => {
                    setSuccess(null)
                    baseApi.post("/plans/promotions/", values).then(resp => {
                        setPlan({...plan, promotions: [...plan?.promotions, resp.data]})
                        dispatch(updatePlan({...plan, promotions: [...plan?.promotions, resp.data]}))
                        setAlertMessage("Promoción creada correctamente")
                        setSuccess(true)
                        setSubmitting(false)
                    }).catch(err => {
                        setAlertMessage("Error")
                        setSuccess(false)
                        setSubmitting(false)
                    })
                }}>
                    {({isSubmitting, values, setFieldValue}) => (
                        <FormikForm>

                            <Modal.Header>
                                Promociones
                                <strong>{plan?.name}</strong>
                            </Modal.Header>
                            <Modal.Body>
                                {renderActivePromos()}
                                {renderAlert()}
                                <div className='text-primary font-size-h4 mb-5'>Nueva Promoción</div>
                                <div className='font-size-h6 font-weight-bolder ml-4'>Título Promoción</div>
                                <FieldError name="title" />
                                <div className="col-6 mt-n4">
                                    <FormField label="" name="title"/>
                                </div>


                                <div className='font-size-h6 font-weight-bolder ml-4'>Descuento</div>
                                <FieldError name="discount_amount" />

                                <div className="d-flex ml-7">
                                    
                                    <FormInputGroup name="discount_amount" label=""
                                                    groupText={values.is_percent ? "%" : "$"} prepend={!values.is_percent}/>
                                    <FieldError name="activate_on" />
                                    <div className=" ml-8">
                                        <Field component={Checkbox} type="checkbox" name="is_percent"
                                               label="Porcentaje"/>
                                    </div>
                                </div>

                                <div className='font-size-h6 font-weight-bolder ml-4'>Fecha Inicio</div>
                                <div className=" ml-4 mb-5">
                                    <div className="customDatePickerWidth " style={{width: "220px"}}>
                                        <ReactDatePicker
                                            showTimeSelect
                                            timeCaption="Hora"
                                            dateFormat="dd 'de' MMMM yyyy HH:mm"
                                            locale={es}
                                            selected={values.activate_on}
                                            minDate={new Date()}
                                            onChange={date => {
                                                setFieldValue("activate_on", date)
                                            }}
                                            customInput={<input className="form-control"/>}
                                        />
                                    </div>
                                </div>
                                <FieldError name="expire_on" />
                                <div className='font-size-h6 font-weight-bolder ml-4'>Fecha Fin</div>
                                <div className="ml-4">
                                    <div className="customDatePickerWidth " style={{width: "220px"}}>
                                        <ReactDatePicker
                                            showTimeSelect
                                            timeCaption="Hora"
                                            dateFormat="dd 'de' MMMM yyyy HH:mm"
                                            locale={es}
                                            selected={values.expire_on}
                                            minDate={values.activate_on}
                                            onChange={date => {
                                                setFieldValue("expire_on", date)
                                            }}
                                            customInput={<input className="form-control"/>}
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting || showDeleteModal}>
                                    {isSubmitting ? "..." : "Crear"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreatePromotionModal;
