import React from "react";
import {Formik, Form as FormikForm} from "formik";
import FormField from "../../components/form/FormField";
import {Button} from "react-bootstrap";
import loginApi from "../../apis/loginApi";
import {getApiErrorMessage} from "../../utils/apiErrors";
import baseApi from "../../apis/baseApi";

const Registration = () => {
  return (
    <div>
      <h4>Registrar Estudio</h4>
      <Formik
        initialValues={{
          email: "",
          password: "",
          full_name: "",
          phone: "",
          is_manager: 1,
          is_staff: 1,
          studio_name: ""
        }}
        onSubmit={(values, {setSubmitting}) => {
          baseApi
            .post("/sa/", {
              ...values,
              username: values.email,
              studio: {name: values.studio_name}
            })
            .then(resp => {
              alert("SUCCESS");
              setSubmitting(false)
            }).catch(err => {
              alert(getApiErrorMessage(err))
              setSubmitting(false)
            }
          );
        }}
      >
        {({isSubmitting}) => (
          <FormikForm>
            <FormField label="E-Mail" name="email"/>
            <FormField label="Password" name="password"/>
            <FormField label="Nombre completo" name="full_name"/>
            <FormField label="Teléfono" name="phone"/>
            <FormField label="Nombre del Estudio" name="studio_name"/>
            <Button type="submit" disabled={isSubmitting}>
              Guardar
            </Button>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default Registration;
