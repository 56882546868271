import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTimes} from "@fortawesome/free-solid-svg-icons";
import {parseISO, isBefore, isAfter, format} from "date-fns";

import TablePill from "../LabelPill";
import {formatDate} from "../../../utils/dateFormatters";
import {MemberPlan} from "../../../pages/Members/MemberDetails/MemberPlans";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";

type MemberPlanTrProps = {
    memberPlan: MemberPlan,
    onCancel: () => void,
    onEditExpiration: () => void,
}

const MemberPlanTr = ({memberPlan, onCancel, onEditExpiration}: MemberPlanTrProps) => {
    const planActivateTT = (props: any) => (<Tooltip id={`logout-tt-${memberPlan.id}`} {...props}>Este plan se activara
        el {format(parseISO(memberPlan.activate_on), "dd/MMM/yyyy")}</Tooltip>)
    return (
        <tr className="font-size-lg align-middle text-center align-items-center table-bordered" key={memberPlan.id}>
            {/*TODO: change to sale id*/}
            {/* <td className="text-center text-uppercase">{memberPlan.id.slice(-6)}</td> */}

            {/* COLUMNA 1 */}
            <td className='text-left'>
                <div className="font-weight-bold">{formatDate(memberPlan.created_on)}</div>
                <div className="  font-size-sm">{memberPlan.plan.name}</div>
                <div className='text-dark font-size-sm'>${memberPlan.plan.price}</div>
            </td>

            {/* COLUMNA 2 */}
                {/* <td className="text-left  border-right">
                    <div className="">{memberPlan.plan.name}</div>
                    <div className='mt-1'>
                        {memberPlan.cancelled
                            ? <TablePill variant="warning" text="Cancelada" />
                            : isBefore(parseISO(memberPlan.expire_on), new Date())
                                ? <TablePill text="Vencida" variant="danger" />
                                : isAfter(parseISO(memberPlan.activate_on), new Date())
                                    ? <OverlayTrigger
                                        placement="left"
                                        overlay={planActivateTT}
                                        delay={{ show: 100, hide: 400 }}
                                    >
                                        <div>
                                            <TablePill text={"En espera"} variant="info" />
                                        </div>
                                    </OverlayTrigger>
                                    : <TablePill text={"Vigente"} />
                        }
                    </div>
                    <div className="">{memberPlan.plan.no_limit_credits ? `∞` : `Incluye ${memberPlan.plan.credits} créditos`}</div>
                </td> */}

            {/* <td>
                <div className="">{memberPlan.plan.no_limit_credits ? `Membresía ${memberPlan.plan.days_duration} días` : `Disponibles:${memberPlan.plan.credits} -Disponibles:`}</div>
                {memberPlan.credits_available ? memberPlan.credits_available : "∞"}
                {memberPlan.credits_available ? <span className="text-primary ml-1"><FontAwesomeIcon icon={faEdit} size="sm" /></span> : ""}
            </td> */}

            {/* COLUMNA 3 */}
            <td>
                <div className=" d-inline">{memberPlan.plan.no_limit_credits ? `∞` : `Créditos Disponibles: `}</div>
                <div className='font-weight-bolder font-size-h3 '>{memberPlan.credits_available ? memberPlan.credits_available : "0"}</div>

                <div className='mb-2'>
                    {memberPlan.cancelled
                        ? <TablePill variant="warning" text="Plan Cancelado" />
                        : isBefore(parseISO(memberPlan.expire_on), new Date())
                            ? <TablePill text="Vencidos" variant="danger" />
                            : isAfter(parseISO(memberPlan.activate_on), new Date())
                                ? <OverlayTrigger
                                    placement="left"
                                    overlay={planActivateTT}
                                    delay={{ show: 100, hide: 400 }}
                                >
                                    <div>
                                        <TablePill text={"En espera"} variant="info" />
                                    </div>
                                </OverlayTrigger>
                                : <TablePill text={"Vigentes"} />
                    }
                </div>
                {/* {memberPlan.credits_available ? <span className="text-primary ml-1"><FontAwesomeIcon icon={faEdit} size="sm" /></span> : ""} */}
            </td>

            {/* <td className=""> 
                {memberPlan.credits_available ? memberPlan.credits_available : "∞"}
                
                {memberPlan.credits_available ? <span className="text-primary ml-1"><FontAwesomeIcon icon={faEdit} size="sm" /></span> : ""}

            </td> */}

            {/* COLUMNA 4 */}
            <td>
                
                
                <div>Vencen:</div>
                <div className="" onClick={onEditExpiration}>
                    <span className='font-weight-bolder font-size-h5'>{formatDate(memberPlan.expire_on)}</span>
                    <span className="text-info clickable ml-2">
                        {/* <FontAwesomeIcon icon={faEdit}  size="sm"/> */}
                    </span>
                </div>

                <div className='text-primary mt-n2 clickable'  onClick={onEditExpiration}>
                    <span className='font-size-sm mr-1'>Editar Vencimiento</span>
                    <FontAwesomeIcon icon={faEdit}  size="sm" className='mr-1'/>
                </div>

                {/* LABEL */}
                {/* <div className='mt-3'>
                    {memberPlan.cancelled
                        ? <TablePill variant="warning" text="Cancelada" />
                        : isBefore(parseISO(memberPlan.expire_on), new Date())
                            ? <TablePill text="Vencida" variant="danger" />
                            : isAfter(parseISO(memberPlan.activate_on), new Date())
                                ? <OverlayTrigger
                                    placement="left"
                                    overlay={planActivateTT}
                                    delay={{ show: 100, hide: 400 }}
                                >
                                    <div>
                                        <TablePill text={"En espera"} variant="info" />
                                    </div>
                                </OverlayTrigger>
                                : <TablePill text={"Vigente"} />
                    }
                </div> */}
                
            </td>

            {/* <td className="border-right">
                {memberPlan.cancelled
                    ? <TablePill variant="warning" text="Cancelada"/>
                    : isBefore(parseISO(memberPlan.expire_on), new Date())
                        ? <TablePill text="Vencida" variant="danger"/>
                        : isAfter(parseISO(memberPlan.activate_on), new Date())
                            ? <OverlayTrigger
                                placement="left"
                                overlay={planActivateTT}
                                delay={{show: 100, hide: 400}}
                            >
                                <div>
                                    <TablePill text={"En espera"} variant="info"/>
                                </div>
                            </OverlayTrigger>
                            : <TablePill text={"Vigente"}/>
                }
            </td> */}

            {/* COLUMNA 5 BOTON CANCELAR */}
            <td className="px-0 text-center">
                <Button
                    disabled={memberPlan.cancelled || isBefore(parseISO(memberPlan.expire_on), new Date())}
                    onClick={() => {
                        onCancel()
                    }}
                    className="btn btn-linl-danger text-danger bg-transparent border-0 btn-outline-danger">
                    {/* <FontAwesomeIcon icon={faTimes}/>  */}
                    Cancelar Plan
                </Button>
            </td>
        </tr>
    );
};

export default MemberPlanTr;
