import {Dispatch} from "redux";

import {StudioPlansActionTypes} from "./types";
import baseApi from "../apis/baseApi";
import {StudioClass} from "./studioClasses";

export interface StudioPlan {
    id: string;
    public_visible: boolean;
    name: string;
    description: string;
    price: string;
    credits: number;
    no_limit_credits: boolean;
    sku: string;
    days_duration: number;
    does_not_expire: boolean;
    day_reservations_limit?: number;
    week_reservations_limit?: number;
    allow_all_branches: boolean;
    allow_all_classes: boolean;
    created_on: Date;
    allowed_classes: StudioClass[];
    branches: string[];
    allow_friend_rsvp: boolean
    max_purchases_per_member?: number
    promotions: StudioPlanPromotion[]
    is_subscription: boolean
}

export interface StudioPlanPromotion {
    activate_on: string;
    created_by: string;
    created_on: string;
    discount_amount: number;
    expire_on: string;
    id: string;
    is_percent: boolean;
    plan: string;
    title: string;
}

export interface StudioPlansState {
    plans: StudioPlan[]
    loading: boolean
}

export interface StudioPlanAction {
    type: StudioPlansActionTypes
    payload: any
}

export const fetchStudioPlans = (branchId: string) => {
    return async (dispatch: Dispatch) => {
        dispatch({type: StudioPlansActionTypes.FETCHING, payload: null})
        baseApi.get<StudioPlan[]>(`/plans/?branch=${branchId}`).then((response) => {
            dispatch<StudioPlanAction>({
                type: StudioPlansActionTypes.FETCHED,
                payload: response.data
            })
        })
    }
}

export const addPlan = (plan: StudioPlan) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: StudioPlansActionTypes.ADD_PLAN,
            payload: plan
        })
    }
}

export const updatePlan = (plan: StudioPlan) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: StudioPlansActionTypes.UPDATE_PLAN,
            payload: plan
        })
    }
}

export const removePlan = (planId: string) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: StudioPlansActionTypes.REMOVE_PLAN,
            payload: planId
        })
    }
}